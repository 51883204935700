import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { theme } from '../../../../theme/theme';
import { StyledNavLink } from 'components/UI/Link/Link.Styled';

const useStyles = makeStyles((props) => ({
	Secondary: {
		fontFamily: 'Poppins-Regular',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: (props) => (props.small === 'true' ? '12px' : '14px'),
		lineHeight: (props) => (props.small === 'true' ? '15px' : '21px'),
		color: (props) => (props.fontcolor ? props.fontcolor : '#fff'),
		textTransform: 'inherit',
		padding: 0,
		minHeight: (props) => (props.square ? 45 : props.small === 'true' ? 30 : 45),
		minWidth: (props) => (props.square ? 45 : props.small === 'true' ? 100 : 150),
		border: '1px solid',
		borderRadius: '3px',
		borderColor: (props) => (props.color ? props.color : theme.landing_page_colors.red),
		marginBottom: (props) => (props.bottom ? props.bottom : '0px'),

		'&:hover': {
			border: (props) =>
				props.color
					? `1px solid ${props.color}`
					: `1px solid ${theme.landing_page_colors.red}`,
			background: (props) => (props.color ? props.color : theme.landing_page_colors.red),
		},
	},
	label: {
		padding: '10px',
	},
	Contained: {
		border: `1px solid ${theme.landing_page_colors.red}`,
		background: theme.landing_page_colors.red,
	},
	Disabled: {
		border: (props) =>
			props.color
				? `1px solid ${props.color} !important`
				: `1px solid ${theme.landing_page_colors.red}`,
		background: (props) =>
			props.outline ? 'transparent' : `${theme.landing_page_colors.red} !important`,
		color: (props) =>
			props.fontcolor ? `${props.fontcolor} !important` : '#FFFFFF !important',
		opacity: 0.5,
	},
}));

const ButtonSecondary = (props) => {
	const uiClasses = useStyles(props);

	const { to } = props;

	return to ? (
		<StyledNavLink to={to}>
			<Button
				{...props}
				variant={props.outline ? 'outlined' : 'contained'}
				size="medium"
				color="primary"
				fullWidth={props.isfullwidth}
				className={clsx(
					uiClasses.Secondary,
					uiClasses.label,
					{ [uiClasses.Contained]: !props.outline },
					{ [uiClasses.Disabled]: props.disabled },
					props.className
				)}
			>
				{props.children}
			</Button>
		</StyledNavLink>
	) : (
		<Button
			{...props}
			variant={props.outline ? 'outlined' : 'contained'}
			size="medium"
			color="primary"
			fullWidth={props.isfullwidth}
			className={clsx(
				uiClasses.Secondary,
				uiClasses.label,
				{ [uiClasses.Contained]: !props.outline },
				{ [uiClasses.Disabled]: props.disabled },
				props.className
			)}
		>
			{props.children}
		</Button>
	);
};

ButtonSecondary.propTypes = {
	isfullwidth: PropTypes.bool,
	outline: PropTypes.string,
	children: PropTypes.node,
};

export default ButtonSecondary;
