import React, { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { onPopoverClose, onPopoverOpen } from '../../../../../shared/utility';
import { StyledMenu, Divider, StyledNavLink } from './DesktopMenu.Styled';
import { StyledNavLink as RawNawLink } from 'components/UI/Link/Link.Styled';
import Avatar from 'components/UI/Avatar';
import BuyBalanceButton from '../../BuyBalanceButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ButtonSecondary from 'components/UI/Buttons/ButtonSecondary/ButtonSecondary';

import useUserData from 'hooks/useUserData';

const DesktopMenu = ({ menuData, nickname, userAvatar }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const handleOpenPopover = (event) => {
		setAnchorEl(event.currentTarget);
		onPopoverOpen();
	};
	const handleClosePopover = () => {
		setAnchorEl(null);
		onPopoverClose();
	};
	const { userId, loading } = useUserData();

	return !loading ? (
		<div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
			<BuyBalanceButton />
			<Divider />
			<RawNawLink to={`/profile/${userId}`}>
				<Avatar alt={nickname} src={userAvatar} />
			</RawNawLink>
			<Divider />
			<ButtonSecondary
				square={1}
				outline="outlined"
				color="#1F2431"
				fontcolor="#3E414F"
				onClick={handleOpenPopover}
			>
				<ExpandMoreIcon />
			</ButtonSecondary>
			<StyledMenu
				id="menu-appbar"
				anchorEl={anchorEl}
				keepMounted
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={Boolean(anchorEl)}
				onClose={handleClosePopover}
			>
				{menuData.map((item, index) => (
					<MenuItem key={`MenuItem${index}`}>
						<StyledNavLink
							key={`MenuItemLink${index}`}
							to={item.link}
							onClick={handleClosePopover}
						>
							{item.title}
						</StyledNavLink>
					</MenuItem>
				))}
			</StyledMenu>
		</div>
	) : null;
};

export default DesktopMenu;
