import * as actionTypes from './actionTypes';

export const autoSetLanguageInit = () => {
  return {
    type: actionTypes.AUTO_SET_LANGUAGE_INIT
  }
}

export const changeLanguage = (language) => {
  return {
    type: actionTypes.CHANGE_LANGUAGE,
    language: language
  }
}

export const changeLanguageSuccess = (language) => {
  return {
    type: actionTypes.CHANGE_LANGUAGE_SUCCESS,
    language: language
  }
}