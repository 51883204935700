import React from 'react';
import logoImg from '../../../assets/images/logo-white-full.png';
import { LogoComponentStyled } from './Logo.Styled';


export default function Logo() {
    return (
        <LogoComponentStyled>
            <img src={logoImg} alt="ESPORTFIELD" />
        </LogoComponentStyled>
    )
}
