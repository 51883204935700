import ButtonSecondary from 'components/UI/Buttons/ButtonSecondary/ButtonSecondary';
import React from 'react';
import * as Styled from './buyBalanceButton.styled';
import singleCoin from 'assets/icons/single_coin.png';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { formatBalanceAmount } from 'shared/formatBalanceAmount';
import { StyledNavLink } from 'components/UI/Link/Link.Styled';

const BuyBalanceButton = () => {
	const balance = useSelector((state) => state.userData?.userData?.wallet?.coins_amount);
	const userRole = useSelector((state) => state.userData?.userData?.user_role?.name);

	const balanceProcessed = formatBalanceAmount(balance);
	return (
		<Styled.Wrapper>
			<StyledNavLink to="/wallet">
				<p>
					{balanceProcessed} <img src={singleCoin} alt="coins" />
				</p>
			</StyledNavLink>
			{userRole === 'player' && (
				<ButtonSecondary to="/wallet">
					<FormattedMessage id="buy balance" />
				</ButtonSecondary>
			)}
		</Styled.Wrapper>
	);
};

export default BuyBalanceButton;
