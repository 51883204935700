import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as actions from '../../../../store/actions/index';
import { connect, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import classes from './LoggedOutHeaderControls.module.scss';
import styled from 'styled-components';
import Burger from '../components/Burger/Burger';

const StyledNavLink = styled(NavLink)`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	font-family: Poppins-Regular;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #ffffff;
	text-transform: none;
	text-decoration: none;
	padding: 0;
	min-height: 45px;
	min-width: 150px;
	border: 1px solid ${({ theme }) => theme.landing_page_colors.red};
	padding: 0 20px;
	&:hover {
		border: 1px solid ${({ theme }) => theme.landing_page_colors.red};
		background: ${({ theme }) => theme.landing_page_colors.red};
	}
`;

const LoggedOutHeaderControls = ({ intl, ...props }) => {
	const { onSetUserRole, onSetAuthErrorNull, onSetRegisterErrorNull } = props;
	const texts = {
		login: intl.formatMessage({
			id: 'header.login',
		}),
		register: intl.formatMessage({
			id: 'header.register',
		}),
	};

	const handleResetUserRole = () => {
		onSetUserRole(null);
		onSetAuthErrorNull();
		onSetRegisterErrorNull();
	};
	const handleResetErrors = () => {
		onSetAuthErrorNull();
		onSetRegisterErrorNull();
	};
	const view = useSelector((state) => state.view.view);

	return (
		<div style={{ display: 'flex' }}>
			{view === 'mobile' ? (
				<Burger />
			) : (
				<>
					<StyledNavLink
						to="/login"
						className={classes.NavLink}
						onClick={handleResetErrors}
					>
						{texts.login}
					</StyledNavLink>
					<StyledNavLink to="/register" onClick={handleResetUserRole}>
						{texts.register}
					</StyledNavLink>
				</>
			)}
		</div>
	);
};

LoggedOutHeaderControls.propTypes = {
	onOpenRegisterModal: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return {
		onOpenRegisterModal: () => dispatch(actions.openRegisterModal()),
		onSetAuthErrorNull: () => dispatch(actions.setAuthErrorNull()),
		onSetRegisterErrorNull: () => dispatch(actions.setRegisterErrorNull()),
		onSetUserRole: (userRole) => dispatch(actions.setUserRole(userRole)),
	};
};

const mapStateToProps = (state) => {
	return {
		videoAnalysis: state.videoAnalysis.videoAnalysis,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LoggedOutHeaderControls));
