import React, { useEffect } from 'react';
import ringImg from '../../../../assets/images/ring.svg';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import PropTypes from 'prop-types';
import MobileMenu from './MobileMenu/MobileMenu';
import DesktopMenu from './DesktopMenu/DesktopMenu';
import useUserData from '../../../../hooks/useUserData';
import appDebug from '../../../../helpers/debugger.js';

const LoggedInHeaderControls = ({ intl, ...props }) => {
	let debugMode = useSelector((state) => state.general.debugMode);
	const { onGetUserData, userData, onRefreshToken, onGetTokenInfo, expiresInValue } = props;

	const nickname = localStorage.getItem('nickname');
	const userId = localStorage.getItem('userId');

	const userAvatar = userData?.avatar?.mini?.url;

	useEffect(() => {
		onGetTokenInfo();
		const expiresInMiliseconds = expiresInValue / 0.001;
		let timeoutId;

		const refreshData = {
			client_id: `${
				process.env.NODE_ENV === 'development'
					? process.env.REACT_APP_CLIENT_ID
					: '19lRQduVv3Q7rL3jZnNEDU97_adWUBJImP6kve34i8U'
			}`,
			grant_type: 'refresh_token',
			refresh_token: localStorage.getItem('refresh_token'),
		};

		if (expiresInMiliseconds) {
			timeoutId = setTimeout(() => {
				onRefreshToken(refreshData);
			}, expiresInMiliseconds);
		}

		return () => {
			clearTimeout(timeoutId);
		};
	}, [expiresInValue, onRefreshToken, onGetTokenInfo]);

	useEffect(() => {
		onGetUserData();
	}, [onGetUserData]);

	const { userRole, loading } = useUserData();

	const menuData = [
		{
			title: <FormattedMessage id="profile.yourProfile" />,
			link: `/profile/${userId}`,
		},
		{
			title: <FormattedMessage id="common.marketplace" />,
			link: '/marketplace',
		},
		{
			title: <FormattedMessage id="orders" />,
			link: '/orders',
		},
		{
			title: <FormattedMessage id="reportBug" />,
			link: '/bug-report',
		},
		{
			title: <FormattedMessage id="header.logout" />,
			link: '/logout',
		},
	];

	const myVideoAnalysisListLink = {
		title: <FormattedMessage id="video.myAnalysis" />,
		link: '/video-analysis-list',
	};
	const myVideoUploadLink = {
		title: <FormattedMessage id="video.upload" />,
		link: '/video-upload',
	};
	const index = menuData.length - 1;

	const isCoachMenuData = () => {
		if (userRole === 'coach') {
			menuData.splice(index, 0, myVideoAnalysisListLink);
		}
	};
	const isPlayerMenuData = () => {
		if (userRole === 'player') {
			menuData.splice(index, 0, myVideoUploadLink);
		}
	};

	const addMuseToMenu = () => {
		// Muse available in menu always
		//if (debugMode) {
			menuData.push({
				title: <FormattedMessage id="header.muse" />,
				link: '/muse',
			});
			appDebug(
				'info',
				`Link do Muse dodany do menu w trybie debug w pliku LoggedInHeaderControls.js`,
				debugMode
			);
		//}
	};

	addMuseToMenu();
	isPlayerMenuData();
	isCoachMenuData();

	return (
		<React.Fragment>
			{!loading && (
				<>
					{props.view === 'mobile' ? (
						<MobileMenu
							userData={userData}
							menuData={menuData}
							nickname={nickname}
							ringImg={ringImg}
							userAvatar={userAvatar}
						/>
					) : (
						<DesktopMenu
							menuData={menuData}
							nickname={nickname}
							userAvatar={userAvatar}
						/>
					)}
				</>
			)}
		</React.Fragment>
	);
};

LoggedInHeaderControls.propTypes = {
	userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	videoAnalysis: PropTypes.object,
	onCreateVideoAnalysis: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		userData: state.userData.userData,
		userId: state.auth.userId,
		videoAnalysis: state.videoAnalysis.videoAnalysis,
		view: state.view.view,
		authLoading: state.auth.loading,
		registerLoading: state.register.loading,
		accessToken: state.auth.token,
		authError: state.auth.error,
		expiresInValue: state.auth.expiresIn,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onCreateVideoAnalysis: (videoId, summary, recommendation, coachId) =>
			dispatch(actions.createVideoAnalysis(videoId, summary, recommendation, coachId)),
		onSetErrorUserData: (data) => dispatch(actions.setErrorUserData(data)),
		onGetUserData: () => dispatch(actions.getUserData()),
		onRefreshToken: (loginData) => dispatch(actions.refreshToken(loginData)),
		onGetTokenInfo: () => dispatch(actions.getTokenInfo()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LoggedInHeaderControls));
