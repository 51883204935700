import store from '../createStore';
import { theme } from '../theme/theme';

export const updateObject = (oldObject, updatedProperies) => {
	return {
		...oldObject,
		...updatedProperies,
	};
};

export const formatTime = (sec) => {
	let hours = Math.floor(sec / 3600);
	let minutes = Math.floor(sec / 60);
	let seconds = Math.floor(sec % 60);
	let afterComma = (sec % 1).toFixed(1).toString().split('.')[1];

	minutes = minutes >= 10 ? minutes : '0' + minutes;
	seconds = seconds >= 10 ? seconds : '0' + seconds;

	if (hours === 0) {
		if (sec % 1 !== 0) {
			return minutes + ':' + seconds + '.' + afterComma;
		} else {
			return minutes + ':' + seconds;
		}
	} else {
		minutes = minutes - 60 * hours;
		minutes = minutes >= 10 ? minutes : '0' + minutes;
		hours = hours >= 10 ? hours : '0' + hours;
		return hours + ':' + minutes + ':' + seconds;
	}
};
export const formatTimeInObject = (sec) => {
	let hours = Math.floor(sec / 3600);
	let minutes = Math.floor((sec / 60) % 60);
	let seconds = Math.floor(sec % 60);

	return {
		hours,
		minutes,
		seconds,
	};
};

export const dataURLtoFile = (dataurl, filename) => {
	let arr = dataurl.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename, { type: mime });
};

export const toDataUrl = (url, callback) => {
	const xhr = new XMLHttpRequest();
	xhr.onload = () => {
		const reader = new FileReader();
		reader.onloadend = () => {
			callback(reader.result);
		};
		reader.readAsDataURL(xhr.response);
	};
	xhr.open('GET', 'https://cors-anywhere.herokuapp.com/' + url, true);
	xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
	xhr.responseType = 'blob';
	xhr.send();
};

export const numberWithCommas = (number) => {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const onPopoverOpen = () => {
	const bodyWidth = document.body.getBoundingClientRect().width;
	const headerWidth = document.querySelector('header').getBoundingClientRect().width;
	document.querySelector('header').style.width = `${headerWidth + 5}px`;
	document.body.style.width = `${bodyWidth + 5}px`;
	if (document.querySelector('.painting-menu')) {
		document.querySelector('.painting-menu').style.left = `calc(50% - 8.5px)`;
	}
	if (document.querySelector('.video-analyzer-welcome-modal')) {
		document.body.style.paddingRight = `20.5px`;
		document.querySelector('header').style.paddingRight = `20.5px`;
	}
};
export const onPopoverClose = () => {
	document.body.style.width = '';
	document.querySelector('header').style.width = ``;
	if (document.querySelector('.delete-confirmation-popover')) {
		document.querySelector('.delete-confirmation-popover').style.left = `17px`;
	}
	if (document.querySelector('.video-analyzer-welcome-modal')) {
		document.querySelector('.video-analyzer-welcome-modal').style.left = `17px`;
	}
	if (document.querySelector('.painting-menu')) {
		document.querySelector('.painting-menu').style.left = `50%`;
	}
};

export const setCursorAtTheEndOfInput = (el) => {
	el.selectionStart = el.value.length;
	el.selectionEnd = el.value.length;
};

export const handleSlideToSliderPosition = () => {
	const timelinesContainer = document.querySelector('.timelines-container');
	const timelinesContainerWidth = timelinesContainer.getBoundingClientRect().width / 2;
	setTimeout(() => {
		const sliderValue = store.getState().videoAnalysis.sliderValue;
		const maxValueSlider = store.getState().videoAnalysis.maxValueSlider;
		const videoAnalysisContainerWidth = store.getState().videoAnalysis.videoAnalysisContainerWidth;

		const currentFramePercentage = (sliderValue / maxValueSlider) * 100;

		const distanceInPixels = (videoAnalysisContainerWidth * currentFramePercentage) / 100;

		timelinesContainer.scrollLeft = distanceInPixels - timelinesContainerWidth;
	}, 10);
};

export const getStatusColor = (status) => {
	switch (status) {
		case 'open': {
			return theme.colors.successGreen;
		}
		case 'canceled': {
			return theme.colors.deleteRed;
		}
		default: {
			return theme.colors.darkGray;
		}
	}
};
