import * as actions from '../actions/index';
import { put } from 'redux-saga/effects';

export function* autoSetLanguageSaga(action) {
	const language = yield localStorage.getItem('language');
	if (language) {
		yield put(actions.changeLanguageSuccess(language));
	} else {
		yield put(actions.changeLanguageSuccess('en'));
	}
}

export function* changeLanguageSaga(action) {
	yield localStorage.setItem('language', action.language);
	yield put(actions.changeLanguageSuccess(action.language));
}
