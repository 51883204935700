import * as actionTypes from './actionTypes';

export const getStatsSuccess = (stats) => {
	return {
		type: actionTypes.GET_STATS_SUCCESS,
		stats,
	};
};

export const getStatsFail = (error) => {
	return {
		type: actionTypes.GET_STATS_FAIL,
		error,
	};
};

export const getSteamStats = (userId) => {
	return {
		type: actionTypes.GET_STEAM_STATS,
		userId,
	};
};
export const getStatsStart = () => {
	return {
		type: actionTypes.GET_STATS_START,
	};
};
