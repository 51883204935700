import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from '../../axios-instance';
import appDebug from '../../helpers/debugger.js';
import { select } from 'redux-saga/effects';

export const getDebugMode = (state) => state.general.debugMode;

export function* getUserDataSaga() {
	try {
		const response = yield axios.get('users/me');

		yield put(actions.getUserDataSuccess(response.data));
	} catch (error) {
		console.error(error);
		console.error(error.response.data.error);
		yield put(actions.getUserDataFail('Wystąpił błąd!'));
	}
}

export function* getAvailableUserLanguagesSaga() {
	let debugMode = yield select(getDebugMode);
	appDebug('info',`Function getAvailableUserLanguagesSaga`, debugMode);
	try {
		const response = yield axios.get('languages');
		appDebug('success',`Action getAvailableUserLanguagesSagaSuccess with parameter ${JSON.stringify(response.data.data)}`, debugMode);
		yield put(actions.getAvailableUserLanguagesSuccess(response.data.data));
	} catch (error) {
		const message = `${error.response.status} ${error.response.statusText}`
		appDebug('error',`Action getAvailableUserLanguagesSagaFail ${message}`, true);
		yield put(actions.getAvailableUserLanguagesFail('Wystąpił błąd przy odczytywaniu dostępnych języków !'));
	}
}

export function* setUserLanguageSaga(action) {
	const userId = action.payload.userId;
	const languageId = action.payload.languageId;
	let debugMode = yield select(getDebugMode);
	const query = {
		user: {
			language_ids: [languageId]
		}
	}
	appDebug('info',`Function setUserLanguageSaga with languageId ${languageId} and userId ${userId} for users/custom/${userId}`, debugMode);
	appDebug('info',`Query parameter ${JSON.stringify(query)}`, debugMode);
	try {
		const response = yield axios.patch(`users/custom/${userId}`,query);
		appDebug('success',`Action setUserLanguageSagaSuccess with parameter ${JSON.stringify(response.data)}`, debugMode);
		yield put(actions.setUserLanguageSuccess());
		appDebug('info',`Action setUserLanguageSagaSuccess update store, getUserData`, debugMode);
		yield put(actions.getUserData());
	} catch (error) {
		const message = `${error.response.status} ${error.response.statusText}`
		appDebug('error',`Action setUserLanguageSagaFail ${message}`, true);
		yield put(actions.setUserLanguageFail(message));
	}
}

export function* getAvailableUserGamesSaga() {
	let debugMode = yield select(getDebugMode);
	appDebug('info',`Function getAvailableUserGamesSaga`, debugMode);
	try {
		const response = yield axios.get('games');
		appDebug('success',`Action getAvailableUserGamesSagaSuccess with parameter ${JSON.stringify(response.data)}`, debugMode);
		yield put(actions.getAvailableUserGamesSuccess(response.data));
	} catch (error) {
		const message = `${error.response.status} ${error.response.statusText}`
		appDebug('error',`Action getAvailableUserGamesSagaFail ${message}`, true);
		yield put(actions.getAvailableUserGamesFail('Wystąpił błąd przy odczytywaniu dostępnych gier !'));
	}
}

export function* getUserGamesSaga() {
	let debugMode = yield select(getDebugMode);
	appDebug('info',`Function getUserGamesSaga`, debugMode);
	try {
		const response = yield axios.get('users/games');
		appDebug('success',`Action getUserGamesSuccess in Saga with parameter ${JSON.stringify(response.data.data)}`, debugMode);
		yield put(actions.getUserGamesSuccess(response.data.data));
	} catch (error) {
		const message = `${error.response.status} ${error.response.statusText}`
		appDebug('error',`Action getUserGamesFail in Saga ${message}`, true);
		yield put(actions.getUserGamesFail('Wystąpił błąd przy odczytywaniu gier użytkownika !'));
	}
}

export function* addUserGameSaga(action) {
	const gameId = action.payload.gameId;
	const experience = action.payload.experience;
	let debugMode = yield select(getDebugMode);
	const query = {
		users_game: {
			game_id: gameId,
			experience: experience 
		}
	}
	appDebug('info',`Function addUserGameSaga with gameId ${gameId} and experience ${experience} for users/games}`, debugMode);
	appDebug('info',`Query parameter ${JSON.stringify(query)}`, debugMode);
	try {
		const response = yield axios.post(`users/games`,query);
		appDebug('success',`Action addUserGameSagaSuccess with parameter ${JSON.stringify(response.data)}`, debugMode);
		yield put(actions.setUserLanguageSuccess());
		appDebug('info',`Action addUserGameSagaSuccess update store, getUserData`, debugMode);
		yield put(actions.getUserData());
		appDebug('info',`Action deleteUserGameSuccess update store, getUserGames`, debugMode);
		yield put(actions.getUserGames());
	} catch (error) {
		const message = `${error.response.status} ${error.response.statusText}`
		appDebug('error',`Action addUserGameSagaFail ${message}`, true);
		yield put(actions.setUserLanguageFail(message));
	}
}

export function* deleteUserGameSaga(action) {
	const game_id = action.payload.gameId;
	let debugMode = yield select(getDebugMode);
	appDebug('info',`Function deleteUserGame for users/games/${game_id}}`, debugMode);
	try {
		const response = yield axios.delete(`users/games/${game_id}`);
		appDebug('success',`Action deleteUserGameSuccess with parameter ${JSON.stringify(response.data)}`, debugMode);
		yield put(actions.deleteUserGameSuccess());
		appDebug('info',`Action deleteUserGameSuccess update store, getUserGames`, debugMode);
		yield put(actions.getUserGames());
	} catch (error) {
		const message = `${error.response.status} ${error.response.statusText}`
		appDebug('error',`Action deleteGameRankFail ${message}`, true);
		yield put(actions.deleteGameRankFail(message));
	}
}

export function* getGameRanksSaga(action) {
	const game_id = action.payload.gameId;
	let debugMode = yield select(getDebugMode);
	appDebug('info',`Function getGameRanksSaga users/games/${game_id}/game_ranks`, debugMode);
	try {
		const response = yield axios.get(`users/games/${game_id}/game_ranks`);
		appDebug('success',`Action getGameRanksSuccess in Saga with parameter ${JSON.stringify(response.data)}`, debugMode);
		const dataToStore = {
			gameId: game_id,
			data: response.data,
		}
		yield put(actions.getGameRanksSuccess(dataToStore));
	} catch (error) {
		const message = `${error.response}`
		appDebug('error',`Action getGameRanksFail in Saga ${message}`, true);
		yield put(actions.getGameRanksFail('Wystąpił błąd przy odczytywaniu gier użytkownika !'));
	}
}

export function* setGameRankSaga(action) {
	const game_rank_id = action.payload.gameRankId;
	const game_id = action.payload.gameId;
	let debugMode = yield select(getDebugMode);
	const query = {
		game_rank: {
			game_rank_id: game_rank_id,
		}
	}
	appDebug('info',`Function setGameRank with game_rank_id ${game_rank_id} for users/games/${game_id}/game_ranks}`, debugMode);
	appDebug('info',`Query parameter ${JSON.stringify(query)}`, debugMode);
	try {
		const response = yield axios.post(`users/games/${game_id}/game_ranks`,query);
		appDebug('success',`Action setGameRankSuccess with parameter ${JSON.stringify(response.data)}`, debugMode);
		yield put(actions.setGameRankSuccess());
		appDebug('info',`Action setGameRankSuccess update store, getUserData`, debugMode);
		yield put(actions.getUserData());
		appDebug('info',`Action setGameRankSuccess update store, getGameRanks`, debugMode);
		yield put(actions.getGameRanks({gameId: game_id}));
	} catch (error) {
		const message = `${error.response.status} ${error.response.statusText}`
		appDebug('error',`Action setUserLanguageFail ${message}`, true);
		yield put(actions.setGameRankFail(message));
	}
}

export function* deleteGameRankSaga(action) {
	const game_rank_id = action.payload.gameRankId;
	const game_id = action.payload.gameId;
	let debugMode = yield select(getDebugMode);
	appDebug('info',`Function deleteGameRank with game_rank_id ${game_rank_id} for users/games/${game_id}/game_ranks/${game_rank_id}}`, debugMode);
	try {
		const response = yield axios.delete(`users/games/${game_id}/game_ranks/${game_rank_id}`);
		appDebug('success',`Action deleteGameRankSuccess with parameter ${JSON.stringify(response.data)}`, debugMode);
		yield put(actions.deleteGameRankSuccess());
		appDebug('info',`Action deleteGameRankSuccess update store, getUserData`, debugMode);
		yield put(actions.getUserData());
		appDebug('info',`Action deleteGameRankSuccess update store, getGameRanks`, debugMode);
		yield put(actions.getGameRanks({gameId: game_id}));
	} catch (error) {
		const message = `${error.response.status} ${error.response.statusText}`
		appDebug('error',`Action setUserLanguageFail ${message}`, true);
		yield put(actions.deleteGameRankFail(message));
	}
}

export function* getAvailableGameRanksSaga(action) {
	const game_id = action.payload.gameId;
	let debugMode = yield select(getDebugMode);
	const query = {
  		"by_game_ids": [game_id],
	}
	appDebug('info',`Function getAvailableGameRanks game_ranks`, debugMode);
	try {
		const response = yield axios.post(`game_ranks`, query);
		appDebug('success',`Action getAvailableGameRanksSuccess in Saga with parameter by_game_ids [${game_id}] is ${JSON.stringify(response.data)}`, debugMode);
		const dataToStore = {
			gameId: game_id,
			data: response.data,
		}
		yield put(actions.getAvailableGameRanksSuccess(dataToStore));
	} catch (error) {
		const message = `${error.response}`
		appDebug('error',`Action getAvailableGameRanksFail in Saga ${message}`, true);
		yield put(actions.getAvailableGameRanksFail(`Wystąpił błąd przy odczytywaniu dostępnych rank dla gry o id ${game_id} !`));
	}
}
