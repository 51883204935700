import * as actionTypes from './actionTypes';

export const getVideosSuccess = (videos, payload) => {
	return {
		type: actionTypes.GET_VIDEOS_SUCCESS,
		videos,
		payload,
	};
};
export const setStartVideosLoading = () => {
	return {
		type: actionTypes.SET_START_VIDEOS_LOADING,
	};
};
export const setStartDeleteVideosLoading = () => {
	return {
		type: actionTypes.SET_START_DELETE_VIDEOS_LOADING,
	};
};
export const setStartGetVideosLoading = () => {
	return {
		type: actionTypes.SET_START_GET_VIDEOS_LOADING,
	};
};
export const setVideosError = (error) => {
	return {
		type: actionTypes.SET_VIDEOS_ERROR,
		error,
	};
};
export const setUploadPercentage = (percentage) => {
	return {
		type: actionTypes.SET_UPLOAD_PERCENTAGE,
		percentage,
	};
};

export const addNewVideo = (data) => {
	return {
		type: actionTypes.ADD_NEW_VIDEO,
		data,
	};
};
export const getVideos = (payload) => {
	return {
		type: actionTypes.GET_VIDEOS,
		payload,
	};
};
export const deleteVideo = (video_id, userId) => {
	return {
		type: actionTypes.DELETE_VIDEO,
		video_id,
		userId,
	};
};
export const cancelUpload = () => {
	return {
		type: actionTypes.CANCEL_UPLOAD,
	};
};
export const updateVideo = (video_id, object) => {
	return {
		type: actionTypes.UPDATE_VIDEO,
		video_id,
		object,
	};
};
