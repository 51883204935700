import React, { useEffect, useState } from 'react';
import { Grid, Container } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import classes from './Footer.module.scss';
import fbImg from '../../assets/images/fb.svg';
import linkedinImg from '../../assets/images/linkedin.svg';
import instaImg from '../../assets/images/insta.svg';
import twitterImg from '../../assets/images/twitter.svg';
import logoImg from '../../assets/images/logo-white-full.png';
import ButtonSecondary from '../UI/Buttons/ButtonSecondary/ButtonSecondary';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import fund_logo_1 from '../../assets/images/funding/fund_logo_1.png';
import fund_logo_2 from '../../assets/images/funding/fund_logo_2.png';
import fund_logo_3 from '../../assets/images/funding/fund_logo_3.png';
import fund_logo_4 from '../../assets/images/funding/fund_logo_4.png';
import { StyledTextField } from './Footer.Styled';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';
import ErrorAlert from 'components/UI/ErrorAlert/ErrorAlert';

const useStyles = makeStyles((theme) => ({
	Input: {
		borderColor: '#FF3354',
		borderRadius: '5px',
		height: '49px',

		'&:hover': {
			borderColor: '#FF3354',
		},
	},
}));

const Footer = ({ intl, ...props }) => {
	const uiClasses = useStyles();

	const [email, setEmail] = useState('');
	const [messageError, setErrorMessage] = useState(null);
	const createSubscriptionError = useSelector(
		(state) => state.subscriptions.createSubscriptionError
	);
	const createSubscriptionSuccess = useSelector(
		(state) => state.subscriptions.createSubscriptionSuccess
	);
	const createSubscriptionLoading = useSelector(
		(state) => state.subscriptions.createSubscriptionLoading
	);

	const dispatch = useDispatch();

	const texts = {
		home: intl.formatMessage({
			id: 'common.home',
		}),
		about: intl.formatMessage({
			id: 'common.about',
		}),
		organizations: intl.formatMessage({
			id: 'organizations',
		}),
		marketplace: intl.formatMessage({
			id: 'common.marketplace',
		}),
		stayWithUs: intl.formatMessage({
			id: 'common.stayWithUs',
		}),
		contact: intl.formatMessage({
			id: 'common.contact',
		}),
		contactUs: intl.formatMessage({
			id: 'common.contactUs',
		}),
		subscribeNewsletter: intl.formatMessage({
			id: 'newsletter.subscribe',
		}),
		email: intl.formatMessage({
			id: 'common.email',
		}),
		subscribe: intl.formatMessage({
			id: 'common.subscribe',
		}),
		privacyPolicy: intl.formatMessage({
			id: 'common.privacyPolicy',
		}),
		support: intl.formatMessage({
			id: 'common.support',
		}),
		blog: intl.formatMessage({
			id: 'common.BLOG',
		}),
	};

	const handleSubscribe = () => {
		dispatch(actions.createSubscription({ data: { email }, subscriptionType: 'newsletter' }));
	};

	useEffect(() => {
		if (createSubscriptionError) {
			if (createSubscriptionError?.email?.[0] === 'is invalid') {
				setErrorMessage(<FormattedMessage id="EMAIL_IS_INVALID" />);
			} else {
				setErrorMessage(<FormattedMessage id="ERROR_COMMON" />);
			}
		} else {
			setErrorMessage(null);
		}
	}, [createSubscriptionError]);
	useEffect(() => {
		if (createSubscriptionSuccess) {
			setEmail('');
		}
	}, [createSubscriptionSuccess]);

	return (
		<React.Fragment>
			<Container style={{ padding: '120px 10px' }}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={3}>
						<NavLink to="/" className={classes.FooterText}>
							{texts.home}
						</NavLink>
						<br />
						<br />
						<NavLink to="/" className={classes.FooterText}>
							{texts.about}
						</NavLink>
						<br />
						<br />
						<NavLink to="/" className={classes.FooterText}>
							<b>{texts.marketplace}</b>
						</NavLink>
						<br />
						<br />
						<NavLink to="/" className={classes.FooterText}>
							{texts.contact}
						</NavLink>
						<br />
						<br />
					</Grid>
					<Grid item xs={12} sm={4}>
						<p className={clsx(classes.FooterText, 'NoMarginTop')}>
							{texts.stayWithUs}
						</p>
						<a href="https://www.facebook.com/esportfield/">
							<img className={classes.SocialMediaImg} src={fbImg} alt="FB" />
						</a>
						<a href="https://www.linkedin.com/company/esportfield/">
							<img
								className={classes.SocialMediaImg}
								src={linkedinImg}
								alt="LINKEDIN"
							/>
						</a>
						<a href="https://www.instagram.com/esportfieldpl/">
							<img
								className={classes.SocialMediaImg}
								src={instaImg}
								alt="INSTAGRAM"
							/>
						</a>

						<a href="https://twitter.com/esportfield">
							<img
								className={classes.SocialMediaImg}
								src={twitterImg}
								alt="TWITTER"
							/>
						</a>

						<br />
						<br />
						<NavLink to="/contactpage">
							<ButtonSecondary outline="true">{texts.contactUs}</ButtonSecondary>
						</NavLink>
					</Grid>
					<Grid item xs={12} sm={5}>
						<p className={clsx(classes.FooterText, 'NoMarginTop')}>
							{texts.subscribeNewsletter}
						</p>
						<br />
						<StyledTextField
							InputProps={{
								classes: {
									notchedOutline: uiClasses.Input,
								},
							}}
							variant="outlined"
							value={email}
							onChange={(e) => {
								setEmail(e.target.value);
							}}
							placeholder={texts.email}
						/>
						<ButtonSecondary
							disabled={createSubscriptionLoading}
							onClick={handleSubscribe}
						>
							{texts.subscribe}
						</ButtonSecondary>
						<br />
						<br />
						<p style={{ opacity: 0.5 }}>
							<NavLink to="/privacy-policy" className={classes.FooterText}>
								{texts.privacyPolicy}
							</NavLink>
							&nbsp;&nbsp;|&nbsp;&nbsp;
							<NavLink to="/rules-and-regulations" className={classes.FooterText}>
								<FormattedMessage id="RULES_AND_REGULATIONS" />
							</NavLink>
						</p>
					</Grid>
				</Grid>
			</Container>
			<hr style={{ border: '1px solid #5F5F5F' }} />
			<Container style={{ padding: '80px 10px' }}>
				<Grid container direction="row" justify="space-between" alignItems="center">
					<Grid item xs={4}>
						<p className={classes.FooterDescription}>
							MJ Technologies Sp. z o. o. realizuje projekt dofinansowany z Funduszy
							Europejskich z Programu Operacyjnego Polska Wschodnia tj. 2014-2020,
							Działanie 1.1. Platformy startowe dla nowych pomysłów, 1.1.2 Rozwój
							startupów w Polsce Wschodniej pt. „Esportfield – Platforma
							analityczno-społecznościowa dla graczy e-sportowych”
						</p>
						<p className={classes.FooterDescription}>
							Celem projektu jest: rozwój działalności biznesowej spółki przez
							wprowadzenie na rynek platformy i usług Esportfield i uzyskanie
							przychodów ze sprzedaży. Planowanymi efektami są: rozwój funkcjonalności
							produktu; uruchomienie kanałów sprzedaży; kampanie promocyjne.
						</p>
					</Grid>
					<Grid item xs={4} className={classes.FundImgWrapper}>
						<img className={classes.FundImg} src={fund_logo_1} alt="" />
						<img className={classes.FundImg} src={fund_logo_4} alt="" />
						<img className={classes.FundImg} src={fund_logo_2} alt="" />
						<img className={classes.FundImg} src={fund_logo_3} alt="" />
					</Grid>
				</Grid>

				<Grid container className={classes.bottomContainer}>
					<Grid item className={classes.bottom}>
						<img src={logoImg} alt="ESPORTFIELD" />
					</Grid>
					<Grid item className={classes.bottom}>
						<p
							className="header-text-regular font-16px TextAlignRight NoMarginTop"
							style={{ opacity: 0.5 }}
						>
							2019 Esportfield. All rights reserved
						</p>
					</Grid>
				</Grid>
			</Container>
			<ErrorAlert message={messageError} />
			<ErrorAlert
				message={createSubscriptionSuccess && <FormattedMessage id="SUBSCRIPTION_ADDED" />}
				success
			/>
		</React.Fragment>
	);
};

export default injectIntl(Footer);
