import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from '../../axios-instance';

export function* purchaseProduct(action) {
	const { id } = action;
	yield put(actions.purchaseProductLoading(true));

	try {
		const response = yield axios.post('payments/purchase', { payment: { product_id: id } });

		yield put(actions.setRedirectURL(response?.data?.redirect_url));
		yield put(actions.purchaseProductLoading(false));
	} catch (e) {
		console.log(e.response);
		yield put(actions.purchaseProductError(e.response.data));
		yield put(actions.purchaseProductLoading(false));
	}
}
export function* getOnboardedSaga() {
	try {
		const response = yield axios.get('payments/payout_enabled');
		console.log(response);

		yield put(actions.setOnboarded(response.data));
	} catch (e) {
		console.log(e.response);
		yield put(actions.setOnboardedError(e.response));
	}
}
export function* getOnboardingURLSaga() {
	try {
		const response = yield axios.post('payments/get_onboarding_url');

		yield put(actions.setOnboardingURL(response.data.redirect_url));
	} catch (e) {
		console.log(e.response);
		yield put(actions.setOnboardingURLError(e.response));
	}
}
export function* getPayoutRequirementsSaga() {
	try {
		const response = yield axios.get('payments/payout_requirements');

		yield put(actions.setPayoutRequirements(response.data));
	} catch (e) {
		console.log(e.response);
		yield put(actions.setPayoutRequirementsError(e.response));
	}
}
export function* payoutMoneySaga(action) {
	const { coins_amount } = action;
	yield put(actions.setPayoutMoneyLoading(true));

	try {
		yield axios.post('payments/payout', {
			payout: {
				coins_amount,
			},
		});
		yield put(actions.setPayoutMoneyLoading(false));
		yield put(actions.setPayoutMoneySuccess(true));
	} catch (e) {
		console.log(e.response);
		yield put(actions.setPayoutMoneyLoading(false));
		yield put(actions.setPayoutMoneyError(e.response));
	}
}
