import axios from 'axios';

console.log(process.env.NODE_ENV);
const devApi = 'https://dev.api.esportfield.pl/api/v1/';
const prodApi = 'https://api.esportfield.pl/api/v1/';

const baseURL = window.location.hostname === 'www.esportfield.com' ? prodApi : devApi;

const instance = axios.create({
	baseURL,
	headers: { 'Access-Control-Allow-Origin': '*' },
});

export default instance;
