import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Wrapper } from './styles';

const CenterHeaderControls = () => {
	return (
		<Wrapper>
			<a href="https://shop.esportfield.com/">
				<FormattedMessage id="VISIT_OUR_SHOP" />
			</a>
		</Wrapper>
	);
};

export default CenterHeaderControls;
