import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import authReducer from './store/reducers/auth';
import createSagaMiddleware from 'redux-saga';
import {
	watchAuth,
	watchLanguage,
	watchRegister,
	watchGames,
	watchUserRoles,
	watchUserData,
	watchVideoAnalysis,
	watchStats,
	watchVideos,
	watchOrders,
	watchMuse,
	watchFeedbacks,
	watchProducts,
	watchPayments,
	watchRatings,
	watchCourses,
	watchSubscriptions,
} from './store/sagas/index';
import languageReducer from './store/reducers/language';
import registerReducer from './store/reducers/register';
import gamesReducer from './store/reducers/games';
import statsReducer from './store/reducers/stats';
import tutorialReducer from './store/reducers/tutorial';
import userDataReducer from './store/reducers/userData';
import userRolesReducer from './store/reducers/userRoles';
import videoAnalysisReducer from './store/reducers/videoAnalysis';
import videosReducer from './store/reducers/videos';
import viewReducer from './store/reducers/view';
import ordersReducer from './store/reducers/orders';
import feedbacksReducer from './store/reducers/feedbacks';
import generalReducer from './store/reducers/general';
import museReducer from './store/reducers/muse';
import productsReducer from './store/reducers/products';
import paymentsReducer from './store/reducers/payments';
import ratingsReducer from './store/reducers/ratings';
import coursesReducer from './store/reducers/courses';
import subscriptionsReducer from './store/reducers/subscriptions';
import axios from './axios-instance';

const composeEnhancers =
	(process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) ||
	compose;

const rootReducer = combineReducers({
	auth: authReducer,
	feedbacks: feedbacksReducer,
	games: gamesReducer,
	lang: languageReducer,
	orders: ordersReducer,
	payments: paymentsReducer,
	products: productsReducer,
	register: registerReducer,
	stats: statsReducer,
	tutorial: tutorialReducer,
	userData: userDataReducer,
	userRoles: userRolesReducer,
	videoAnalysis: videoAnalysisReducer,
	videos: videosReducer,
	view: viewReducer,
	general: generalReducer,
	muse: museReducer,
	ratings: ratingsReducer,
	courses: coursesReducer,
	subscriptions: subscriptionsReducer,
});

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(watchAuth);
sagaMiddleware.run(watchLanguage);
sagaMiddleware.run(watchRegister);
sagaMiddleware.run(watchGames);
sagaMiddleware.run(watchStats);
sagaMiddleware.run(watchUserData);
sagaMiddleware.run(watchUserRoles);
sagaMiddleware.run(watchVideoAnalysis);
sagaMiddleware.run(watchVideos);
sagaMiddleware.run(watchOrders);
sagaMiddleware.run(watchMuse);
sagaMiddleware.run(watchFeedbacks);
sagaMiddleware.run(watchProducts);
sagaMiddleware.run(watchPayments);
sagaMiddleware.run(watchRatings);
sagaMiddleware.run(watchCourses);
sagaMiddleware.run(watchSubscriptions);

axios.interceptors.request.use((config) => {
	// const token = store.getState().auth.token;
	const token = localStorage.getItem('token');

	if (token) {
		config.headers = {
			Authorization: `Bearer ${token}`,
		};
	} else {
		const token = store.getState().auth.token;
		const email = store.getState().auth.email;

		config.headers.common['X-User-Email'] = email;
		config.headers.common['X-User-Token'] = token;
	}

	return config;
});

export default store;
