import { Divider, Drawer, IconButton, List, ListItem } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledDrawer = styled(Drawer)`
	.MuiDrawer-paper {
		background-color: #252935;
		width: 100%;
		padding: 15px;
		height: 100%;
	}
`;
export const DrawerHeader = styled.div`
	display: 'flex';
	align-items: center;
	justify-content: flex-start;
`;

export const StyledIconButton = styled(IconButton)`
	width: 100%;
	display: flex;
	justify-content: flex-start;
	border-radius: 0;
`;

export const StyledTitle = styled.p`
	color: #fff;
	font-size: 16px;
	margin-left: 15px;
	font-weight: bold;
`;
export const DrawerWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	height: 100%;
`;
export const StyledDivider = styled(Divider)`
	background-color: rgba(255, 255, 255, 0.08);
`;
export const BigNavLink = styled(NavLink)`
	font-size: 16px;
	font-weight: bold;
`;
export const StyledList = styled(List)`
	display: flex;
	flex-direction: column;
	height: 100%;
`;
export const StyledListItem = styled(ListItem)`
	&:last-child {
		margin-top: auto;
	}
`;
