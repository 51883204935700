import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
	userRoles: [],
  error: null
}

const getUserRolesSuccess = (state, action) => {
  return updateObject(state, {userRoles: action.userRoles, error: null});
}

const getUserRolesFail = (state, action) => {
  return updateObject(state, {error: action.error});
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_USER_ROLES_SUCCESS: return getUserRolesSuccess(state, action);
		case actionTypes.GET_USER_ROLES_FAIL: return getUserRolesFail(state, action);
		default: return state;
	}
}

export default reducer;