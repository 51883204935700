export default function appDebug(type, text, debugMode) {
    if (debugMode) {
        const defaultStyles = 'display: block; color: #fff; padding: 5px 10px;';
        const initStyles = `display: block ; background: url( 'https://shop.esportfield.com/skins/user/rwd_shoper_2/images/logo.png' ) no-repeat; padding: 25px 150px`;
        let localText = text;
        if (!type) {
            return console.log(`%cIncorrect type parameter`, `background: #fe00e8; ${defaultStyles}` );
        }
        if (!localText) {
            localText = '';
        }
        switch (type) {
            case 'init':
                return console.log('%c ',`${initStyles}`);
            case 'info':
                return console.log(`%c${localText}`, `background: #007ef4; ${defaultStyles}` );
            case 'success':
                return console.log(`%c${localText}`, `background: #81B214; ${defaultStyles}` );
            case 'warn':
                return console.log(`%c${localText}`, `background: #FC5404; ${defaultStyles}` );
            case 'error':
                return console.log(`%c${localText}`, `background: #f00; ${defaultStyles}` );
            default:
                return console.log(`%cIncorect message type`, `background: #fe00e8; ${defaultStyles}` );
        }
    }
}