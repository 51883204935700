import { Avatar } from '@material-ui/core';
import React from 'react';
import { Wrapper, AvatarWrapper } from './avatar.styled';
import { useSelector } from 'react-redux';

const Container = ({ src, alt }) => {
	const nickname = useSelector((state) => state.userData.userData.nickname);
	return (
		<Wrapper>
			<AvatarWrapper>
				<Avatar src={src} alt={alt} />
			</AvatarWrapper>
			<p>{nickname}</p>
		</Wrapper>
	);
};

export default Container;
