import { AppBar, Drawer } from '@material-ui/core';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 220;

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		position: 'relative',
		margin: '0 auto',
	},
	appBar: {
		right: 'auto',
		left: 'auto',
		zIndex: theme.zIndex.drawer + 1,
		padding: '10px 0',
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		position: 'absolute',
		left: '20px',
		marginRight: 46,
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		right: 'auto',
		left: 'auto',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		right: 'auto',
		left: 'auto',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: '60px',
		[theme.breakpoints.up('sm')]: {
			width: '60px',
		},
	},
	toolbar: {
		top: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
}));

export const StyledAppBar = styled(AppBar)`
	background: ${({ theme }) => theme.colors.black} !important;
	border-bottom: 1px solid ${({ theme }) => theme.colors.strokeBlue};

	.MuiAppBar-colorPrimary {
		background: ${({ theme }) => theme.colors.black} !important;
	}
`;
export const StyledDrawer = styled(Drawer)`
	.MuiDrawer-paper {
		background: ${({ theme }) => theme.landing_page_colors.primary};
		border-right: 1px solid ${({ theme }) => theme.colors.strokeBlue};
		color: #fff;
		font-family: 'Poppins-Regular', sans-serif;
		.MuiSvgIcon-root {
			fill: #fff;
		}

		.MuiListItemIcon-root {
			min-width: 0px;
			margin-right: 20px;
		}
		& > div {
			border-bottom: 1px solid #333;
		}

		.MuiList-root {
			padding-top: 40px;
			a {
				display: block;
				border-left: 1px solid transparent;
				text-decoration: none;
				color: ${({ theme }) => theme.colors.white};
				margin-bottom: 30px;
				&.selected {
					display: block;
					border-left: 1px solid ${({ theme }) => theme.landing_page_colors.red};
					background: linear-gradient(
						90deg,
						rgba(255, 51, 84, 0.1) 0%,
						rgba(196, 196, 196, 0) 100%
					);

					/* background-color: red; */
					.MuiSvgIcon-root {
						fill: ${({ theme }) => theme.landing_page_colors.red} !important;
					}
				}
			}
		}
	}
`;
