import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import appDebug from '../../helpers/debugger.js';

const initialState = {
	userId: null,
	userRole: null,
	token: null,
	refreshToken: null,
	expiresIn: null,
	provider: null,
	providerToken: null,
	error: null,
	errorUserData: null,
	loading: false,
	redirectFromLogin: false,
};

const loginStart = (state, action) => {
	return updateObject(state, { error: null, loading: true });
};
const setRedirectFromLogin = (state, action) => {
	return updateObject(state, { redirectFromLogin: action.redirectFromLogin });
};
const updateExpiresInAndRefreshToken = (state, action) => {
	return updateObject(state, { expiresIn: action.expiresIn, refreshToken: action.refreshToken });
};

const setTokenAndUserId = (state) => {
	const token = localStorage.getItem('token');
	const userId = +localStorage.getItem('userId');
	return updateObject(state, { token, userId });
};


const mapUserRoleIdToRole = (userRoleId) => {
	switch(userRoleId) {
		case 3: appDebug('info', `Auth Login user roleId ${userRoleId} is coach`, true); return "coach";
		case 4: appDebug('info', `Auth Login user roleId ${userRoleId} is player`, true); return "player";
		case 5: appDebug('info', `Auth Login user roleId ${userRoleId} is team`, true); return "team";
		default: appDebug('warn', `Auth Login user roleId ${userRoleId} is mapped as player`, true); return "player";
	}
}


const loginSuccess = (state, action) => {
	return updateObject(state, {
		token: action.token,
		refreshToken: action.refreshToken,
		expiresIn: action.expiresIn,
		userId: action.userId,
		//userRole: action.userRoleId === 3 ? 'coach' : 'player',
		userRole: mapUserRoleIdToRole(action.userRoleId),
		error: null,
		loading: false,
		providerToken: null,
		provider: null,
	});
};

const refreshSuccess = (state, action) => {
	return updateObject(state, {
		token: action.token,
		refreshToken: action.refreshToken,
		error: null,
	});
};

const setUserRole = (state, action) => {
	return updateObject(state, {
		userRole: action.userRole,
	});
};

const setProviderToken = (state, action) => {
	return updateObject(state, {
		providerToken: action.providerToken,
	});
};
const setProvider = (state, action) => {
	return updateObject(state, {
		provider: action.provider,
	});
};
const setAuthErrorNull = (state) => {
	return updateObject(state, {
		error: null,
		errorUserFirstName: null,
		providerToken: null,
		provider: null,
		errorUserData: null,
		redirectFromLogin: false,
	});
};
const setErrorUserData = (state, action) => {
	return updateObject(state, {
		errorUserData: action.errorUserData,
	});
};

const loginFail = (state, action) => {
	return updateObject(state, { error: action.error, loading: false });
};

const logoutStart = (state, action) => {
	return updateObject(state, { error: null, loading: true });
};

const logout = (state, action) => {
	return updateObject(state, {
		token: null,
		userId: null,
		error: null,
		loading: false,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.LOGIN_START:
			return loginStart(state, action);
		case actionTypes.LOGIN_SUCCESS:
			return loginSuccess(state, action);
		case actionTypes.UPDATE_EXPIRES_IN_AND_REFRESH_TOKEN:
			return updateExpiresInAndRefreshToken(state, action);
		case actionTypes.REFRESH_SUCCESS:
			return refreshSuccess(state, action);
		case actionTypes.SET_USER_ROLE:
			return setUserRole(state, action);
		case actionTypes.SET_AUTH_ERROR_NULL:
			return setAuthErrorNull(state);
		case actionTypes.SET_ERROR_USER_DATA:
			return setErrorUserData(state, action);
		case actionTypes.LOGIN_FAIL:
			return loginFail(state, action);
		case actionTypes.LOGOUT_SUCCESS:
			return logout(state, action);
		case actionTypes.LOGOUT_START:
			return logoutStart(state, action);
		case actionTypes.SET_REDIRECT_FROM_LOGIN:
			return setRedirectFromLogin(state, action);
		case actionTypes.SET_PROVIDER:
			return setProvider(state, action);
		case actionTypes.SET_PROVIDER_TOKEN:
			return setProviderToken(state, action);
		case actionTypes.SET_TOKEN_AND_USER_ID:
			return setTokenAndUserId(state);
		default:
			return state;
	}
};

export default reducer;
