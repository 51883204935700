import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoggedOutHeaderControls from './LoggedOutHeaderControls/LoggedOutHeaderControls';
import LoggedInHeaderControls from './LoggedInHeaderControls/LoggedInHeaderControls';
import * as actions from '../../../store/actions/index';

const RightHeaderControls = (props) => {
	const { onSetTokenAndUserId } = props;

	useEffect(() => {
		onSetTokenAndUserId();
	}, [onSetTokenAndUserId]);
	return (
		<div style={{ marginLeft: 'auto' }}>
			{props.loading ? null : (
				<>{props.isAuth ? <LoggedInHeaderControls /> : <LoggedOutHeaderControls />}</>
			)}
		</div>
	);
};

RightHeaderControls.propTypes = {
	isAuth: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
	return {
		isAuth: state.auth.token !== null,
		loading: state.auth.loading,
		view: state.view.view,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSetTokenAndUserId: () => dispatch(actions.setTokenAndUserId()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RightHeaderControls);
