import {put} from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from '../../axios-instance';

export function* getGamesSaga(action) {
  yield put(actions.getGamesStart());
  try {
    const response = yield axios.get('games');
    yield put(actions.getGamesSuccess(response.data));
  } catch (error) {
    yield put(actions.getGamesFail('Wystąpił błąd!'));
  }
}