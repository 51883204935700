import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	orders: null,
	myPublishedOrders: null,
	myBids: null,
	specificOrder: null,
	specificOrderError: null,
	applyForOrderSuccess: false,
	loading: false,
	error: null,
	isOrderSent: false,
};

const setStartOrdersLoading = (state) => {
	return updateObject(state, { loading: true });
};
const setOrdersError = (state, action) => {
	return updateObject(state, { error: action.error, loading: false });
};
const setOrdersSuccess = (state, action) => {
	return updateObject(state, { orders: action.orders, loading: false, error: null });
};
const setOrderSentSuccess = (state) => {
	return updateObject(state, { isOrderSent: true, loading: false });
};
const openOrdersForm = (state) => {
	return updateObject(state, { loading: false, error: null, isOrderSent: false });
};
const setSpecificOrderSuccess = (state, action) => {
	return updateObject(state, { specificOrder: action.order, loading: false, error: null });
};
const setApplyForOrderSuccess = (state, action) => {
	return updateObject(state, { applyForOrderSuccess: action.order, loading: false, error: null });
};
const setMyPublishedOrdersSuccess = (state, action) => {
	return updateObject(state, { myPublishedOrders: action.orders, loading: false, error: null });
};
const setMyBidsSuccess = (state, action) => {
	return updateObject(state, { myBids: action.bids, loading: false, error: null });
};
const resetSendingOrder = (state, action) => {
	return updateObject(state, { loading: false, isOrderSent: false });
};
const setPostOrderError = (state, action) => {
	return updateObject(state, { loading: false, setPostOrderError: action.error });
};
const setSpecificOrderError = (state, action) => {
	return updateObject(state, { loading: false, specificOrderError: action.error });
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_START_ORDERS_LOADING:
			return setStartOrdersLoading(state);
		case actionTypes.SET_ORDERS_ERROR:
			return setOrdersError(state, action);
		case actionTypes.SET_ORDERS_SUCCESS:
			return setOrdersSuccess(state, action);
		case actionTypes.SET_ORDER_SENT_SUCCESS:
			return setOrderSentSuccess(state, action);
		case actionTypes.OPEN_ORDERS_FORM:
			return openOrdersForm(state, action);
		case actionTypes.SET_SPECIFIC_ORDER_SUCCESS:
			return setSpecificOrderSuccess(state, action);
		case actionTypes.SET_APPLY_FOR_ORDER_SUCCESS:
			return setApplyForOrderSuccess(state, action);
		case actionTypes.SET_MY_PUBLISHED_ORDERS_SUCCESS:
			return setMyPublishedOrdersSuccess(state, action);
		case actionTypes.SET_MY_BIDS_SUCCESS:
			return setMyBidsSuccess(state, action);
		case actionTypes.RESET_SENDING_ORDER:
			return resetSendingOrder(state, action);
		case actionTypes.SET_POST_ORDER_ERROR:
			return setPostOrderError(state, action);
		case actionTypes.SET_SPECIFIC_ORDER_ERROR:
			return setSpecificOrderError(state, action);
		default:
			return state;
	}
};

export default reducer;
