import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import LeftHeaderControls from './LeftHeaderControls/LeftHeaderControls';
import RightHeaderControls from './RightHeaderControls/RightHeaderControls';
import { Container } from '@material-ui/core';
import { connect } from 'react-redux';
import { StyledHeader } from './Header.Styled';
import CenterHeaderControls from './CenterHeaderControls/CenterHeaderControls';
import EULogo from './EULogo/EULogo';

const Header = (props) => {
	const { logoutLoading } = props;
	return (
		<StyledHeader>
			<Toolbar>
				<Container style={{ display: 'flex', paddingLeft: '8px' }}>
					<LeftHeaderControls />
					<EULogo />
					{logoutLoading ? null : (
						<>
							<CenterHeaderControls />
							<RightHeaderControls />
						</>
					)}
				</Container>
			</Toolbar>
		</StyledHeader>
	);
};

const mapStateToProps = (state) => {
	return {
		logoutLoading: state.auth.loading,
		view: state.view.view,
	};
};

export default connect(mapStateToProps)(Header);
