import React from 'react';
import clsx from 'clsx';
import { Toolbar, Divider, IconButton, Container } from '@material-ui/core';
import { StyledAppBar, StyledDrawer, useStyles } from './ProfileHeader.Styled';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import LeftHeaderControls from '../LeftHeaderControls/LeftHeaderControls';
import RightHeaderControls from '../RightHeaderControls/RightHeaderControls';

import ProfileHeaderMenu from './ProfileHeaderMenu/ProfileHeaderMenu';
import CenterHeaderControls from '../CenterHeaderControls/CenterHeaderControls';

export default function MiniDrawer(props) {
	const classes = useStyles();

	const [open, setOpen] = React.useState(false);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	return (
		<div className={classes.root}>
			<StyledAppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open,
				})}
			>
				<Toolbar>
					<Container style={{ display: 'flex' }}>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							edge="start"
							className={clsx(classes.menuButton, {
								[classes.hide]: open,
							})}
						>
							<MenuIcon />
						</IconButton>
						<LeftHeaderControls />
						<CenterHeaderControls />
						<RightHeaderControls />
					</Container>
				</Toolbar>
			</StyledAppBar>
			<StyledDrawer
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}
			>
				<div className={classes.toolbar}>
					<IconButton onClick={handleDrawerClose}>
						<ChevronLeftIcon />
					</IconButton>
				</div>
				<Divider />
				<ProfileHeaderMenu />
			</StyledDrawer>
			<main style={{ width: '100%', padding: '85px 50px 0 0', position: 'relative' }}>
				{props.children}
			</main>
		</div>
	);
}
