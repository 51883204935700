import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from '../../axios-instance';

export function* createSubscription(action) {
	const { subscriptionType, data } = action;

	yield put(actions.setCreateSubscriptionLoading(true));
	try {
		yield axios.post(`/subscriptions/${subscriptionType}`, data);
		yield put(actions.setCreateSubscriptionSuccess(true));
	} catch (error) {
		yield put(actions.setCreateSubscriptionError(error.response.data));
	} finally {
		yield put(actions.setCreateSubscriptionLoading(false));
	}
}
