import { Button, Drawer } from '@material-ui/core';
import styled from 'styled-components';

export const BurgerButton = styled(Button)`
	margin-left: auto !important;

	.MuiSvgIcon-root {
		fill: #fff;
	}
`;

export const StyledDrawer = styled(Drawer)`
	.MuiDrawer-paperAnchorRight {
		padding: 40px;
		background-color: #18113c;
		width: 80%;

		img {
			width: 100%;
		}

		.buttons-wrapper {
			margin-top: 40px;
			width: 100%;
			display: flex;
			flex-direction: column;
		}
	}
`;
