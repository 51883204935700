import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from '../../axios-instance';

export function* getSteamStatsSaga(action) {
	yield put(actions.getStatsStart());
	try {
		const userId = action.userId;
		const response = yield axios.get(`/steam_stats/${userId}/all`);
		yield put(actions.getStatsSuccess(response.data));
	} catch (error) {
		console.error(error.response.data.error);
		yield put(actions.getStatsFail('Wystąpił błąd!'));
	}
}
