import * as actionTypes from './actionTypes';

export const openRegisterModal = () => {
	return {
		type: actionTypes.OPEN_REGISTER_MODAL,
	};
};

export const closeRegisterModal = () => {
	return {
		type: actionTypes.CLOSE_REGISTER_MODAL,
	};
};
export const setRegisterErrorNull = () => {
	return {
		type: actionTypes.SET_REGISTER_ERROR_NULL,
	};
};

export const registerStart = () => {
	return {
		type: actionTypes.REGISTER_START,
	};
};

export const registerSuccess = () => {
	return {
		type: actionTypes.REGISTER_SUCCESS,
	};
};

export const registerFail = (error) => {
	return {
		type: actionTypes.REGISTER_FAIL,
		error: error,
	};
};

export const register = (userData) => {
	return {
		type: actionTypes.REGISTER,
		userData: userData,
	};
};
