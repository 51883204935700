import * as actionTypes from './actionTypes';

export const setView = (view) => {
	return {
		type: actionTypes.SET_VIEW,
		view,
	};
};
export const setIsKeyListenerDisabled = (value) => {
	return {
		type: actionTypes.SET_IS_KEY_LISTENER_DISABLED,
		value,
	};
};
export const setIsMarkerModalOpen = (value) => {
	return {
		type: actionTypes.SET_IS_MARKER_MODAL_OPEN,
		value,
	};
};
