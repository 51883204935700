import * as actionTypes from './actionTypes';

export const sendFeedbackLoading = () => {
	return {
		type: actionTypes.SEND_FEEDBACK_LOADING,
	};
};

export const sendFeedbackSuccess = (response) => {
	return {
		type: actionTypes.SEND_FEEDBACK_SUCCESS,
		response,
	};
};

export const sendFeedbackError = (error) => {
	return {
		type: actionTypes.SEND_FEEDBACK_ERROR,
		error,
	};
};

export const sendFeedback = (feedback) => {
	return {
		type: actionTypes.SEND_FEEDBACK,
		feedback,
	};
};
