import * as actionTypes from './actionTypes';

export const loginStart = () => {
	return {
		type: actionTypes.LOGIN_START,
	};
};

export const loginSuccess = (token, refreshToken, expiresIn, userId, userRoleId) => {
	return {
		type: actionTypes.LOGIN_SUCCESS,
		token,
		refreshToken,
		expiresIn,
		userId,
		userRoleId,
	};
};
export const setMyDataSuccess = (userId) => {
	return {
		type: actionTypes.SET_MY_DATA_SUCCESS,
		userId,
	};
};
export const refreshSuccess = (token, refreshToken) => {
	return {
		type: actionTypes.REFRESH_SUCCESS,
		token,
		refreshToken,
	};
};
export const updateExpiresInAndRefreshToken = (expiresIn, refreshToken) => {
	return {
		type: actionTypes.UPDATE_EXPIRES_IN_AND_REFRESH_TOKEN,
		expiresIn,
		refreshToken,
	};
};

export const setUserRole = (userRole) => {
	return {
		type: actionTypes.SET_USER_ROLE,
		userRole: userRole,
	};
};
export const setRedirectFromLogin = (redirectFromLogin) => {
	return {
		type: actionTypes.SET_REDIRECT_FROM_LOGIN,
		redirectFromLogin,
	};
};
export const setErrorUserData = (errorUserData) => {
	return {
		type: actionTypes.SET_ERROR_USER_DATA,
		errorUserData: errorUserData,
	};
};
export const setAuthErrorNull = () => {
	localStorage.removeItem('user_role');
	localStorage.removeItem('provider');

	return {
		type: actionTypes.SET_AUTH_ERROR_NULL,
	};
};

export const loginFail = (error) => {
	return {
		type: actionTypes.LOGIN_FAIL,
		error: error,
	};
};

export const login = (loginData) => {
	return {
		type: actionTypes.LOGIN,
		loginData,
	};
};

export const logoutStart = () => {
	return {
		type: actionTypes.LOGOUT_START,
	};
};
export const logout = (data) => {
	return {
		type: actionTypes.LOGOUT_START,
		data,
	};
};

export const logoutSuccess = () => {
	return {
		type: actionTypes.LOGOUT_SUCCESS,
	};
};

export const autoLogin = () => {
	return {
		type: actionTypes.AUTO_LOGIN,
	};
};

export const setProvider = (provider) => {
	return {
		type: actionTypes.SET_PROVIDER,
		provider,
	};
};
export const setProviderToken = (providerToken) => {
	return {
		type: actionTypes.SET_PROVIDER_TOKEN,
		providerToken,
	};
};
export const getTokenInfo = () => {
	return {
		type: actionTypes.GET_TOKEN_INFO,
	};
};
export const setMyData = () => {
	return {
		type: actionTypes.SET_MY_DATA,
	};
};
export const refreshToken = (refreshData) => {
	return {
		type: actionTypes.REFRESH_TOKEN,
		refreshData,
	};
};
export const setTokenAndUserId = () => {
	return {
		type: actionTypes.SET_TOKEN_AND_USER_ID,
	};
};
