import axios from '../../axios-instance';
import { put, take, call, fork } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import { CancelToken } from 'axios';

import * as actions from '../actions/index';

const source = CancelToken.source();

const upload = (file, onUploadProgress) => {
	return axios.post(`/game_videos`, file, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		cancelToken: source.token,
		onUploadProgress,
	});
};

function createUploader(file) {
	let emit;
	const chan = eventChannel((emitter) => {
		emit = emitter;
		return () => {};
	});
	const uploadProgressCb = ({ total, loaded }) => {
		const percentage = Math.round((loaded * 100) / total);
		emit(percentage);
		if (percentage === 100) emit(END);
	};
	const uploadPromise = upload(file, uploadProgressCb);
	return [uploadPromise, chan];
}

function* uploadProgressWatcher(chan) {
	while (true) {
		// eslint-disable-line no-constant-condition
		const progress = yield take(chan);
		yield put(actions.setUploadPercentage(progress));
	}
}

export function* addNewVideoSaga(action) {
	const { data } = action;
	yield put(actions.setStartVideosLoading());
	try {
		const [uploadPromise, chan] = yield call(createUploader, data);
		yield fork(uploadProgressWatcher, chan);
		const res = yield call(() => uploadPromise);
		yield put(actions.getVideosSuccess(res.data));
	} catch (e) {
		console.log(e.response);
		yield put(actions.setVideosError(e.response));
	}
}
export function* getVideosSaga(action) {
	const { payload } = action;
	yield put(actions.setStartGetVideosLoading());
	try {
		const response = yield axios.get('game_videos');
		if (payload) {
			yield put(actions.getVideosSuccess(response.data, payload));
		} else {
			yield put(actions.getVideosSuccess(response.data, 'from_table'));
		}
	} catch (e) {
		yield put(actions.setVideosError(e.response));
	}
}
export function* deleteVideoSaga(action) {
	const { video_id, coachId } = action;
	yield put(actions.setStartDeleteVideosLoading());
	try {
		yield axios.delete(`game_videos/${video_id}`);
		yield put(actions.getVideos('override'));
		yield put(actions.getVideoAnalysisByCoachId(coachId));
	} catch (e) {
		console.log(e.response);
		yield put(actions.setVideosError(e.response));
	}
}
export function* updateVideoSaga(action) {
	const { video_id, object } = action;

	try {
		yield axios.patch(`game_videos/${video_id}`, { video: { ...object } });
	} catch (e) {
		console.log(e.response);
		yield put(actions.setVideosError(e.response));
	}
}

export function* cancelUploadSaga() {
	yield source.cancel('Operation canceled by the user.');
}
