import * as actionTypes from './actionTypes';

export const setStartOrdersLoading = () => {
	return {
		type: actionTypes.SET_START_ORDERS_LOADING,
	};
};

export const setOrdersSuccess = (orders) => {
	return {
		type: actionTypes.SET_ORDERS_SUCCESS,
		orders,
	};
};

export const setOrdersError = (error) => {
	return {
		type: actionTypes.SET_ORDERS_ERROR,
		error,
	};
};

export const postOrder = (orderData) => {
	return {
		type: actionTypes.POST_ORDER,
		orderData,
	};
};
export const getOpenedOrders = (filters) => {
	return {
		type: actionTypes.GET_OPENED_ORDERS,
		filters,
	};
};
export const setOrdersSentSuccess = () => {
	return {
		type: actionTypes.SET_ORDER_SENT_SUCCESS,
	};
};

export const openOrdersForm = () => {
	return {
		type: actionTypes.OPEN_ORDERS_FORM,
	};
};
export const setSpecificOrderSuccess = (order) => {
	return {
		type: actionTypes.SET_SPECIFIC_ORDER_SUCCESS,
		order,
	};
};
export const getSpecificOrder = (id) => {
	return {
		type: actionTypes.GET_SPECIFIC_ORDER,
		id,
	};
};
export const applyForOrder = (id) => {
	return {
		type: actionTypes.APPLY_FOR_ORDER,
		id,
	};
};

export const setApplyForOrderSuccess = (order) => {
	return {
		type: actionTypes.SET_APPLY_FOR_ORDER_SUCCESS,
		order,
	};
};
export const getMyOrders = (payload, filters) => {
	return {
		type: actionTypes.GET_MY_ORDERS,
		payload,
		filters,
	};
};
export const setMyPublishedOrdersSuccess = (orders) => {
	return {
		type: actionTypes.SET_MY_PUBLISHED_ORDERS_SUCCESS,
		orders,
	};
};
export const acceptCurrentBid = (bidId) => {
	return {
		type: actionTypes.ACCEPT_BID,
		bidId,
	};
};
export const rejectCurrentBid = (bidId) => {
	return {
		type: actionTypes.REJECT_BID,
		bidId,
	};
};
export const markBidAsViewed = (bidsData, user) => {
	return {
		type: actionTypes.MARK_BID_AS_VIEWED,
		bidsData,
		user,
	};
};
export const setMyBidsSuccess = (bids) => {
	return {
		type: actionTypes.SET_MY_BIDS_SUCCESS,
		bids,
	};
};
export const getMyBids = (payload) => {
	return {
		type: actionTypes.GET_MY_BIDS,
		payload,
	};
};
export const updateOrder = (orderId, fromAnalysis, status) => {
	return {
		type: actionTypes.UPDATE_ORDER,
		orderId,
		fromAnalysis,
		status,
	};
};
export const deleteMyBid = (orderId, bidId) => {
	return {
		type: actionTypes.DELETE_MY_BID,
		orderId,
		bidId,
	};
};
export const resetSendingOrder = () => {
	return {
		type: actionTypes.RESET_SENDING_ORDER,
	};
};
export const setPostOrderError = (error) => {
	return {
		type: actionTypes.SET_POST_ORDER_ERROR,
		error,
	};
};
export const setSpecificOrderError = (error) => {
	return {
		type: actionTypes.SET_SPECIFIC_ORDER_ERROR,
		error,
	};
};
