import React from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import messages_pl from './messages/pl.json';
import messages_en from './messages/en.json';
import PropTypes from 'prop-types';

const messages = {
  'pl': messages_pl,
  'en': messages_en
};

const ConnectedIntlProvider = props => {
  return (
    <IntlProvider locale={props.language} messages={messages[props.language]}>
      {props.children}
    </IntlProvider>
  );
}

ConnectedIntlProvider.propTypes = {
  language: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

const mapStateToProps = state => {
  return {
    language: state.lang.language
  };
}

export default connect(mapStateToProps)(ConnectedIntlProvider);