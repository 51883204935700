import * as actionTypes from './actionTypes';

export const getUserRolesSuccess = (userRoles) => {
  return {
    type: actionTypes.GET_USER_ROLES_SUCCESS,
    userRoles: userRoles
  }
}

export const getUserRolesFail = (error) => {
  return {
    type: actionTypes.GET_USER_ROLES_FAIL,
    error: error
  }
}

export const getUserRoles = () => {
  return {
    type: actionTypes.GET_USER_ROLES
  }
}