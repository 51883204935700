import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
  debugMode: false
}

const turnOnDebugMode = (state, action) => {
  return updateObject(state, {debugMode: true});
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.TURN_ON_DEBUG_MODE: return turnOnDebugMode(state, action);
		default: return state;
	}
}

export default reducer;