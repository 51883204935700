import * as actionTypes from './actionTypes';

/* General band analysis object */
export const createGeneralBandAnalysis = () => {
	return {
		type: actionTypes.CREATE_GENERAL_BAND_ANALYSIS,
	};
};

export const createGeneralBandAnalysisSuccess = (payload) => {
	return {
		type: actionTypes.CREATE_GENERAL_BAND_ANALYSIS_SUCCESS,
        payload
	};
};

export const createGeneralBandAnalysisFail = (payload) => {
	return {
		type: actionTypes.CREATE_GENERAL_BAND_ANALYSIS_FAIL,
        payload
	};
};

export const setGeneralBandAnalysisStoreId = (payload) => {
	return {
		type: actionTypes.SET_GENERAL_BAND_ANALYSIS_STORE_ID,
		payload
	};
};

export const getGeneralBandAnalysisList = () => {
	return {
		type: actionTypes.GET_GENERAL_BAND_ANALYSIS_LIST,
	};
};

export const getGeneralBandAnalysisListFail = (payload) => {
	return {
		type: actionTypes.GET_GENERAL_BAND_ANALYSIS_LIST_FAIL,
		payload
	};
};

/* General band analysis object */

/* Muse band analysis sesion measurement */
export const createMuseBandAnalysis = () => {
	return {
		type: actionTypes.CREATE_MUSE_BAND_ANALYSIS,
	};
};

export const getMuseBandAnalysisList = (payload) => {
	return {
		type: actionTypes.GET_MUSE_BAND_ANALYSIS_LIST,
		payload
	};
};

export const getMuseBandAnalysisListSuccess = (payload) => {
	return {
		type: actionTypes.GET_MUSE_BAND_ANALYSIS_LIST_SUCCESS,
        payload
	};
};

export const getMuseBandAnalysisListFail = (payload) => {
	return {
		type: actionTypes.GET_MUSE_BAND_ANALYSIS_LIST_FAIL,
        payload
	};
};

export const createMuseBandAnalysisSuccess = (payload) => {
	return {
		type: actionTypes.CREATE_MUSE_BAND_ANALYSIS_SUCCESS,
        payload
	};
};

export const createMuseBandAnalysisFail = (payload) => {
	return {
		type: actionTypes.CREATE_MUSE_BAND_ANALYSIS_FAIL,
        payload
	};
};

export const postMuseBandAnalysisPackage = (payload) => {
	return {
		type: actionTypes.POST_MUSE_BAND_ANALYSIS_PACKAGE,
		payload
	};
};

export const postMuseBandAnalysisPackageSuccess = (payload) => {
	return {
		type: actionTypes.POST_MUSE_BAND_ANALYSIS_PACKAGE_SUCCESS,
        payload
	};
};

export const postMuseBandAnalysisPackageFail = (payload) => {
	return {
		type: actionTypes.POST_MUSE_BAND_ANALYSIS_PACKAGE_FAIL,
        payload
	};
};

export const getMuseBandAnalysisPackages = (payload) => {
	return {
		type: actionTypes.GET_MUSE_BAND_ANALYSIS_PACKAGES,
		payload
	};
};

export const getMuseBandAnalysisPackagesSuccess = (payload) => {
	return {
		type: actionTypes.GET_MUSE_BAND_ANALYSIS_PACKAGES_SUCCESS,
        payload
	};
};

export const getMuseBandAnalysisPackagesFail = (payload) => {
	return {
		type: actionTypes.GET_MUSE_BAND_ANALYSIS_PACKAGES_FAIL,
        payload
	};
};

export const clearRecordedDataForChart = () => {
	return {
		type: actionTypes.CLEAR_RECORDED_DATA_FOR_CHART
	};
};

export const clearStoreMuseBandAnalysisList = () => {
	return {
		type: actionTypes.CLEAR_STORE_MUSE_BAND_ANALYSIS_LIST
	};
};

export const turnOnMuseDebugg = () => {
	return {
		type: actionTypes.TURN_ON_MUSE_DEBUGG
	};
};

/* Muse band analysis sesion measurement */
