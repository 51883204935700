/* eslint-disable no-extend-native */
export const formatBalanceAmount = (balance) => {
	String.prototype.splice = function (idx, rem, str) {
		return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
	};

	if (balance) {
		const string = `${balance}`;

		const stringLength = string.length;

		if (string.length >= 4) {
			const index = stringLength - 3;

			const result = string.splice(index, 0, ',');

			return result;
		}
		return string;
	}
	return 0;
};
