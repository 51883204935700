export const theme = {
	colors: {
		black: '#0a0a0a',
		blue: '#0e76bc',
		red: '#FF3354',
		darkBlue: '#32374c',
		lightBlue: '#3ea6ff',
		primary: '#0a1422',
		secondary: '#1c1e2e',
		white: '#fff',
		yellow: '#dad40c',
		gray: '#808080',
		blueGray: '#c8c9e8',
		darkGray: '#646464',
		lightGray: '#b0b0b0',
		darkestGray: '#2d2d2d',
		inputSelection: '#ffff0070',
		successGreen: '#1aa000',
		deleteRed: '#d82d2d',
		strokeBlue: '#1E222D',
		lightOn: '#767D92',
	},

	landing_page_colors: {
		primary: '#0E0E0E',
		red: '#FF3354',
		darkBlue: '#2D2A4D',
		blueGray: '#5E7B8D',
		darkBlueBackground: '#131621',
	},

	boxShadows: {
		primary: '0px 0px 15px rgba(9,102,241,0.56)',
	},
};
