import styled from 'styled-components';

export const Wrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	a {
		color: ${({ theme }) => theme.landing_page_colors.red};
		text-decoration: none;
		text-transform: uppercase;
		font-weight: 600;
	}
`;
