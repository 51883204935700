import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	language: 'en',
};

const changeLanguageSuccess = (state, action) => {
	return updateObject(state, { language: action.language });
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CHANGE_LANGUAGE_SUCCESS:
			return changeLanguageSuccess(state, action);
		default:
			return state;
	}
};

export default reducer;
