import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
const initialState = {
	userData: null,
	error: null,
	tutorialContent: null,
	availableLanguages: [],
	availableGames: [],
	userGames: [],
	gamesRanks: [],
	availableGameRanks: [{empty: true}]
};
const getUserDataSuccess = (state, action) => {
	if (!!!action.userData) {
		return updateObject(state, {
			userData: null,
			error: null,
			tutorialContent: null,
		});
	}
	if (action.userData.tutorial) {
		return updateObject(state, {
			userData: action.userData,
			error: null,
			tutorialContent: action.userData.tutorial.content,
		});
	} else {
		return updateObject(state, {
			userData: action.userData,
			error: null,
		});
	}
};
const getUserDataFail = (state, action) => {
	return updateObject(state, { error: action.error });
};
const getTutorialSuccess = (state, action) => {
	return updateObject(state, { tutorialContent: action.content });
};
const getAvailableUserLanguagesReducerSuccess = (state, action) => {
	return updateObject(state, { availableLanguages: action.payload });
};
const getAvailableUserLanguagesReducerFail = (state, action) => {
	return updateObject(state, { error: action.error });
};
const setUserLanguageReducerSuccess = (state, action) => {
	return updateObject(state, { state } );
};
const setUserLanguageReducerFail = (state, action) => {
	return updateObject(state, { error: action.error });
};
const getAvailableUserGamesReducerSuccess = (state, action) => {
	return updateObject(state, { availableGames: action.payload });
};
const getAvailableUserGamesReducerFail = (state, action) => {
	return updateObject(state, { error: action.error });
};
const getUserGamesReducerSuccess = (state, action) => {
	return updateObject(state, { userGames: action.payload });
};
const getUserGamesReducerFail = (state, action) => {
	return updateObject(state, { error: action.error });
};
const getGameRanksReducerSuccess = (state, action) => {
	let updatedGamesRanks = state.gamesRanks;
	updatedGamesRanks[action.payload.gameId] = action.payload.data;
	return updateObject(state, { gamesRanks: updatedGamesRanks });
};
const getGameRanksReducerFail = (state, action) => {
	return updateObject(state, { error: action.error });
};
const getAvailableGameRanksReducerSuccess = (state, action) => {
	let updatedAvailableGameRanks = state.availableGameRanks;
	updatedAvailableGameRanks[action.payload.gameId] = action.payload.data;
	return updateObject(state, { availableGameRanks: updatedAvailableGameRanks });
};
const getAvailableGameRanksReducerFail = (state, action) => {
	return updateObject(state, { error: action.error });
};
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_USER_DATA_SUCCESS:
			return getUserDataSuccess(state, action);
		case actionTypes.GET_USER_DATA_FAIL:
			return getUserDataFail(state, action);
		case actionTypes.GET_TUTORIAL_SUCCESS:
			return getTutorialSuccess(state, action);
		case actionTypes.GET_AVAILABLE_USER_LANGUAGES_SUCCESS:
			return getAvailableUserLanguagesReducerSuccess(state, action);
		case actionTypes.GET_AVAILABLE_USER_LANGUAGES_FAIL:
			return getAvailableUserLanguagesReducerFail(state, action);
		case actionTypes.SET_USER_LANGUAGE_SUCCESS:
			return setUserLanguageReducerSuccess(state, action);
		case actionTypes.SET_USER_LANGUAGE_FAIL:
			return setUserLanguageReducerFail(state, action);
		case actionTypes.GET_AVAILABLE_USER_GAMES_SUCCESS:
			return getAvailableUserGamesReducerSuccess(state, action);
		case actionTypes.GET_AVAILABLE_USER_GAMES_FAIL:
			return getAvailableUserGamesReducerFail(state, action);
		case actionTypes.GET_USER_GAMES_SUCCESS:
			return getUserGamesReducerSuccess(state, action);
		case actionTypes.GET_USER_GAMES_FAIL:
			return getUserGamesReducerFail(state, action);
		case actionTypes.GET_GAME_RANKS_SUCCESS:
			return getGameRanksReducerSuccess(state, action);
		case actionTypes.GET_GAME_RANKS_FAIL:
		return getGameRanksReducerFail(state, action);
		case actionTypes.GET_AVAILABLE_GAME_RANKS_SUCCESS:
			return getAvailableGameRanksReducerSuccess(state, action);
		case actionTypes.GET_AVAILABLE_GAME_RANKS_FAIL:
			return getAvailableGameRanksReducerFail(state, action);
		default:
			return state;
	}
};
export default reducer;
