import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from '../../axios-instance';
import { select } from 'redux-saga/effects';
import appDebug from '../../helpers/debugger.js';

export const getDebugMode = (state) => state.general.debugMode;

export function* registerSaga(action) {
	let debugMode = yield select(getDebugMode);
	yield put(actions.registerStart());
	try {
		appDebug('info',`Function registerSaga send register data ${JSON.stringify(action.userData)}`, debugMode);
		const response = yield axios.post('users/custom', action.userData);
		yield localStorage.setItem('refresh_token', response.data.access_token.refresh_token);
		yield localStorage.setItem('token', response.data.access_token.access_token);
		yield localStorage.setItem('userId', response.data.id);
		appDebug('success',`Function registerSaga response data ${JSON.stringify(response.data)}`, debugMode);
		appDebug('success',`Function registerSaga data.user_role.id ${response.data.user_role.id}`, debugMode);
		yield put(
			actions.loginSuccess(
				response.data.access_token.access_token,
				response.data.access_token.refresh_token,
				response.data.access_token.expires_in,
				response.data.id,
				response.data.user_role.id
			)
		);
		yield put(actions.registerSuccess());
	} catch (error) {
		appDebug('error', `Error ${error}`, debugMode);
		const errorPasswordTooShort = error.response.data.errors.password;
		const errorEmail = error.response.data.errors.email;
		const errorPassowrdConfirmation = error.response.data.errors.password_confirmation;
		if (errorEmail) {
			yield put(actions.registerFail('Email has already been taken'));
		} else if (errorPassowrdConfirmation) {
			yield put(actions.registerFail('Password do not match'));
		} else if (errorPasswordTooShort) {
			yield put(actions.registerFail('Password too short'));
		} else {
			yield put(actions.registerFail('Wystąpił błąd!'));
		}
	}
}
