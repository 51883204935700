import { NavLink } from 'react-router-dom';
import { Menu } from '@material-ui/core';
import styled from 'styled-components';

export const StyledNavLink = styled(NavLink)`
	width: 100%;
	height: 100%;
	text-decoration: none;
	font-family: 'Poppins-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const StyledMenu = styled(Menu)`
	.MuiPopover-paper {
		background-color: ${({ theme }) => theme.colors.black};
		color: white;
		top: 64px !important;
		padding: 10px 0px 10px 0;
		.MuiMenuItem-root {
			a {
				color: white;
			}
		}
	}
`;

export const Divider = styled.div`
	height: 85px;
	margin: -20px 25px -20px;
	width: 1px;
	background-color: ${({ theme }) => theme.colors.strokeBlue};
`;
