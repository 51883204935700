import styled from 'styled-components';

export const LogoComponentStyled = styled.div`
	margin-top: 10px;
    
    img {
        max-width: 200px;
        height: auto;
    }
    
    @media only screen and (max-width: 450px) {
        padding-right: 5px;
        img {
            max-width: 100px !important;
        }
    }
`;
