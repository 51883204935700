import * as actionTypes from './actionTypes';

export const getProductsLoading = (bool) => {
	return {
		type: actionTypes.GET_PRODUCTS_LOADING,
		bool,
	};
};
export const getProductsError = (error) => {
	return {
		type: actionTypes.GET_PRODUCTS_ERROR,
		error,
	};
};
export const setAllProducts = (products) => {
	return {
		type: actionTypes.SET_ALL_PRODUCTS,
		products,
	};
};
export const getAllProducts = () => {
	return {
		type: actionTypes.GET_ALL_PRODUCTS,
	};
};
