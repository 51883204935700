import * as actionTypes from './actionTypes';

export const getUserDataSuccess = (userData) => {
	return {
		type: actionTypes.GET_USER_DATA_SUCCESS,
		userData,
	};
};

export const getUserDataFail = (error) => {
	return {
		type: actionTypes.GET_USER_DATA_FAIL,
		error: error,
	};
};

export const getUserData = () => {
	return {
		type: actionTypes.GET_USER_DATA,
	};
};
export const getTutorialSuccess = (content) => {
	return {
		type: actionTypes.GET_TUTORIAL_SUCCESS,
		content,
	};
};

export const getAvailableUserLanguages = () => {
	return {
		type: actionTypes.GET_AVAILABLE_USER_LANGUAGES,
	};
};

export const getAvailableUserLanguagesSuccess = (payload) => {
	return {
		type: actionTypes.GET_AVAILABLE_USER_LANGUAGES_SUCCESS,
		payload,
	};
};

export const getAvailableUserLanguagesFail = (error) => {
	return {
		type: actionTypes.GET_AVAILABLE_USER_LANGUAGES_FAIL,
		error: error,
	};
};

export const setUserLanguage = (payload) => {
	return {
		type: actionTypes.SET_USER_LANGUAGE,
		payload,
	};
};

export const setUserLanguageSuccess = (payload) => {
	return {
		type: actionTypes.SET_USER_LANGUAGE_SUCCESS,
		payload,
	};
};

export const setUserLanguageFail = (error) => {
	return {
		type: actionTypes.SET_USER_LANGUAGE_FAIL,
		error: error,
	};
};

export const getAvailableUserGames = () => {
	return {
		type: actionTypes.GET_AVAILABLE_USER_GAMES,
	};
};

export const getAvailableUserGamesSuccess = (payload) => {
	return {
		type: actionTypes.GET_AVAILABLE_USER_GAMES_SUCCESS,
		payload,
	};
};

export const getAvailableUserGamesFail = (error) => {
	return {
		type: actionTypes.GET_AVAILABLE_USER_GAMES_FAIL,
		error: error,
	};
};

export const getUserGames = () => {
	return {
		type: actionTypes.GET_USER_GAMES,
	};
};

export const getUserGamesSuccess = (payload) => {
	return {
		type: actionTypes.GET_USER_GAMES_SUCCESS,
		payload,
	};
};

export const getUserGamesFail = (error) => {
	return {
		type: actionTypes.GET_USER_GAMES_FAIL,
		error: error,
	};
};

export const addUserGame = (payload) => {
	return {
		type: actionTypes.ADD_USER_GAME,
		payload,
	};
};

export const addUserGameSuccess = (payload) => {
	return {
		type: actionTypes.ADD_USER_GAME_SUCCESS,
		payload,
	};
};

export const addUserGamesFail = (error) => {
	return {
		type: actionTypes.ADD_USER_GAME_FAIL,
		error: error,
	};
};

export const deleteUserGame = (payload) => {
	return {
		type: actionTypes.DELETE_USER_GAME,
		payload,
	};
};

export const deleteUserGameSuccess = (payload) => {
	return {
		type: actionTypes.DELETE_USER_GAME_SUCCESS,
		payload,
	};
};

export const deleteUserGamesFail = (error) => {
	return {
		type: actionTypes.DELETE_USER_GAME_FAIL,
		error: error,
	};
};

export const getAvailableGameRanks = (payload) => {
	return {
		type: actionTypes.GET_AVAILABLE_GAME_RANKS,
		payload,
	};
};

export const getAvailableGameRanksSuccess = (payload) => {
	return {
		type: actionTypes.GET_AVAILABLE_GAME_RANKS_SUCCESS,
		payload,
	};
};

export const getAvailableGameRanksFail = (error) => {
	return {
		type: actionTypes.GET_AVAILABLE_GAME_RANKS_FAIL,
		error: error,
	};
};

export const getGameRanks = (payload) => {
	return {
		type: actionTypes.GET_GAME_RANKS,
		payload,
	};
};

export const getGameRanksSuccess = (payload) => {
	return {
		type: actionTypes.GET_GAME_RANKS_SUCCESS,
		payload,
	};
};

export const getGameRanksFail = (error) => {
	return {
		type: actionTypes.GET_GAME_RANKS_FAIL,
		error: error,
	};
};

export const setGameRank = (payload) => {
	return {
		type: actionTypes.SET_GAME_RANK,
		payload,
	};
};

export const setGameRankSuccess = (payload) => {
	return {
		type: actionTypes.SET_GAME_RANK_SUCCESS,
		payload,
	};
};

export const setGameRankFail = (error) => {
	return {
		type: actionTypes.SET_GAME_RANK_FAIL,
		error: error,
	};
};

export const deleteGameRank = (payload) => {
	return {
		type: actionTypes.DELETE_GAME_RANK,
		payload,
	};
};

export const deleteGameRankSuccess = (payload) => {
	return {
		type: actionTypes.DELETE_GAME_RANK_SUCCESS,
		payload,
	};
};

export const deleteGameRankFail = (error) => {
	return {
		type: actionTypes.DELETE_GAME_RANK_FAIL,
		error: error,
	};
};
