import styled from 'styled-components';

export const EULogoComponentStyled = styled.div`
	margin-left: 30px;
    padding-top: 10px;

    img {
        max-width: 60px;
        height: auto;
    }

    p {
        text-decoration: none;
        text-align: center;
        color: #fff;
        font-size: 0.5rem;
    }
    
    @media only screen and (max-width: 450px) {
        padding-right: 5px;
        img {
            max-width: 3rem !important;
        }
    }
`;
