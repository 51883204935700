import React from 'react';
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MuseumIcon from '@material-ui/icons/Museum';
import MovieIcon from '@material-ui/icons/Movie';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BugReportIcon from '@material-ui/icons/BugReport';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

export default function useMenuData(userData, amount) {
	const [loading, setLoading] = useState(true);
	const [menuData, setMenuData] = useState([]);

	useEffect(() => {
		if (userData) {
			const data = [
				{
					title: <FormattedMessage id="profile.yourProfile" />,
					icon: <AccountCircleIcon style={{ fill: '#3E414F' }} />,
					link: `/profile/${userData.id}`,
				},
				{
					title: <FormattedMessage id="common.marketplace" />,
					icon: <MuseumIcon style={{ fill: '#3E414F' }} />,
					link: `/marketplace`,
				},
				{
					title: <FormattedMessage id="orders.my" />,
					icon: <SubscriptionsIcon style={{ fill: '#3E414F' }} />,
					link: `/orders`,
					amount,
				},
				{
					title: <FormattedMessage id="video.my" />,
					icon: <MovieIcon style={{ fill: '#3E414F' }} />,
					link: `/video-upload`,
				},
				{
					title: <FormattedMessage id="reportBug" />,
					icon: <BugReportIcon style={{ fill: '#3E414F' }} />,
					link: '/bug-report',
					id: 'bug',
				},
			];

			if (userData.user_role.name === 'coach') {
				data.push({
					title: <FormattedMessage id="video.myAnalysis" />,
					icon: <ShowChartIcon style={{ fill: '#3E414F' }} />,
					link: `/video-analysis-list`,
				});
				data.push({
					title: <FormattedMessage id="Wallet" />,
					icon: <AccountBalanceWalletIcon style={{ fill: '#3E414F' }} />,
					link: '/wallet',
					id: 'wallet',
				});
			}
			if (userData.user_role.name === 'player') {
				data.push({
					title: <FormattedMessage id="Wallet" />,
					icon: <AccountBalanceWalletIcon style={{ fill: '#3E414F' }} />,
					link: '/wallet',
					id: 'wallet',
				});
			}
			setMenuData(data);
			setLoading(false);
		}
	}, [userData, setMenuData, setLoading, amount]);

	return { menuData, loading };
}
