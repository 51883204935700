import * as actionTypes from './actionTypes';

export const createVideoLabel = (layerId, frameFrom, frameTo, videoAnalysisId) => {
	return {
		type: actionTypes.CREATE_VIDEO_LABEL,
		layerId,
		frameFrom,
		frameTo,
		videoAnalysisId,
	};
};
export const createVideoLayer = (videoAnalysisId) => {
	return {
		type: actionTypes.CREATE_VIDEO_LAYER,
		videoAnalysisId,
	};
};

export const createVideoLabelSuccess = (videoLayer) => {
	return {
		type: actionTypes.CREATE_VIDEO_LABEL_SUCCESS,
		videoLayer: videoLayer,
	};
};
export const setCreateVideoAnalysisLoading = (boolean) => {
	return {
		type: actionTypes.SET_CREATE_VIDEO_ANALYSIS_LOADING,
		boolean,
	};
};

export const createVideoLabelFail = (error) => {
	return {
		type: actionTypes.CREATE_VIDEO_LABEL_FAIL,
		error: error,
	};
};

export const deleteVideoLayer = (id) => {
	return {
		type: actionTypes.DELETE_VIDEO_LAYER,
		id: id,
	};
};

export const deleteVideoLayerSuccess = (id) => {
	return {
		type: actionTypes.DELETE_VIDEO_LAYER_SUCCESS,
		id: id,
	};
};

export const setVideoLayers = (videoLayers) => {
	return {
		type: actionTypes.SET_VIDEO_LAYERS,
		videoLayers: videoLayers,
	};
};

export const getVideoLayers = (gameAnalysisId, payload) => {
	return {
		type: actionTypes.GET_VIDEO_LAYERS,
		gameAnalysisId: gameAnalysisId,
		payload,
	};
};

export const getVideoLayersFail = (error) => {
	return {
		type: actionTypes.GET_VIDEO_LAYERS_FAIL,
		error: error,
	};
};
export const getVideoLayersSuccess = () => {
	return {
		type: actionTypes.GET_VIDEO_LAYERS_SUCCESS,
	};
};
export const setDrawRef = (drawRef) => {
	return {
		type: actionTypes.SET_DRAW_REF,
		drawRef,
	};
};
export const setDrawRefId = (id) => {
	return {
		type: actionTypes.SET_DRAW_REF_ID,
		id,
	};
};

export const setDrawToolType = (tool) => {
	return {
		type: actionTypes.SET_DRAW_TOOL_TYPE,
		tool,
	};
};
export const setDrawToolWidth = (width) => {
	return {
		type: actionTypes.SET_DRAW_TOOL_WIDTH,
		width,
	};
};
export const setDrawToolColor = (color) => {
	return {
		type: actionTypes.SET_DRAW_TOOL_COLOR,
		color,
	};
};

export const createMarkerType = (name, color, layerId) => {
	return {
		type: actionTypes.CREATE_MARKER_TYPE,
		name: name,
		color: color,
		layerId: layerId,
	};
};

export const createMarkerTypeSuccess = (markerType) => {
	return {
		type: actionTypes.CREATE_MARKER_TYPE_SUCCESS,
		markerType: markerType,
	};
};

export const deleteMarkerType = (id) => {
	return {
		type: actionTypes.DELETE_MARKER_TYPE,
		id: id,
	};
};

export const deleteMarkerTypeSuccess = (id) => {
	return {
		type: actionTypes.DELETE_MARKER_TYPE_SUCCESS,
		id: id,
	};
};

export const setMarkerTypeOnLayer = (layerId, markerTypeId, color, name) => {
	return {
		type: actionTypes.SET_MARKER_TYPE_ON_LAYER,
		layerId: layerId,
		markerTypeId: markerTypeId,
		color: color,
		name: name,
	};
};

export const setMarkerTypeOnLayerSuccess = (layerId, markerType) => {
	return {
		type: actionTypes.SET_MARKER_TYPE_ON_LAYER_SUCCESS,
		layerId: layerId,
		markerType: markerType,
	};
};

export const setMarkerTypeOnLayerFail = (error) => {
	return {
		type: actionTypes.SET_MARKER_TYPE_ON_LAYER_FAIL,
		error: error,
	};
};

export const setImageOnLayer = (layerId, image) => {
	return {
		type: actionTypes.SET_IMAGE_ON_LAYER,
		layerId: layerId,
		image: image,
	};
};

export const setImageOnLayerSuccess = (layerId, image) => {
	return {
		type: actionTypes.SET_IMAGE_ON_LAYER_SUCCESS,
		layerId: layerId,
		image: image,
	};
};

export const setImageOnLayerFail = (error) => {
	return {
		type: actionTypes.SET_IMAGE_ON_LAYER_FAIL,
		error: error,
	};
};

export const setVisibleLayer = (indexLayer, visible) => {
	return {
		type: actionTypes.SET_VISIBLE_LAYER,
		indexLayer: indexLayer,
		visible: visible,
	};
};

export const playOnlyOneVideoLayer = (indexLayer) => {
	return {
		type: actionTypes.PLAY_ONLY_ONE_VIDEO_LAYER,
		indexLayer: indexLayer,
	};
};

export const getVideoAnalysis = (id) => {
	return {
		type: actionTypes.GET_VIDEO_ANALYSIS,
		id: id,
	};
};

export const getVideoAnalysisSuccess = (videoAnalysis) => {
	return {
		type: actionTypes.GET_VIDEO_ANALYSIS_SUCCESS,
		videoAnalysis: videoAnalysis,
	};
};

export const getVideoAnalysisFail = (error) => {
	return {
		type: actionTypes.GET_VIDEO_ANALYSIS_FAIL,
		error: error,
	};
};

export const createVideoAnalysis = (videoId, summary, recommendation, coachId, from_order_id) => {
	return {
		type: actionTypes.CREATE_VIDEO_ANALYSIS,
		videoId: videoId,
		summary: summary,
		recommendation: recommendation,
		coachId: coachId,
		from_order_id,
	};
};

export const createVideoAnalysisSuccess = (videoAnalysis) => {
	return {
		type: actionTypes.CREATE_VIDEO_ANALYSIS_SUCCESS,
		videoAnalysis: videoAnalysis,
	};
};

export const createVideoAnalysisFail = (error) => {
	return {
		type: actionTypes.CREATE_VIDEO_ANALYSIS_FAIL,
		error: error,
	};
};

export const updateVideoAnalysis = (id, summary, finished, orderId) => {
	return {
		type: actionTypes.UPDATE_VIDEO_ANALYSIS,
		id,
		summary,
		finished,
		orderId,
	};
};

export const updateVideoAnalysisSuccess = (summary, recommendation) => {
	return {
		type: actionTypes.UPDATE_VIDEO_ANALYSIS_SUCCESS,
		summary: summary,
		recommendation: recommendation,
	};
};

export const updateVideoAnalysisFail = (error) => {
	return {
		type: actionTypes.UPDATE_VIDEO_ANALYSIS_FAIL,
		error: error,
	};
};

export const getVideoAnalysisByCoachId = (coachId) => {
	return {
		type: actionTypes.GET_VIDEO_ANALYSIS_BY_COACH_ID,
		coachId: coachId,
	};
};

export const getVideoAnalysisByCoachIdSuccess = (videoAnalysis) => {
	return {
		type: actionTypes.GET_VIDEO_ANALYSIS_BY_COACH_ID_SUCCESS,
		videoAnalysis: videoAnalysis,
	};
};

export const getVideoAnalysisByCoachIdFail = (error) => {
	return {
		type: actionTypes.GET_VIDEO_ANALYSIS_BY_COACH_ID_FAIL,
		error: error,
	};
};
export const updateZoomValue = (value) => {
	return {
		type: actionTypes.UPDATE_ZOOM_VALUE,
		zoomValue: value,
	};
};

export const resetVideoAnalysis = () => {
	return {
		type: actionTypes.RESET_VIDEO_ANALYSIS,
	};
};
export const setSecondsToTimelineWrapperWidthRatio = (ratio) => {
	return {
		type: actionTypes.SET_SECONDS_TO_TIMELINE_WRAPPER_WIDTH_RATIO,
		ratio,
	};
};
export const setSliderValue = (sliderValue) => {
	return {
		type: actionTypes.SET_SLIDER_VALUE,
		sliderValue,
	};
};

export const deleteVideoLabel = (labelId, videoAnalysisId) => {
	return {
		type: actionTypes.DELETE_VIDEO_LABEL,
		labelId,
		videoAnalysisId,
	};
};
export const setVideoAnalysisError = (error) => {
	return {
		type: actionTypes.SET_VIDEO_ANALYSIS_ERROR,
		error,
	};
};
export const setVideoLayerMarkerType = (layerId, color, name, videoAnalysisId) => {
	return {
		type: actionTypes.SET_VIDEO_LAYER_MARKER_TYPE,
		layerId,
		color,
		name,
		videoAnalysisId,
	};
};
export const addCommentToLayer = (layerId, frame, content, videoAnalysisId) => {
	return {
		type: actionTypes.ADD_COMMENT_TO_LAYER,
		layerId,
		frame,
		content,
		videoAnalysisId,
	};
};
export const addCommentToLayerStart = () => {
	return {
		type: actionTypes.ADD_COMMENT_TO_LAYER_START,
	};
};
export const deleteComment = (commentId, videoAnalysisId) => {
	return {
		type: actionTypes.DELETE_COMMENT,
		commentId,
		videoAnalysisId,
	};
};
export const updateComment = (commentId, layerId, userId, content, frame, videoAnalysisId) => {
	return {
		type: actionTypes.UPDATE_COMMENT,
		commentId,
		layerId,
		userId,
		content,
		frame,
		videoAnalysisId,
	};
};
export const setVideoAnalysisContainerWidth = (width) => {
	return {
		type: actionTypes.SET_VIDEO_ANALYSIS_CONTAINER_WIDTH,
		width,
	};
};
export const setIsPaintMenuOpen = (boolean) => {
	return {
		type: actionTypes.SET_IS_PAINT_MENU_OPEN,
		boolean,
	};
};
export const setVideoPlayerRef = (videoPlayerRef) => {
	return {
		type: actionTypes.SET_VIDEO_PLAYER_REF,
		videoPlayerRef,
	};
};
export const setMaxValueSlider = (maxValueSlider) => {
	return {
		type: actionTypes.SET_MAX_VALUE_SLIDER,
		maxValueSlider,
	};
};
export const videoAnalysisStartLoading = () => {
	return {
		type: actionTypes.VIDEO_ANALYSIS_START_LOADING,
	};
};
export const addDrawToLayer = (drawingId, layerId, videoAnalysisId, data) => {
	return {
		type: actionTypes.ADD_DRAW_TO_LAYER,
		drawingId,
		layerId,
		videoAnalysisId,
		data,
	};
};
export const setActiveLayer = (layerId, videoAnalysisId) => {
	return {
		type: actionTypes.SET_ACTIVE_LAYER,
		layerId,
		videoAnalysisId,
	};
};
export const setActiveLayerSuccess = (id) => {
	return {
		type: actionTypes.SET_ACTIVE_LAYER_SUCCESS,
		id,
	};
};
export const setMiniPlayerPropsForEditing = (propsObject) => {
	return {
		type: actionTypes.SET_MINI_PLAYER_PROPS_FOR_EDITING,
		propsObject,
	};
};
export const deleteDrawingFromLayer = (drawingId, videoAnalysisId) => {
	return {
		type: actionTypes.DELETE_DRAWING_FROM_LAYER,
		drawingId,
		videoAnalysisId,
	};
};
export const setSketchCanvasDimensions = (dimensions) => {
	return {
		type: actionTypes.SET_SKETCH_CANVAS_DIMENSIONS,
		dimensions,
	};
};
export const setChangeTutorialState = (videoAnalysisId, data) => {
	return {
		type: actionTypes.SET_CHANGE_TUTORIAL_STATE,
		videoAnalysisId,
		data,
	};
};
export const deleteVideoAnalysis = (videoAnalysisId, userId) => {
	return {
		type: actionTypes.DELETE_VIDEO_ANALYSIS,
		videoAnalysisId,
		userId,
	};
};
export const setDeleteVideoAnalysisLoading = (boolean) => {
	return {
		type: actionTypes.SET_DELETE_VIDEO_ANALYSIS_LOADING,
		boolean,
	};
};
export const deleteAllLayers = (videoAnalysisId) => {
	return {
		type: actionTypes.DELETE_ALL_LAYERS,
		videoAnalysisId,
	};
};
export const setDeleteAllLayersError = (payload) => {
	return {
		type: actionTypes.SET_DELETE_ALL_LAYERS_ERROR,
		payload,
	};
};
export const setDeleteAllLayersLoading = (payload) => {
	return {
		type: actionTypes.SET_DELETE_ALL_LAYERS_LOADING,
		payload,
	};
};
export const setDeleteAllLayersSuccess = (payload) => {
	return {
		type: actionTypes.SET_DELETE_ALL_LAYERS_SUCCESS,
		payload,
	};
};
