import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	isOpen: false,
	error: null,
	loading: false,
};

const openRegisterModal = (state, action) => {
	return updateObject(state, { isOpen: true });
};

const setRegisterErrorNull = (state) => {
	return updateObject(state, { error: null });
};

const closeRegisterModal = (state, action) => {
	return updateObject(state, { isOpen: false });
};

const registerStart = (state, action) => {
	return updateObject(state, { error: null, loading: true });
};

const registerSuccess = (state, action) => {
	return updateObject(state, { error: null, loading: false });
};

const registerFail = (state, action) => {
	return updateObject(state, { error: action.error, loading: false });
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.OPEN_REGISTER_MODAL:
			return openRegisterModal(state, action);
		case actionTypes.SET_REGISTER_ERROR_NULL:
			return setRegisterErrorNull(state);
		case actionTypes.CLOSE_REGISTER_MODAL:
			return closeRegisterModal(state, action);
		case actionTypes.REGISTER_START:
			return registerStart(state, action);
		case actionTypes.REGISTER_SUCCESS:
			return registerSuccess(state, action);
		case actionTypes.REGISTER_FAIL:
			return registerFail(state, action);
		default:
			return state;
	}
};

export default reducer;
