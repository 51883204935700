import React, { useEffect } from 'react';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const Logout = (props) => {
	const { onLogout } = props;
	const data = {
		client_id: `${
			process.env.NODE_ENV === 'development'
				? process.env.REACT_APP_CLIENT_ID
				: '19lRQduVv3Q7rL3jZnNEDU97_adWUBJImP6kve34i8U'
		}`,
		// client_secret: `${
		// 	process.env.NODE_ENV === 'development'
		// 		? process.env.REACT_APP_CLIENT_SECRET
		// 		: 'vj5_a7n6EvoZPJgvAGAHikS7c3l9ryJxvO3E3m3aK_E'
		// }`,
	};

	useEffect(() => {
		onLogout(data);
	}, [onLogout, data]);

	return <Redirect to="/" />;
};

Logout.propTypes = {
	onLogout: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return {
		onLogout: (data) => dispatch(actions.logout(data)),
	};
};

export default connect(null, mapDispatchToProps)(Logout);
