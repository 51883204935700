import * as actionTypes from './actionTypes';

export const setCreateSubscriptionError = (value) => {
	return {
		type: actionTypes.SET_CREATE_SUBSCRIPTION_ERROR,
		value,
	};
};
export const setCreateSubscriptionLoading = (value) => {
	return {
		type: actionTypes.SET_CREATE_SUBSCRIPTION_LOADING,
		value,
	};
};
export const setCreateSubscriptionSuccess = (value) => {
	return {
		type: actionTypes.SET_CREATE_SUBSCRIPTION_SUCCESS,
		value,
	};
};
export const createSubscription = (value) => {
	const { data, subscriptionType } = value;

	return {
		type: actionTypes.CREATE_SUBSCRIPTION,
		subscriptionType,
		data,
	};
};
