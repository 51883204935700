import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	courses: null,
	loading: false,
	error: null,
	postingCourseLoading: false,
	postingCourseError: null,
	postedCourse: null,
	specificCourse: null,
	specificCourseError: null,
	boughtCourseSuccess: false,
	boughtCourse: null,
	boughtCourseError: null,
	myCoursesLoading: false,
	myCourses: null,
	myCoursesError: null,
};

const setStartCoursesLoading = (state) => {
	return updateObject(state, { loading: true });
};
const setCoursesError = (state, action) => {
	return updateObject(state, { error: action.error, loading: false });
};
const setCoursesSuccess = (state, action) => {
	return updateObject(state, { courses: action.courses, loading: false, error: null });
};
const setPostingCourseLoading = (state, action) => {
	return updateObject(state, { postingCourseLoading: action.value });
};
const setPostingCourseSuccess = (state, action) => {
	return updateObject(state, { postingCourseLoading: false, postedCourse: action.data });
};
const setPostingCourseError = (state, action) => {
	return updateObject(state, { postingCourseError: action.error });
};

const setSpecificCourseSuccess = (state, action) => {
	return updateObject(state, {
		specificCourse: action.course,
		loading: false,
		error: null,
		boughtCourseError: null,
		boughtCourseSuccess: false,
	});
};

const setSpecificCourseError = (state, action) => {
	return updateObject(state, { loading: false, specificCourseError: action.error });
};

const buyCourseSuccess = (state, action) => {
	return updateObject(state, {
		boughtCourseSuccess: action.boughtCourseSuccess,
		loading: false,
		error: null,
	});
};

const buyCourseError = (state, action) => {
	return updateObject(state, { loading: false, boughtCourseError: action.error });
};
const setMyCoursesLoading = (state, action) => {
	return updateObject(state, { myCoursesLoading: action.boolean });
};
const setMyCoursesError = (state, action) => {
	return updateObject(state, { myCoursesError: action.error, myCoursesLoading: false });
};
const setMyCoursesSuccess = (state, action) => {
	return updateObject(state, {
		myCourses: action.myCourses,
		myCoursesLoading: false,
		myCoursesError: null,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_START_COURSES_LOADING:
			return setStartCoursesLoading(state);
		case actionTypes.SET_COURSES_ERROR:
			return setCoursesError(state, action);
		case actionTypes.SET_COURSES_SUCCESS:
			return setCoursesSuccess(state, action);
		case actionTypes.SET_POSTING_COURSE_LOADING:
			return setPostingCourseLoading(state, action);
		case actionTypes.SET_POSTING_COURSE_SUCCESS:
			return setPostingCourseSuccess(state, action);
		case actionTypes.SET_POSTING_COURSE_ERROR:
			return setPostingCourseError(state, action);
		case actionTypes.SET_SPECIFIC_COURSE_SUCCESS:
			return setSpecificCourseSuccess(state, action);
		case actionTypes.SET_SPECIFIC_COURSE_ERROR:
			return setSpecificCourseError(state, action);
		case actionTypes.BUY_COURSE_SUCCESS:
			return buyCourseSuccess(state, action);
		case actionTypes.BUY_COURSE_ERROR:
			return buyCourseError(state, action);
		case actionTypes.SET_MY_COURSES_LOADING:
			return setMyCoursesLoading(state, action);
		case actionTypes.SET_MY_COURSES_ERROR:
			return setMyCoursesError(state, action);
		case actionTypes.SET_MY_COURSES_SUCCESS:
			return setMyCoursesSuccess(state, action);
		default:
			return state;
	}
};

export default reducer;
