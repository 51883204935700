import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function useBidsNotifications(order, userRole) {
	const [amount, setAmount] = useState(null);
	const myPublishedOrders = useSelector((state) => state?.orders?.myPublishedOrders);
	const myPublishedBids = useSelector((state) => state?.orders?.myBids);

	useEffect(() => {
		if (myPublishedOrders) {
			const ordersWithBids = myPublishedOrders.filter((item) => item.bids);

			const unviewedAmount = ordersWithBids
				.map((item) => item.bids)
				.flat()
				.map((item) => item.player_viewed)
				.filter((item) => !item).length;
			setAmount(unviewedAmount);
		}

		if (myPublishedBids) {
			const bids_amount = myPublishedBids
				.filter((bid) => bid.order.status !== 'open')
				.filter((bid) => bid.coach_viewed === false);

			setAmount(bids_amount.length);
		}
	}, [myPublishedOrders, myPublishedBids]);

	useEffect(() => {
		if ((order, userRole)) {
			if (order.bids) {
				if (userRole === 'player') {
					setAmount(
						order.bids.map((item) => item.player_viewed).filter((item) => !item).length
					);
				}
			} else {
				setAmount(null);
			}
		}
	}, [order, userRole]);

	return {
		amount,
	};
}
