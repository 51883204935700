import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { theme } from '../../theme/theme';
import ProfileHeader from '../../components/Header/ProfileHeader/ProfileHeader';
import { StylesProvider } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const Layout = (props) => {
	const { pathname } = useLocation();
	const view = useSelector((state) => state.view.view);

	const shouldRenderProileHeader = () => {
		switch (true) {
			case view === 'mobile': {
				return false;
			}
			case pathname.includes('profile') ||
				pathname.includes('marketplace') ||
				pathname.includes('video-upload') ||
				pathname.includes('video-preview') ||
				pathname.includes('video-analysis') ||
				pathname.includes('wallet') ||
				pathname.includes('bug-report') ||
				pathname.includes('send-demo') ||
				pathname.includes('orders') ||
				pathname.includes('order'): {
				return true;
			}
			default:
				return false;
		}
	};

	const result = shouldRenderProileHeader();
	return (
		<React.Fragment>
			<StylesProvider injectFirst>
				<ThemeProvider theme={theme}>
					{result ? (
						<ProfileHeader>
							{props.children}
							<Footer />
						</ProfileHeader>
					) : (
						<>
							<Header />
							<main style={{ paddingTop: '64px' }}>{props.children}</main>
							<Footer />{' '}
						</>
					)}
				</ThemeProvider>
			</StylesProvider>
		</React.Fragment>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
