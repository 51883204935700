import { IconButton, Snackbar } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Wrapper } from './ErrorAlert.Styled';
import CloseIcon from '@material-ui/icons/Close';
import * as actions from '../../../store/actions';
import { useDispatch } from 'react-redux';

const ErrorAlert = ({ message, success }) => {
	const [open, setIsOpen] = useState(false);
	const dispatch = useDispatch();
	useEffect(() => {
		if (message) {
			setIsOpen(true);
		}
	}, [message]);

	const handleClose = () => {
		setIsOpen(false);
	};

	const handleReset = () => {
		dispatch(actions.setAuthErrorNull());
		dispatch(actions.setDeleteAllLayersError(null));
		dispatch(actions.setErrorUserData(null));
		dispatch(actions.setOrdersError(null));
		dispatch(actions.setRegisterErrorNull(null));
		dispatch(actions.setVideoAnalysisError(null));
		dispatch(actions.setVideosError(null));
		dispatch(actions.createVideoAnalysisFail(null));
		dispatch(actions.setPostingCourseError(null));
		dispatch(actions.setCreateSubscriptionError(null));
		dispatch(actions.setCreateSubscriptionSuccess(false));
	};
	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			open={open}
			autoHideDuration={5000}
			onClose={handleClose}
			message="Note archived"
			onExited={handleReset}
		>
			<Wrapper success={success}>
				{message}

				<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
					<CloseIcon fontSize="small" />
				</IconButton>
			</Wrapper>
		</Snackbar>
	);
};

export default ErrorAlert;
