import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	response: null,
	error: null,
	loading: false,
};

const sendFeedbackLoading = (state, action) => {
	return updateObject(state, { loading: true });
};

const sendFeedbackSuccess = (state, action) => {
	return updateObject(state, { response: action.response, error: null, loading: false });
};

const sendFeedbackError = (state, action) => {
	return updateObject(state, { error: action.error, loading: false });
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SEND_FEEDBACK_LOADING:
			return sendFeedbackLoading(state, action);
		case actionTypes.SEND_FEEDBACK_SUCCESS:
			return sendFeedbackSuccess(state, action);
		case actionTypes.SEND_FEEDBACK_ERROR:
			return sendFeedbackError(state, action);
		default:
			return state;
	}
};

export default reducer;
