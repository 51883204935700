import styled from 'styled-components';

export const Wrapper = styled.div`
	padding: 10px 20px;
	background-color: ${({ theme, success }) =>
		success ? theme.colors.successGreen : theme.colors.deleteRed};
	color: white;
	border-radius: 5px;
	margin: 5px 0;
`;
