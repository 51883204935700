import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';
import appDebug from '../../helpers/debugger.js';

const initialState = {
  generalBandAnalysisId: undefined,
  generalBandAnalysisError: undefined,
  museBandAnalysisList: [],
  museBandAnalysisId: undefined,
  museBandAnalysisError: undefined,
  museBandAnalysisRecordedData: [],
  debugMode: false
}

const setGeneralBandAnalysisInternalId = (state, action) => {
  appDebug('success',`Function setGeneralBandAnalysisInternalId ${action.payload}`, state.debugMode);
  return updateObject(state, {generalBandAnalysisId: action.payload});
}

const createGeneralBandAnalysisFail = (state, action) => {
  appDebug('info',`Function createGeneralMuseBandAnalysisFail ${action.payload}`, state.debugMode);
  return updateObject(state, {generalBandAnalysisError: action.payload});
}
const getGeneralBandAnalysisListFail = (state, action) => {
  appDebug('info',`Function getGeneralBandAnalysisFail ${action.payload}`, state.debugMode);
  return updateObject(state, {generalBandAnalysisError: action.payload});
}

const createMuseBandAnalysisSuccess = (state, action) => {
  appDebug('success',`Function createMuseBandAnalysisSuccess`, state.debugMode);
  return updateObject(state, {museBandAnalysisId: action.payload});
}

const createMuseBandAnalysisFail = (state, action) => {
  appDebug('info',`Function createMuseBandAnalysisFail`, state.debugMode);
  return updateObject(state, {museBandAnalysisError: action.payload});
}

const clearStoreMuseBandAnalysisList = (state, action) => {
  appDebug('success',`Function clearStoreMuseBandAnalysisList`, state.debugMode);
  return updateObject(state, {museBandAnalysisList: []});
}

const getMuseBandAnalysisListSuccess = (state, action) => {
  appDebug('success',`Function getMuseBandAnalysisListSuccess`, state.debugMode);
  return updateObject(state, {museBandAnalysisList: state.museBandAnalysisList.concat(action.payload)});
}

const getMuseBandAnalysisListFail = (state, action) => {
  appDebug('info',`Function getMuseBandAnalysisListFail`, state.debugMode);
  return updateObject(state, {museBandAnalysisError: action.payload, museBandAnalysisList: []});
}

const getMuseBandAnalysisPackagesSuccess = (state, action) => {
  appDebug('success',`Function getMuseBandAnalysisPackagesSuccess`, state.debugMode);
  return updateObject(state, {museBandAnalysisRecordedData: state.museBandAnalysisRecordedData.concat(action.payload)});
}

const getMuseBandAnalysisPackagesFail = (state, action) => {
  appDebug('info',`Function getMuseBandAnalysisPackagesFail`, state.debugMode);
  return updateObject(state, {museBandAnalysisError: action.payload, museBandAnalysisRecordedData: []});
}

const clearRecordedDataForChart = (state) => {
  appDebug('info',`Function clearRecordedDataForChart`, state.debugMode);
  return updateObject(state, {museBandAnalysisRecordedData: []});
}

const turnOnMuseDebugg = (state) => {
  appDebug('info',`Function turnOnMuseDebugg`, state.debugMode);
  return updateObject(state, {debugMode: true});
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_GENERAL_BAND_ANALYSIS_STORE_ID: return setGeneralBandAnalysisInternalId(state, action);
		case actionTypes.CREATE_GENERAL_BAND_ANALYSIS_SUCCESS: return setGeneralBandAnalysisInternalId(state, action);
		case actionTypes.CREATE_GENERAL_BAND_ANALYSIS_FAIL: return createGeneralBandAnalysisFail(state, action);
		case actionTypes.GET_GENERAL_BAND_ANALYSIS_LIST_FAIL: return getGeneralBandAnalysisListFail(state, action);
		case actionTypes.CREATE_MUSE_BAND_ANALYSIS_SUCCESS: return createMuseBandAnalysisSuccess(state, action);
		case actionTypes.CREATE_MUSE_BAND_ANALYSIS_FAIL: return createMuseBandAnalysisFail(state, action);
		case actionTypes.GET_MUSE_BAND_ANALYSIS_LIST_SUCCESS: return getMuseBandAnalysisListSuccess(state, action);
		case actionTypes.GET_MUSE_BAND_ANALYSIS_LIST_FAIL: return getMuseBandAnalysisListFail(state, action);
		case actionTypes.GET_MUSE_BAND_ANALYSIS_PACKAGES_SUCCESS: return getMuseBandAnalysisPackagesSuccess(state, action);
		case actionTypes.GET_MUSE_BAND_ANALYSIS_PACKAGES_FAIL: return getMuseBandAnalysisPackagesFail(state, action);
		case actionTypes.CLEAR_RECORDED_DATA_FOR_CHART: return clearRecordedDataForChart(state);
		case actionTypes.CLEAR_STORE_MUSE_BAND_ANALYSIS_LIST: return clearStoreMuseBandAnalysisList(state);
		case actionTypes.TURN_ON_MUSE_DEBUGG: return turnOnMuseDebugg(state);
		default: return state;
	}
}

export default reducer;