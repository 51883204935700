import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	stats: null,
	error: null,
	loading: false,
};

const getStatsSuccess = (state, action) => {
	return updateObject(state, { stats: action.stats, error: null, loading: false });
};
const getStatsStart = (state, action) => {
	return updateObject(state, { loading: true });
};

const getStatsFail = (state, action) => {
	return updateObject(state, { error: action.error, loading: false });
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_STATS_SUCCESS:
			return getStatsSuccess(state, action);
		case actionTypes.GET_STATS_START:
			return getStatsStart(state, action);
		case actionTypes.GET_STATS_FAIL:
			return getStatsFail(state, action);
		default:
			return state;
	}
};

export default reducer;
