import * as actionTypes from './actionTypes';

export const createRating = (payload) => {
	return {
		type: actionTypes.CREATE_RATING,
        payload
	};
};

export const createRatingSuccess = (payload) => {
	return {
		type: actionTypes.CREATE_RATING_SUCCESS,
        payload
	};
};

export const createRatingFail = (payload) => {
	return {
		type: actionTypes.CREATE_RATING_FAIL,
        payload
	};
};

export const getRatingInfo = (payload) => {
	return {
		type: actionTypes.GET_RATING_INFO,
        payload
	};
};

export const getRatingInfoSuccess = (payload) => {
	return {
		type: actionTypes.GET_RATING_INFO_SUCCESS,
        payload
	};
};

export const getRatingInfoFail = (payload) => {
	return {
		type: actionTypes.GET_RATING_INFO_FAIL,
        payload
	};
};