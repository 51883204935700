import * as actionTypes from './actionTypes';

export const getGamesStart = () => {
  return {
    type: actionTypes.GET_GAMES_START
  }
}

export const getGamesSuccess = (games) => {
  return {
    type: actionTypes.GET_GAMES_SUCCESS,
    games: games
  }
}

export const getGamesFail = (error) => {
  return {
    type: actionTypes.GET_GAMES_FAIL,
    error: error
  }
}

export const getGames = () => {
  return {
    type: actionTypes.GET_GAMES
  }
}