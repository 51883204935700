import * as actionTypes from './actionTypes';

export const setStartCoursesLoading = () => {
	return {
		type: actionTypes.SET_START_COURSES_LOADING,
	};
};

export const setCoursesSuccess = (courses) => {
	return {
		type: actionTypes.SET_COURSES_SUCCESS,
		courses,
	};
};

export const setCoursesError = (error) => {
	return {
		type: actionTypes.SET_COURSES_ERROR,
		error,
	};
};

export const getOpenedCourses = (filters) => {
	return {
		type: actionTypes.GET_OPENED_COURSES,
		filters,
	};
};
export const setPostingCourseLoading = (value) => {
	return {
		type: actionTypes.SET_POSTING_COURSE_LOADING,
		value,
	};
};
export const setPostingCourseError = (error) => {
	return {
		type: actionTypes.SET_POSTING_COURSE_ERROR,
		error,
	};
};
export const setPostingCourseSuccess = (data) => {
	return {
		type: actionTypes.SET_POSTING_COURSE_SUCCESS,
		data,
	};
};
export const postCourse = (data) => {
	return {
		type: actionTypes.POST_COURSE,
		data,
	};
};

export const getSpecificCourse = (id) => {
	return {
		type: actionTypes.GET_SPECIFIC_COURSE,
		id,
	};
};

export const setSpecificCourseSuccess = (course) => {
	return {
		type: actionTypes.SET_SPECIFIC_COURSE_SUCCESS,
		course,
	};
};

export const setSpecificCourseError = (error) => {
	return {
		type: actionTypes.SET_SPECIFIC_COURSE_ERROR,
		error,
	};
};

export const buyCourse = (id) => {
	return {
		type: actionTypes.BUY_COURSE,
		id,
	};
};

export const buyCourseSuccess = (boughtCourseSuccess) => {
	return {
		type: actionTypes.BUY_COURSE_SUCCESS,
		boughtCourseSuccess,
	};
};

export const buyCourseError = (error) => {
	return {
		type: actionTypes.BUY_COURSE_ERROR,
		error,
	};
};
export const setMyCoursesLoading = (boolean) => {
	return {
		type: actionTypes.SET_MY_COURSES_LOADING,
		boolean,
	};
};
export const setMyCoursesError = (error) => {
	return {
		type: actionTypes.SET_MY_COURSES_ERROR,
		error,
	};
};
export const setMyCoursesSuccess = (myCourses) => {
	return {
		type: actionTypes.SET_MY_COURSES_SUCCESS,
		myCourses,
	};
};
export const getMyCourses = (data) => {
	return {
		type: actionTypes.GET_MY_COURSES,
		data,
	};
};
