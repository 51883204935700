import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	createSubscriptionSuccess: false,
	createSubscriptionLoading: false,
	createSubscriptionError: null,
};

const setCreateSubscriptionSuccess = (state, action) => {
	return updateObject(state, { createSubscriptionSuccess: action.value });
};
const setCreateSubscriptionLoading = (state, action) => {
	return updateObject(state, { createSubscriptionLoading: action.value });
};
const setCreateSubscriptionError = (state, action) => {
	return updateObject(state, { createSubscriptionError: action.value });
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_CREATE_SUBSCRIPTION_SUCCESS:
			return setCreateSubscriptionSuccess(state, action);
		case actionTypes.SET_CREATE_SUBSCRIPTION_LOADING:
			return setCreateSubscriptionLoading(state, action);
		case actionTypes.SET_CREATE_SUBSCRIPTION_ERROR:
			return setCreateSubscriptionError(state, action);
		default:
			return state;
	}
};

export default reducer;
