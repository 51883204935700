import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from '../../axios-instance';
import appDebug from '../../helpers/debugger.js';
import { select } from 'redux-saga/effects';

export const getDebugMode = (state) => state.general.debugMode;

export function* getAllProducts(action) {
	let debugMode = yield select(getDebugMode);
	appDebug('info',`Function getAllProductsSaga`, debugMode);
	yield put(actions.getProductsLoading(true));

	try {
		const response = yield axios.get('products');
		appDebug('success',`Action getAllProductsSaga with data ${JSON.stringify(response.data.data)}`, debugMode);
		yield put(actions.setAllProducts(response.data.data));
		yield put(actions.getProductsLoading(false));
	} catch (e) {
		console.log(e.response);
		yield put(actions.getProductsError(e.response.data));
		yield put(actions.getProductsLoading(false));
	}
}
