import React, { useState, useEffect } from 'react';

const FontResizer = () => {

  const [visible, setVisibility] = useState(true);

  useEffect(() => {
    const fontSize = localStorage.getItem("fontSize");
    if (fontSize) {
        setBodyClass(fontSize);
    }
  },[])

  function setBodyClass(bodyClass) {
    document.body.classList.remove("fontSizeSmall");
    document.body.classList.remove("fontSizeNormal");
    document.body.classList.remove("fontSizeBig");
    if (bodyClass !== "fontSizeClear") {
      document.body.classList.add(bodyClass);
      localStorage.setItem("fontSize", bodyClass);
    } else {
      localStorage.setItem("fontSize", "");
    }
  }

  return <div id="fontsSizerComponent" className={visible ? "show" : "hide"}>
    <div id="fontSizeSmall" className="fontSizer__Switcher" onClick={()=>{setBodyClass('fontSizeSmall')}}>A</div> 
    <div id="fontSizeNormal" className="fontSizer__Switcher" onClick={()=>{setBodyClass('fontSizeNormal')}}>A</div> 
    <div id="fontSizeBig" className="fontSizer__Switcher" onClick={()=>{setBodyClass('fontSizeBig')}}>A</div> 
    <div id="fontSizeClear" className="fontSizer__Switcher fontSizer__Switcher--Clear" onClick={()=>{setBodyClass('fontSizeClear')}}>A</div> 
    <div id="fontSizeArrowUp" className="fontSizer__Switcher fontSizer__Switcher--Clear" onClick={()=>{setVisibility(!visible)}}><i className="arrow down"></i></div>
    <div id="fontSizeArrowDown" className="fontSizer__Switcher fontSizer__Switcher--Clear" onClick={()=>{setVisibility(!visible)}}><i className="arrow up"></i></div>
  </div>;
};

export default FontResizer;
