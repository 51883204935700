import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from '../../axios-instance';
import appDebug from '../../helpers/debugger.js';
import { select } from 'redux-saga/effects';

export const getDebugMode = (state) => state.general.debugMode;

export function* createRatingSaga(action) {
	let debugMode = yield select(getDebugMode);
	appDebug('info', `createRatingSaga  ${JSON.stringify(action.payload)}`, debugMode);
	try {
		const objectToSendInBody = {
			rating: {
				vote: action.payload.vote,
				comment: action.payload.comment,
			},
		};
		appDebug(
			'info',
			`createRatingSaga  objectToSendInBody ${JSON.stringify(objectToSendInBody)}`,
			debugMode
		);
		appDebug('info', `send data to ratings/by_order/${action.payload.orderId}`, debugMode);
		const response = yield axios.post(
			`ratings/by_order/${action.payload.orderId}`,
			objectToSendInBody
		);
		appDebug('success', `Action createRatingSuccess`, debugMode);
		yield put(actions.createRatingSuccess(response.data.id));
	} catch (error) {
		const message = `${error.response.status} ${error.response.statusText}`;
		appDebug('error', `Action createRatingFail ${message}`, true);
		yield put(actions.createRatingFail(message));
	}
}

export function* getRatingInfoSaga(action) {
	let debugMode = yield select(getDebugMode);
	const orderId = action.payload;
	appDebug('info', `getRatingInfoSaga  ${JSON.stringify(orderId)}`, debugMode);
	try {
		const response = yield axios.get(`ratings/by_order/${orderId}`);
		appDebug('success', `Action getRatingInfoSuccess`, debugMode);
		yield put(actions.getRatingInfoSuccess(response.data));
	} catch (error) {
		if (error.response.status === 422 && error.response.data.rating === 'not present') {
			const emptyRating = {
				vote: -1,
				order: {
					id: orderId,
				},
			};
			appDebug('success', `EMPTY RATING for order ${orderId}`, debugMode);
			appDebug('success', `Action getRatingInfoSuccess`, debugMode);
			yield put(actions.getRatingInfoSuccess(emptyRating));
		} else {
			const message = `${error.response.status} ${error.response.statusText}`;
			appDebug('error', `Action getRatingInfoSagaFail ${message}`, true);
			yield put(actions.getRatingInfoFail(message));
		}
	}
}
