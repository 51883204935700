import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from '../../axios-instance';
import appDebug from '../../helpers/debugger.js';
import { select } from 'redux-saga/effects';
export const getDebugMode = (state) => state.general.debugMode;

export function* getOpenedCoursesSaga(action) {
	let page = action.filters.page;
	let debugMode = yield select(getDebugMode);
	appDebug('info', `Function getOpenedCoursesSaga page ${page}`, debugMode);
	yield put(actions.setStartCoursesLoading());
	try {
		const response = yield axios.post('courses/opened', {
			limit: 12,
			page: page,
			by_game_ids: [],
			by_player_ids: [],
			order: action.filters.order,
			search_by_title: action.filters.search,
			search_by_title_and_description: '',
		});
		appDebug(
			'success',
			`Function getOpenedCoursesSaga ${JSON.stringify(response.data)}`,
			debugMode
		);
		yield put(actions.setCoursesSuccess(response.data));
	} catch (e) {
		appDebug(
			'error',
			`Function getOpenedCoursesSaga ${JSON.stringify(e.response.data)}`,
			debugMode
		);
		yield put(actions.setCoursesError(e.response.data));
	}
}

export function* getSpecificCourseSaga(data) {
	const { id } = data;
	let debugMode = yield select(getDebugMode);
	appDebug('info', `Function getSpecificCourse id ${id}`, debugMode);
	yield put(actions.setStartCoursesLoading());
	try {
		appDebug('info', `Get data from endpoint courses/${id}`, debugMode);
		const response = yield axios.get(`courses/${id}`);
		appDebug(
			'success',
			`Function getSpecificCourseSaga ${JSON.stringify(response.data)}`,
			debugMode
		);
		yield put(actions.setSpecificCourseSuccess(response.data));
	} catch (e) {
		appDebug(
			'error',
			`Function getSpecificCourse ${JSON.stringify(e.response.data)}`,
			debugMode
		);
		yield put(actions.setSpecificCourseError(e.response.data));
	}
}

export function* postCourseSaga(action) {
	let debugMode = yield select(getDebugMode);
	const { data } = action;
	yield put(actions.setPostingCourseLoading(true));
	try {
		const response = yield axios.post('courses', {
			course: {
				...data,
			},
		});
		appDebug(
			'success',
			`Function setPostingCourseSuccess ${JSON.stringify(response.data)}`,
			debugMode
		);
		yield put(actions.setPostingCourseSuccess(response));
	} catch (e) {
		appDebug(
			'error',
			`Function setPostingCourseError ${JSON.stringify(e.response.data)}`,
			debugMode
		);
		yield put(actions.setPostingCourseError(e.response.data));
	} finally {
		yield put(actions.setPostingCourseLoading(false));
	}
}

export function* buyCourseSaga(data) {
	const { id } = data;
	let debugMode = yield select(getDebugMode);
	appDebug('info', `Function buyCourseSaga id ${id}`, debugMode);
	yield put(actions.setStartCoursesLoading());
	try {
		appDebug('info', `Send data to endpoint from endpoint courses/${id}/buy`, debugMode);
		const response = yield axios.post(`courses/${id}/buy`);
		appDebug('success', `Function buyCourseSaga ${JSON.stringify(response.data)}`, debugMode);
		yield put(actions.buyCourseSuccess(true));
	} catch (e) {
		appDebug('error', `Function buyCourseSaga ${JSON.stringify(e.response.data)}`, debugMode);
		yield put(actions.buyCourseError(e.response.data));
	}
}

export function* getMyCourses() {
	yield put(actions.setMyCoursesLoading(true));
	try {
		const response = yield axios.post(`courses/bought`);
		console.log(response);
		yield put(actions.setMyCoursesSuccess(response.data.data));
	} catch (e) {
		console.log(e.response);
		yield put(actions.setMyCoursesError(e.response));
	}
}
