import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from '../../axios-instance';
import appDebug from '../../helpers/debugger.js';
import { select } from 'redux-saga/effects';
export const getDebugMode = (state) => state.general.debugMode;

export function* loginSaga(action) {
	let debugMode = yield select(getDebugMode);
	appDebug('info',`Function loginSaga`, debugMode);

	yield put(actions.loginStart());
	const loginData = action.loginData;
	try {
		appDebug('info',`Function loginSaga send login data ${JSON.stringify(loginData)}`, debugMode);
		const response = yield axios.post('oauth/token', loginData);
		yield localStorage.setItem('refresh_token', response.data.refresh_token);
		yield localStorage.setItem('token', response.data.access_token);
		yield localStorage.setItem('userId', response.data.user.id);
		yield localStorage.removeItem('user_role');
		yield localStorage.removeItem('provider');
		appDebug('success',`Function loginSaga response ${JSON.stringify(response.data)}`, debugMode);

		yield put(
			actions.loginSuccess(
				response.data.access_token,
				response.data.refresh_token,
				response.data.expires_in,
				response.data.user.id,
				response.data.user.user_role_id
			)
		);
	} catch (error) {
		if (error.response.data.error === 'no_user_found') {
			yield put(actions.loginFail(error.response));
		} else {
			yield put(actions.loginFail(error.response));
		}
	}
}

export function* logoutSaga(action) {
	yield put(actions.logoutSuccess());
	yield put(actions.getUserDataSuccess(null));
	try {
		yield axios.post('oauth/revoke', action.data);

		yield put(actions.resetVideoAnalysis());
		yield put(actions.getVideosSuccess(null, 'override'));
		yield put(actions.setMyBidsSuccess(null));
		yield put(actions.setOrdersSuccess(null));
		yield put(actions.setMyPublishedOrdersSuccess(null));
	} catch (error) {
		console.log(error);
	}
}

export function* autoLoginSaga(action) {
	yield put(actions.loginStart());
	const token = yield localStorage.getItem('token');
	const refreshToken = yield localStorage.getItem('refresh_token');
	const refreshData = {
		client_id: `${
			process.env.NODE_ENV === 'development'
				? process.env.REACT_APP_CLIENT_ID
				: 'LvFzXJLCbinjyUn3ELm1Gymct6WI0FwmPU9YknAhi4s'
		}`,
		grant_type: 'refresh_token',
		refresh_token: refreshToken,
	};
	if (!token && !refreshToken) {
		yield put(actions.logoutSuccess());
	} else {
		try {
			const response = yield axios.post('oauth/token', refreshData);
			yield localStorage.setItem('refresh_token', response.data.refresh_token);
			yield localStorage.setItem('token', response.data.access_token);
			yield put(
				actions.loginSuccess(
					response.data.access_token,
					response.data.refresh_token,
					response.data.expires_in,
					response.data.user.id,
					response.data.user.user_role_id
				)
			);
		} catch (error) {
			console.log(error);
		}
	}
}

export function* logoutSuccessSaga(action) {
	yield localStorage.removeItem('token');
	yield localStorage.removeItem('userId');
	yield localStorage.removeItem('refresh_token');
}
export function* getTokenInfoSaga() {
	try {
		const response = yield axios.get('oauth/token/info');
		yield put(
			actions.updateExpiresInAndRefreshToken(
				response.data.expires_in_seconds,
				response.data.refresh_token
			)
		);
	} catch (error) {
		if (error.response.data.error === 'invalid_token') {
			yield put(actions.logoutSuccess());
			yield put(actions.resetVideoAnalysis());
		}
	}
}

export function* refreshTokenSaga(action) {
	const refreshData = action.refreshData;

	try {
		const response = yield axios.post('oauth/token', refreshData);
		yield localStorage.setItem('refresh_token', response.data.refresh_token);
		yield localStorage.setItem('token', response.data.access_token);
		yield put(actions.refreshSuccess(response.data.access_token, response.data.refresh_token));
	} catch (error) {
		yield put(actions.logoutSuccess());
		yield put(actions.resetVideoAnalysis());
	}
}
