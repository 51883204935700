import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	createVideoAnalysisLoading: false,
	videoAnalysis: null,
	videoAnalysisByCoachId: null,
	videoLayers: [],
	videoAnalysisError: null,
	error: null,
	createAnalysisError: null,
	markerTypes: [],
	markerTypesError: null,
	drawRef: null,
	videoPlayerRef: null,
	drawRefId: null,
	drawToolType: null,
	drawToolWidth: 3,
	drawToolColor: '#fff',
	miniPlayerPropsForEditing: null,
	isPaintMenuOpen: false,
	playVideoLayer: null,
	zoomValue: 100,
	sliderValue: 0,
	maxValueSlider: null,
	secondsToTimelineWrapperWidthRatio: undefined,
	loading: false,
	deleteLoading: false,
	videoAnalysisContainerWidth: null,
	deleteAllLayersLoading: false,
	deleteAllLayersSuccess: null,
	deleteAllLayersError: null,
	sketchCanvasDimensions: {
		width: null,
		height: null,
	},
};

const createVideoLabelSuccess = (state, action) => {
	const videoLayer = updateObject(action.videoLayer, { visible: true });
	const updatedVideoLayers = state.videoLayers.concat(videoLayer);
	return updateObject(state, {
		videoLayers: updatedVideoLayers,
	});
};

const createVideoLabelFail = (state, action) => {
	return updateObject(state, { error: action.error });
};

const deleteVideoLayerSuccess = (state, action) => {
	const updatedVideoLayers = state.videoLayers.filter((layer) => layer.id !== action.id);
	return updateObject(state, {
		videoLayers: updatedVideoLayers,
		loading: false,
	});
};

const setVideoLayers = (state, action) => {
	const updatedVideoLayers = action.videoLayers.map((layer) =>
		updateObject(layer, { visible: true })
	);
	return updateObject(state, { videoLayers: updatedVideoLayers });
};

const getVideoLayersFail = (state, action) => {
	return updateObject(state, { error: action.error });
};
const getVideoLayersSuccess = (state) => {
	return updateObject(state, { error: null, loading: false });
};

const setDrawRefId = (state, action) => {
	return updateObject(state, {
		drawRefId: action.id,
	});
};
const setDrawToolType = (state, action) => {
	return updateObject(state, {
		drawToolType: action.tool,
	});
};
const setDrawToolWidth = (state, action) => {
	return updateObject(state, {
		drawToolWidth: action.width,
	});
};
const setDrawToolColor = (state, action) => {
	return updateObject(state, {
		drawToolColor: action.color,
	});
};

const createMarkerTypeSuccess = (state, action) => {
	const updatedMarkerTypes = state.markerTypes.concat(action.markerType);
	return updateObject(state, { markerTypes: updatedMarkerTypes });
};

const deleteMarkerTypeSuccess = (state, action) => {
	const updatedMarkerTypes = state.markerTypes.filter(
		(markerType) => markerType.id !== action.id
	);
	return updateObject(state, { markerTypes: updatedMarkerTypes });
};

const setMarkerTypeOnLayerSuccess = (state, action) => {
	const updatedVideoLayers = state.videoLayers.map((layer) =>
		layer.id === action.layerId
			? updateObject(layer, { marker_type: action.markerType })
			: layer
	);
	return updateObject(state, { videoLayers: updatedVideoLayers });
};

const setMarkerTypeOnLayerFail = (state, action) => {
	return updateObject(state, { error: action.error });
};

const setImageOnLayerSuccess = (state, action) => {
	const updatedVideoLayers = state.videoLayers.map((layer) =>
		layer.id === action.layerId ? updateObject(layer, { image: action.image }) : layer
	);
	return updateObject(state, { videoLayers: updatedVideoLayers });
};

const setImageOnLayerFail = (state, action) => {
	return updateObject(state, { error: action.error });
};

const setVisibleLayer = (state, action) => {
	const updatedVideoLayers = state.videoLayers.map((layer, index) =>
		index === action.indexLayer ? updateObject(layer, { visible: action.visible }) : layer
	);
	return updateObject(state, { videoLayers: updatedVideoLayers });
};

const playOnlyOneVideoLayer = (state, action) => {
	let updatedVideoLayers = state.videoLayers.map((layer) =>
		updateObject(layer, { visible: true })
	);
	let playVideoLayerObject = null;
	if (action.indexLayer !== null) {
		updatedVideoLayers = state.videoLayers.map((layer, index) =>
			index === action.indexLayer
				? updateObject(layer, { visible: true })
				: updateObject(layer, { visible: false })
		);
		const videoLayer = state.videoLayers.find((layer, index) => index === action.indexLayer);
		playVideoLayerObject = {
			index: action.indexLayer,
			frame_from: videoLayer.frame_from,
			frame_to: videoLayer.frame_to,
		};
	}
	return updateObject(state, {
		videoLayers: updatedVideoLayers,
		playVideoLayer: playVideoLayerObject,
	});
};

const getVideoAnalysisFail = (state, action) => {
	return updateObject(state, { videoAnalysisError: action.error });
};

const getVideoAnalysisSuccess = (state, action) => {
	return updateObject(state, { videoAnalysis: action.videoAnalysis, videoAnalysisError: null });
};

const createVideoAnalysisFail = (state, action) => {
	return updateObject(state, { createAnalysisError: action.error });
};

const createVideoAnalysisSuccess = (state, action) => {
	return updateObject(state, { videoAnalysis: action.videoAnalysis });
};

const updateVideoAnalysisFail = (state, action) => {
	return updateObject(state, { error: action.error });
};

const setCreateVideoAnalysisLoading = (state, action) => {
	return updateObject(state, { createVideoAnalysisLoading: action.boolean });
};

const updateVideoAnalysisSuccess = (state, action) => {
	const updateVideoAnalysis = updateObject(state.videoAnalysis, {
		summary: action.summary,
		recommendation: action.recommendation,
	});
	return updateObject(state, { videoAnalysis: updateVideoAnalysis });
};

const getVideoAnalysisByCoachIdFail = (state, action) => {
	return updateObject(state, { error: action.error });
};

const getVideoAnalysisByCoachIdSuccess = (state, action) => {
	return updateObject(state, { videoAnalysisByCoachId: action.videoAnalysis });
};

const updateZoomValue = (state, action) => {
	return updateObject(state, { zoomValue: action.zoomValue });
};

const resetVideoAnalysis = (state) => {
	return initialState;
};

const setSecondsToTimelineWrapperWidthRatio = (state, action) => {
	return updateObject(state, {
		secondsToTimelineWrapperWidthRatio: action.ratio,
	});
};
const setSliderValue = (state, action) => {
	return updateObject(state, {
		sliderValue: action.sliderValue,
	});
};
const setVideoAnalysisError = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};
const setAddCommentToLayerStart = (state) => {
	return updateObject(state, {
		loading: true,
	});
};
const setAddCommentToLayerSuccess = (state) => {
	return updateObject(state, {
		loading: false,
	});
};
const setVideoAnalysisContainerWidth = (state, action) => {
	return updateObject(state, {
		videoAnalysisContainerWidth: action.width,
	});
};
const setIsPaintMenuOpen = (state, action) => {
	return updateObject(state, {
		isPaintMenuOpen: action.boolean,
	});
};
const setDrawRef = (state, action) => {
	return updateObject(state, {
		drawRef: action.drawRef,
	});
};
const setVideoPlayerRef = (state, action) => {
	return updateObject(state, {
		videoPlayerRef: action.videoPlayerRef,
	});
};
const setMaxValueSlider = (state, action) => {
	return updateObject(state, {
		maxValueSlider: action.maxValueSlider,
	});
};

const videoAnalysisStartLoading = (state) => {
	return updateObject(state, {
		loading: true,
	});
};
const setActiveLayerSuccess = (state, action) => {
	const updateVideoAnalysis = updateObject(state.videoAnalysis, {
		active_layer_id: action.id,
	});
	return updateObject(state, { videoAnalysis: updateVideoAnalysis });
};
const setMiniPlayerPropsForEditing = (state, action) => {
	return updateObject(state, { miniPlayerPropsForEditing: action.propsObject });
};
const setSketchCanvasDimensions = (state, action) => {
	return updateObject(state, { sketchCanvasDimensions: action.dimensions });
};
const setDeleteVideoAnalysisLoading = (state, action) => {
	return updateObject(state, { deleteLoading: action.boolean });
};
const setDeleteAllLayersLoading = (state, action) => {
	return updateObject(state, { deleteAllLayersLoading: action.payload });
};
const setDeleteAllLayersSuccess = (state, action) => {
	return updateObject(state, { deleteAllLayersSuccess: action.payload });
};
const setDeleteAllLayersError = (state, action) => {
	return updateObject(state, { deleteAllLayersError: action.payload });
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CREATE_VIDEO_LABEL_SUCCESS:
			return createVideoLabelSuccess(state, action);
		case actionTypes.CREATE_VIDEO_LABEL_FAIL:
			return createVideoLabelFail(state, action);
		case actionTypes.DELETE_VIDEO_LAYER_SUCCESS:
			return deleteVideoLayerSuccess(state, action);
		case actionTypes.SET_VIDEO_LAYERS:
			return setVideoLayers(state, action);
		case actionTypes.GET_VIDEO_LAYERS_FAIL:
			return getVideoLayersFail(state, action);
		case actionTypes.GET_VIDEO_LAYERS_SUCCESS:
			return getVideoLayersSuccess(state);
		case actionTypes.SET_DRAW_TOOL_TYPE:
			return setDrawToolType(state, action);
		case actionTypes.CREATE_MARKER_TYPE_SUCCESS:
			return createMarkerTypeSuccess(state, action);
		case actionTypes.DELETE_MARKER_TYPE_SUCCESS:
			return deleteMarkerTypeSuccess(state, action);
		case actionTypes.SET_MARKER_TYPE_ON_LAYER_SUCCESS:
			return setMarkerTypeOnLayerSuccess(state, action);
		case actionTypes.SET_MARKER_TYPE_ON_LAYER_FAIL:
			return setMarkerTypeOnLayerFail(state, action);
		case actionTypes.SET_IMAGE_ON_LAYER_SUCCESS:
			return setImageOnLayerSuccess(state, action);
		case actionTypes.SET_IMAGE_ON_LAYER_FAIL:
			return setImageOnLayerFail(state, action);
		case actionTypes.SET_VISIBLE_LAYER:
			return setVisibleLayer(state, action);
		case actionTypes.PLAY_ONLY_ONE_VIDEO_LAYER:
			return playOnlyOneVideoLayer(state, action);
		case actionTypes.GET_VIDEO_ANALYSIS_SUCCESS:
			return getVideoAnalysisSuccess(state, action);
		case actionTypes.GET_VIDEO_ANALYSIS_FAIL:
			return getVideoAnalysisFail(state, action);
		case actionTypes.CREATE_VIDEO_ANALYSIS_SUCCESS:
			return createVideoAnalysisSuccess(state, action);
		case actionTypes.CREATE_VIDEO_ANALYSIS_FAIL:
			return createVideoAnalysisFail(state, action);
		case actionTypes.UPDATE_VIDEO_ANALYSIS_SUCCESS:
			return updateVideoAnalysisSuccess(state, action);
		case actionTypes.UPDATE_VIDEO_ANALYSIS_FAIL:
			return updateVideoAnalysisFail(state, action);
		case actionTypes.GET_VIDEO_ANALYSIS_BY_COACH_ID_SUCCESS:
			return getVideoAnalysisByCoachIdSuccess(state, action);
		case actionTypes.GET_VIDEO_ANALYSIS_BY_COACH_ID_FAIL:
			return getVideoAnalysisByCoachIdFail(state, action);
		case actionTypes.UPDATE_ZOOM_VALUE:
			return updateZoomValue(state, action);
		case actionTypes.RESET_VIDEO_ANALYSIS:
			return resetVideoAnalysis(state);
		case actionTypes.SET_SECONDS_TO_TIMELINE_WRAPPER_WIDTH_RATIO:
			return setSecondsToTimelineWrapperWidthRatio(state, action);
		case actionTypes.SET_SLIDER_VALUE:
			return setSliderValue(state, action);
		case actionTypes.SET_VIDEO_ANALYSIS_ERROR:
			return setVideoAnalysisError(state, action);
		case actionTypes.ADD_COMMENT_TO_LAYER_SUCCESS:
			return setAddCommentToLayerSuccess(state);
		case actionTypes.ADD_COMMENT_TO_LAYER_START:
			return setAddCommentToLayerStart(state);
		case actionTypes.SET_VIDEO_ANALYSIS_CONTAINER_WIDTH:
			return setVideoAnalysisContainerWidth(state, action);
		case actionTypes.SET_IS_PAINT_MENU_OPEN:
			return setIsPaintMenuOpen(state, action);
		case actionTypes.SET_DRAW_REF:
			return setDrawRef(state, action);
		case actionTypes.SET_DRAW_TOOL_WIDTH:
			return setDrawToolWidth(state, action);
		case actionTypes.SET_DRAW_TOOL_COLOR:
			return setDrawToolColor(state, action);
		case actionTypes.SET_DRAW_REF_ID:
			return setDrawRefId(state, action);
		case actionTypes.SET_VIDEO_PLAYER_REF:
			return setVideoPlayerRef(state, action);
		case actionTypes.SET_MAX_VALUE_SLIDER:
			return setMaxValueSlider(state, action);
		case actionTypes.VIDEO_ANALYSIS_START_LOADING:
			return videoAnalysisStartLoading(state);
		case actionTypes.SET_ACTIVE_LAYER_SUCCESS:
			return setActiveLayerSuccess(state, action);
		case actionTypes.SET_MINI_PLAYER_PROPS_FOR_EDITING:
			return setMiniPlayerPropsForEditing(state, action);
		case actionTypes.SET_SKETCH_CANVAS_DIMENSIONS:
			return setSketchCanvasDimensions(state, action);
		case actionTypes.SET_DELETE_VIDEO_ANALYSIS_LOADING:
			return setDeleteVideoAnalysisLoading(state, action);
		case actionTypes.SET_DELETE_ALL_LAYERS_LOADING:
			return setDeleteAllLayersLoading(state, action);
		case actionTypes.SET_DELETE_ALL_LAYERS_ERROR:
			return setDeleteAllLayersError(state, action);
		case actionTypes.SET_DELETE_ALL_LAYERS_SUCCESS:
			return setDeleteAllLayersSuccess(state, action);
		case actionTypes.SET_CREATE_VIDEO_ANALYSIS_LOADING:
			return setCreateVideoAnalysisLoading(state, action);
		default:
			return state;
	}
};

export default reducer;
