import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	markersModalInputText: '',
	isMarkerColorSubmitted: false,
	isFrameRangeSettled: false,
	isCommentModalOpen: false,
	isCommentTooltipClicked: false,
	isCommentTooltipClosed: false,
	isPencilButtonClicked: false,
	isSingleRangeClicked: false,
	isRangeClicked: false,
	isFullRangeClicked: false,
	isBeginOfRangeClicked: false,
	isEndOfRangeClicked: false,
	isVideoEnded: false,
	isPlayButtonClicked: false,
	isPaintMenuClosed: false,
	rangeValue: {
		commitedValue: null,
		currentValue: null,
	},
};

const setMarkersModalInputText = (state, action) => {
	return updateObject(state, { markersModalInputText: action.text });
};
const setIsMarkerColorSubmitted = (state, action) => {
	return updateObject(state, { isMarkerColorSubmitted: action.value });
};
const setIsFrameRangeSettled = (state, action) => {
	return updateObject(state, { isFrameRangeSettled: action.value });
};
const setIsCommentModalOpen = (state, action) => {
	return updateObject(state, { isCommentModalOpen: action.value });
};
const setIsCommentTooltipClicked = (state, action) => {
	return updateObject(state, { isCommentTooltipClicked: action.value });
};
const setIsCommentTooltipClosed = (state, action) => {
	return updateObject(state, { isCommentTooltipClosed: action.value });
};
const setIsPencilButtonClicked = (state, action) => {
	return updateObject(state, { isPencilButtonClicked: action.value });
};
const setIsSingleRangeClicked = (state, action) => {
	return updateObject(state, { isSingleRangeClicked: action.value });
};
const setIsRangeClicked = (state, action) => {
	return updateObject(state, { isRangeClicked: action.value });
};
const setIsFullRangeClicked = (state, action) => {
	return updateObject(state, { isFullRangeClicked: action.value });
};
const setRangeValue = (state, action) => {
	if (action.payload === 'commited') {
		return updateObject(state, {
			rangeValue: { ...state.rangeValue, commitedValue: action.value },
		});
	} else {
		return updateObject(state, { rangeValue: { ...state.rangeValue, currentValue: action.value } });
	}
};

const updateTutorialState = (state, action) => {
	if (action.payload === 'begin_of_range_clicked') {
		return updateObject(state, { isBeginOfRangeClicked: action.value });
	} else if (action.payload === 'end_of_range_clicked') {
		return updateObject(state, { isEndOfRangeClicked: action.value });
	} else if (action.payload === 'video_ended') {
		return updateObject(state, { isVideoEnded: action.value });
	} else if (action.payload === 'play_button_clicked') {
		return updateObject(state, { isPlayButtonClicked: action.value });
	} else if (action.payload === 'painting_menu_closed') {
		return updateObject(state, { isPaintMenuClosed: action.value });
	}
};

const resetTutorial = (state) => {
	return updateObject(state, {
		markersModalInputText: '',
		isMarkerColorSubmitted: false,
		isFrameRangeSettled: false,
		isCommentModalOpen: false,
		isCommentTooltipClicked: false,
		isCommentTooltipClosed: false,
		isPencilButtonClicked: false,
		isSingleRangeClicked: false,
		isRangeClicked: false,
		isFullRangeClicked: false,
		isBeginOfRangeClicked: false,
		isEndOfRangeClicked: false,
		isVideoEnded: false,
		isPlayButtonClicked: false,
		isPaintMenuClosed: false,
		rangeValue: {
			commitedValue: null,
			currentValue: null,
		},
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_MARKERS_MODAL_INPUT_TEXT:
			return setMarkersModalInputText(state, action);
		case actionTypes.SET_IS_MARKER_COLOR_SUBMITTED:
			return setIsMarkerColorSubmitted(state, action);
		case actionTypes.SET_IS_FRAME_RANGE_SETTLED:
			return setIsFrameRangeSettled(state, action);
		case actionTypes.SET_IS_COMMENT_MODAL_OPEN:
			return setIsCommentModalOpen(state, action);
		case actionTypes.SET_IS_COMMENT_TOOLTIP_CLICKED:
			return setIsCommentTooltipClicked(state, action);
		case actionTypes.SET_IS_COMMENT_TOOLTIP_CLOSED:
			return setIsCommentTooltipClosed(state, action);
		case actionTypes.SET_IS_PENCIL_BUTTON_CLICKED:
			return setIsPencilButtonClicked(state, action);
		case actionTypes.SET_IS_SINGLE_RANGE_CLICKED:
			return setIsSingleRangeClicked(state, action);
		case actionTypes.SET_IS_RANGE_CLICKED:
			return setIsRangeClicked(state, action);
		case actionTypes.SET_IS_FULL_RANGE_CLICKED:
			return setIsFullRangeClicked(state, action);
		case actionTypes.SET_RANGE_VALUE:
			return setRangeValue(state, action);
		case actionTypes.UPDATE_TUTORIAL_STATE:
			return updateTutorialState(state, action);
		case actionTypes.RESET_TUTORIAL_STATE:
			return resetTutorial(state);

		default:
			return state;
	}
};

export default reducer;
