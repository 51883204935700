import React, { useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import { BurgerButton, StyledDrawer } from './Burger.Styled';
import ButtonSecondary from '../../../../UI/Buttons/ButtonSecondary/ButtonSecondary';
import logoRed from '../../../../../assets/images/logo-title-white.png';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';

const Burger = () => {
	const [isBurgerOpen, setIsBurgerOpen] = useState(false);
	const toggleDrawer = (open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		setIsBurgerOpen(open);
	};

	const { push } = useHistory();

	const handleRedirect = (to) => {
		setIsBurgerOpen(false);
		push(to);
	};
	return (
		<>
			<BurgerButton onClick={toggleDrawer(true)}>
				<MenuIcon />
			</BurgerButton>
			<StyledDrawer anchor={'right'} open={isBurgerOpen} onClose={toggleDrawer(false)}>
				<div>
					<img src={logoRed} alt="" />
				</div>
				<div className="buttons-wrapper">
					<ButtonSecondary
						outline="true"
						style={{ marginBottom: '20px' }}
						onClick={() => handleRedirect('/login')}
					>
						<FormattedMessage id="common.login" />
					</ButtonSecondary>
					<ButtonSecondary outline="true" onClick={() => handleRedirect('/register')}>
						<FormattedMessage id="header.register" />
					</ButtonSecondary>
				</div>
			</StyledDrawer>
		</>
	);
};

export default Burger;
