export const LOGIN = 'LOGIN';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SET_MY_DATA_SUCCESS = 'SET_MY_DATA_SUCCESS';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const UPDATE_EXPIRES_IN_AND_REFRESH_TOKEN = 'UPDATE_EXPIRES_IN_AND_REFRESH_TOKEN';
export const AUTO_LOGIN = 'AUTO_LOGIN';
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_MY_DATA = 'SET_MY_DATA';
export const GET_TOKEN_INFO = 'GET_TOKEN_INFO';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const SET_ERROR_USER_DATA = 'SET_ERROR_USER_DATA';
export const SET_AUTH_ERROR_NULL = 'SET_AUTH_ERROR_NULL';
export const SET_REDIRECT_FROM_LOGIN = 'SET_REDIRECT_FROM_LOGIN';
export const SET_PROVIDER = 'SET_PROVIDER';
export const SET_PROVIDER_TOKEN = 'SET_PROVIDER_TOKEN';
export const SET_DISCORD_STEAM_LOADING = 'SET_DISCORD_STEAM_LOADING';
export const SET_TOKEN_AND_USER_ID = 'SET_TOKEN_AND_USER_ID';

export const AUTO_SET_LANGUAGE_INIT = 'AUTO_SET_LANGUAGE_INIT';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS';

export const OPEN_REGISTER_MODAL = 'OPEN_REGISTER_MODAL';
export const SET_REGISTER_ERROR_NULL = 'SET_REGISTER_ERROR_NULL';
export const CLOSE_REGISTER_MODAL = 'CLOSE_REGISTER_MODAL';
export const REGISTER = 'REGISTER';
export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const GET_GAMES = 'GET_GAMES';
export const GET_GAMES_START = 'GET_GAMES_START';
export const GET_GAMES_SUCCESS = 'GET_GAMES_SUCCESS';
export const GET_GAMES_FAIL = 'GET_GAMES_FAIL';

export const GET_USER_ROLES = 'GET_USER_ROLES';
export const GET_USER_ROLES_SUCCESS = 'GET_USER_ROLES_SUCCESS';
export const GET_USER_ROLES_FAIL = 'GET_USER_ROLES_FAIL';

//video-analysis
export const CREATE_VIDEO_LABEL = 'CREATE_VIDEO_LABEL';
export const CREATE_VIDEO_LAYER = 'CREATE_VIDEO_LAYER';
export const CREATE_VIDEO_LABEL_SUCCESS = 'CREATE_VIDEO_LABEL_SUCCESS';
export const CREATE_VIDEO_LABEL_FAIL = 'CREATE_VIDEO_LABEL_FAIL';
export const DELETE_VIDEO_LAYER = 'DELETE_VIDEO_LAYER';
export const DELETE_VIDEO_LAYER_SUCCESS = 'DELETE_VIDEO_LAYER_SUCCESS';
export const GET_VIDEO_LAYERS = 'GET_VIDEO_LAYERS';
export const SET_VIDEO_LAYERS = 'SET_VIDEO_LAYERS';
export const GET_VIDEO_LAYERS_FAIL = 'GET_VIDEO_LAYERS_FAIL';
export const GET_VIDEO_LAYERS_SUCCESS = 'GET_VIDEO_LAYERS_SUCCESS';
export const SET_DRAW_TOOL_TYPE = 'SET_DRAW_TOOL_TYPE';
export const CREATE_MARKER_TYPE = 'CREATE_MARKER_TYPE';
export const CREATE_MARKER_TYPE_SUCCESS = 'CREATE_MARKER_TYPE_SUCCESS';
export const DELETE_MARKER_TYPE = 'DELETE_MARKER_TYPE';
export const DELETE_MARKER_TYPE_SUCCESS = 'DELETE_MARKER_TYPE_SUCCESS';
export const SET_MARKER_TYPE_ON_LAYER = 'SET_MARKER_TYPE_ON_LAYER';
export const SET_MARKER_TYPE_ON_LAYER_SUCCESS = 'SET_MARKER_TYPE_ON_LAYER_SUCCESS';
export const SET_MARKER_TYPE_ON_LAYER_FAIL = 'SET_MARKER_TYPE_ON_LAYER_FAIL';
export const SET_IMAGE_ON_LAYER = 'SET_IMAGE_ON_LAYER';
export const SET_IMAGE_ON_LAYER_SUCCESS = 'SET_IMAGE_ON_LAYER_SUCCESS';
export const SET_IMAGE_ON_LAYER_FAIL = 'SET_IMAGE_ON_LAYER_FAIL';
export const SET_COMMENT_ON_LAYER = 'SET_COMMENT_ON_LAYER';
export const SET_COMMENT_ON_LAYER_SUCCESS = 'SET_COMMENT_ON_LAYER_SUCCESS';
export const SET_COMMENT_ON_LAYER_FAIL = 'SET_COMMENT_ON_LAYER_FAIL';
export const SET_SHOW_COMMENT_LAYER_ID = 'SET_SHOW_COMMENT_LAYER_ID';
export const SET_VISIBLE_LAYER = 'SET_VISIBLE_LAYER';
export const PLAY_ONLY_ONE_VIDEO_LAYER = 'PLAY_ONLY_ONE_VIDEO_LAYER';
export const GET_VIDEO_ANALYSIS = 'GET_VIDEO_ANALYSIS';
export const GET_VIDEO_URL = 'GET_VIDEO_URL';
export const GET_VIDEO_ANALYSIS_SUCCESS = 'GET_VIDEO_ANALYSIS_SUCCESS';
export const GET_VIDEO_ANALYSIS_FAIL = 'GET_VIDEO_ANALYSIS_FAIL';
export const CREATE_VIDEO_ANALYSIS = 'CREATE_VIDEO_ANALYSIS';
export const CREATE_VIDEO_ANALYSIS_SUCCESS = 'CREATE_VIDEO_ANALYSIS_SUCCESS';
export const CREATE_VIDEO_ANALYSIS_FAIL = 'CREATE_VIDEO_ANALYSIS_FAIL';
export const UPDATE_VIDEO_ANALYSIS = 'UPDATE_VIDEO_ANALYSIS';
export const UPDATE_VIDEO_ANALYSIS_SUCCESS = 'UPDATE_VIDEO_ANALYSIS_SUCCESS';
export const UPDATE_VIDEO_ANALYSIS_FAIL = 'UPDATE_VIDEO_ANALYSIS_FAIL';
export const GET_VIDEO_ANALYSIS_BY_COACH_ID = 'GET_VIDEO_ANALYSIS_BY_COACH_ID';
export const GET_VIDEO_ANALYSIS_BY_COACH_ID_SUCCESS = 'GET_VIDEO_ANALYSIS_BY_COACH_ID_SUCCESS';
export const GET_VIDEO_ANALYSIS_BY_COACH_ID_FAIL = 'GET_VIDEO_ANALYSIS_BY_COACH_ID_FAIL';
export const UPDATE_ZOOM_VALUE = 'UPDATE_ZOOM_VALUE';
export const RESET_VIDEO_ANALYSIS = 'RESET_VIDEO_ANALYSIS';
export const SET_SECONDS_TO_TIMELINE_WRAPPER_WIDTH_RATIO =
	'SET_SECONDS_TO_TIMELINE_WRAPPER_WIDTH_RATIO';
export const SET_SLIDER_VALUE = 'SET_SLIDER_VALUE';
export const DELETE_VIDEO_LABEL = 'DELETE_VIDEO_LABEL';
export const SET_VIDEO_ANALYSIS_ERROR = 'SET_VIDEO_ANALYSIS_ERROR';
export const SET_VIDEO_LAYER_MARKER_TYPE = 'SET_VIDEO_LAYER_MARKER_TYPE';
export const ADD_COMMENT_TO_LAYER = 'ADD_COMMENT_TO_LAYER';
export const ADD_COMMENT_TO_LAYER_START = 'ADD_COMMENT_TO_LAYER_START';
export const ADD_COMMENT_TO_LAYER_SUCCESS = 'ADD_COMMENT_TO_LAYER_SUCCESS';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const SET_VIDEO_ANALYSIS_CONTAINER_WIDTH = 'SET_VIDEO_ANALYSIS_CONTAINER_WIDTH';
export const SET_IS_PAINT_MENU_OPEN = 'SET_IS_PAINT_MENU_OPEN';
export const SET_DRAW_REF = 'SET_DRAW_REF';
export const SET_DRAW_TOOL_WIDTH = 'SET_DRAW_TOOL_WIDTH';
export const SET_DRAW_TOOL_COLOR = 'SET_DRAW_TOOL_COLOR';
export const SET_DRAW_REF_ID = 'SET_DRAW_REF_ID';
export const SET_VIDEO_PLAYER_REF = 'SET_VIDEO_PLAYER_REF';
export const SET_MAX_VALUE_SLIDER = 'SET_MAX_VALUE_SLIDER';
export const VIDEO_ANALYSIS_START_LOADING = 'VIDEO_ANALYSIS_START_LOADING';
export const ADD_DRAW_TO_LAYER = 'ADD_DRAW_TO_LAYER';
export const SET_ACTIVE_LAYER = 'SET_ACTIVE_LAYER';
export const SET_ACTIVE_LAYER_SUCCESS = 'SET_ACTIVE_LAYER_SUCCESS';
export const SET_MINI_PLAYER_PROPS_FOR_EDITING = 'SET_MINI_PLAYER_PROPS_FOR_EDITING';
export const DELETE_DRAWING_FROM_LAYER = 'DELETE_DRAWING_FROM_LAYER';
export const SET_SKETCH_CANVAS_DIMENSIONS = 'SET_SKETCH_CANVAS_DIMENSIONS';
export const SET_CHANGE_TUTORIAL_STATE = 'SET_CHANGE_TUTORIAL_STATE';
export const DELETE_VIDEO_ANALYSIS = 'DELETE_VIDEO_ANALYSIS';
export const SET_DELETE_VIDEO_ANALYSIS_LOADING = 'SET_DELETE_VIDEO_ANALYSIS_LOADING';
export const DELETE_ALL_LAYERS = 'DELETE_ALL_LAYERS';
export const SET_DELETE_ALL_LAYERS_LOADING = 'SET_DELETE_ALL_LAYERS_LOADING';
export const SET_DELETE_ALL_LAYERS_ERROR = 'SET_DELETE_ALL_LAYERS_ERROR';
export const SET_DELETE_ALL_LAYERS_SUCCESS = 'SET_DELETE_ALL_LAYERS_SUCCESS';
export const SET_CREATE_VIDEO_ANALYSIS_LOADING = 'SET_CREATE_VIDEO_ANALYSIS_LOADING';

//

export const SET_VIEW = 'SET_VIEW';
export const SET_IS_KEY_LISTENER_DISABLED = 'SET_IS_KEY_LISTENER_DISABLED';
export const SET_IS_MARKER_MODAL_OPEL = 'SET_IS_MARKER_MODAL_OPEL';

export const GET_STATS_SUCCESS = 'GET_STATS_SUCCESS';
export const GET_STATS_START = 'GET_STATS_START';
export const GET_STEAM_STATS = 'GET_STEAM_STATS';
export const GET_STATS_FAIL = 'GET_STATS_FAIL';

export const SET_MARKERS_MODAL_INPUT_TEXT = 'SET_MARKERS_MODAL_INPUT_TEXT';
export const SET_IS_MARKER_COLOR_SUBMITTED = 'SET_IS_MARKER_COLOR_SUBMITTED';
export const SET_IS_FRAME_RANGE_SETTLED = 'SET_IS_FRAME_RANGE_SETTLED';
export const SET_IS_COMMENT_MODAL_OPEN = 'SET_IS_COMMENT_MODAL_OPEN';
export const SET_IS_COMMENT_TOOLTIP_CLICKED = 'SET_IS_COMMENT_TOOLTIP_CLICKED';
export const SET_IS_COMMENT_TOOLTIP_CLOSED = 'SET_IS_COMMENT_TOOLTIP_CLOSED';
export const SET_IS_PENCIL_BUTTON_CLICKED = 'SET_IS_PENCIL_BUTTON_CLICKED';
export const SET_IS_SINGLE_RANGE_CLICKED = 'SET_IS_SINGLE_RANGE_CLICKED';
export const SET_IS_RANGE_CLICKED = 'SET_IS_RANGE_CLICKED';
export const SET_IS_FULL_RANGE_CLICKED = 'SET_IS_FULL_RANGE_CLICKED';
export const SET_RANGE_VALUE = 'SET_RANGE_VALUE';
export const UPDATE_TUTORIAL_STATE = 'UPDATE_TUTORIAL_STATE';
export const RESET_TUTORIAL_STATE = 'RESET_TUTORIAL_STATE';

export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_USER_DATA_FAIL = 'GET_USER_DATA_FAIL';
export const GET_TUTORIAL_SUCCESS = 'GET_TUTORIAL_SUCCESS';
export const GET_AVAILABLE_USER_LANGUAGES = 'GET_AVAILABLE_USER_LANGUAGES';
export const GET_AVAILABLE_USER_LANGUAGES_SUCCESS = 'GET_AVAILABLE_USER_LANGUAGES_SUCCESS';
export const GET_AVAILABLE_USER_LANGUAGES_FAIL = 'GET_AVAILABLE_USER_LANGUAGES_FAIL';
export const GET_AVAILABLE_USER_GAMES = 'GET_AVAILABLE_USER_GAMES';
export const GET_AVAILABLE_USER_GAMES_SUCCESS = 'GET_AVAILABLE_USER_GAMES_SUCCESS';
export const GET_AVAILABLE_USER_GAMES_FAIL = 'GET_AVAILABLE_USER_GAMES_FAIL';
export const GET_USER_GAMES = 'GET_USER_GAMES';
export const GET_USER_GAMES_SUCCESS = 'GET_USER_GAMES_SUCCESS';
export const GET_USER_GAMES_FAIL = 'GET_USER_GAMES_FAIL';
export const ADD_USER_GAME = 'ADD_USER_GAME';
export const ADD_USER_GAME_SUCCESS = 'ADD_USER_GAME_SUCCESS';
export const ADD_USER_GAME_FAIL = 'ADD_USER_GAME_FAIL';
export const DELETE_USER_GAME = 'DELETE_USER_GAME';
export const DELETE_USER_GAME_SUCCESS = 'DELETE_USER_GAME_SUCCESS';
export const DELETE_USER_GAME_FAIL = 'DELETE_USER_GAME_FAIL';
export const GET_AVAILABLE_GAME_RANKS = 'GET_AVAILABLE_GAME_RANKS';
export const GET_AVAILABLE_GAME_RANKS_SUCCESS = 'GET_AVAILABLE_GAME_RANKS_SUCCESS';
export const GET_AVAILABLE_GAME_RANKS_FAIL = 'GET_AVAILABLE_GAME_RANKS_FAIL';
export const GET_GAME_RANKS = 'GET_GAME_RANKS';
export const GET_GAME_RANKS_SUCCESS = 'GET_GAME_RANKS_SUCCESS';
export const GET_GAME_RANKS_FAIL = 'GET_GAME_RANKS_FAIL';
export const SET_GAME_RANK = 'SET_GAME_RANK';
export const SET_GAME_RANK_SUCCESS = 'SET_GAME_RANK_SUCCESS';
export const SET_GAME_RANK_FAIL = 'SET_GAME_RANK_FAIL';
export const DELETE_GAME_RANK = 'DELETE_GAME_RANK';
export const DELETE_GAME_RANK_SUCCESS = 'DELETE_GAME_RANK_SUCCESS';
export const DELETE_GAME_RANK_FAIL = 'DELETE_GAME_RANK_FAIL';
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
export const SET_USER_LANGUAGE_SUCCESS = 'SET_USER_LANGUAGE_SUCCESS';
export const SET_USER_LANGUAGE_FAIL = 'SET_USER_LANGUAGE_FAIL';

export const GET_VIDEOS_SUCCESS = 'GET_VIDEOS_SUCCESS';
export const SET_VIDEOS_ERROR = 'SET_VIDEOS_ERROR';
export const SET_START_VIDEOS_LOADING = 'SET_START_VIDEOS_LOADING';
export const SET_START_DELETE_VIDEOS_LOADING = 'SET_START_DELETE_VIDEOS_LOADING';
export const SET_START_GET_VIDEOS_LOADING = 'SET_START_GET_VIDEOS_LOADING';
export const ADD_NEW_VIDEO = 'ADD_NEW_VIDEO';
export const SET_UPLOAD_PERCENTAGE = 'SET_UPLOAD_PERCENTAGE';
export const GET_VIDEOS = 'GET_VIDEOS';
export const DELETE_VIDEO = 'DELETE_VIDEO';
export const CANCEL_UPLOAD = 'CANCEL_UPLOAD';
export const UPDATE_VIDEO = 'UPDATE_VIDEO';

//orders
export const SET_START_ORDERS_LOADING = 'SET_START_ORDERS_LOADING';
export const SET_ORDERS_SUCCESS = 'SET_ORDERS_SUCCESS';
export const SET_ORDERS_ERROR = 'SET_ORDERS_ERROR';
export const POST_ORDER = 'POST_ORDER';
export const GET_OPENED_ORDERS = 'GET_OPENED_ORDERS';
export const SET_ORDER_SENT_SUCCESS = 'SET_ORDER_SENT_SUCCESS';
export const OPEN_ORDERS_FORM = 'OPEN_ORDERS_FORM';
export const SET_SPECIFIC_ORDER_SUCCESS = 'SET_SPECIFIC_ORDER_SUCCESS';
export const GET_SPECIFIC_ORDER = 'GET_SPECIFIC_ORDER';
export const APPLY_FOR_ORDER = 'APPLY_FOR_ORDER';
export const SET_APPLY_FOR_ORDER_SUCCESS = 'SET_APPLY_FOR_ORDER_SUCCESS';
export const GET_MY_ORDERS = 'GET_MY_ORDERS';
export const SET_MY_PUBLISHED_ORDERS_SUCCESS = 'SET_MY_PUBLISHED_ORDERS_SUCCESS';
export const ACCEPT_BID = 'ACCEPT_BID';
export const REJECT_BID = 'REJECT_BID';
export const GET_MY_BIDS = 'GET_MY_BIDS';
export const SET_MY_BIDS_SUCCESS = 'SET_MY_BIDS_SUCCESS';
export const MARK_BID_AS_VIEWED = 'MARK_BID_AS_VIEWED';
export const DELETE_MY_BID = 'DELETE_MY_BID';
export const SET_IS_MARKER_MODAL_OPEN = 'SET_IS_MARKER_MODAL_OPEN';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const RESET_SENDING_ORDER = 'RESET_SENDING_ORDER';
export const SET_POST_ORDER_ERROR = 'SET_POST_ORDER_ERROR';
export const SET_SPECIFIC_ORDER_ERROR = 'SET_SPECIFIC_ORDER_ERROR';

//Feedbacks
export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const SEND_FEEDBACK_LOADING = 'SEND_FEEDBACK_LOADING';
export const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS';
export const SEND_FEEDBACK_ERROR = 'SEND_FEEDBACK_ERROR';

// General reducer
export const TURN_ON_DEBUG_MODE = 'TURN_ON_DEBUG_MODE';

// General band object
export const CREATE_GENERAL_BAND_ANALYSIS = 'CREATE_GENERAL_BAND_ANALYSIS';
export const CREATE_GENERAL_BAND_ANALYSIS_SUCCESS = 'CREATE_GENERAL_BAND_ANALYSIS_SUCCESS';
export const CREATE_GENERAL_BAND_ANALYSIS_FAIL = 'CREATE_GENERAL_BAND_ANALYSIS_FAIL';
export const SET_GENERAL_BAND_ANALYSIS_STORE_ID = 'SET_GENERAL_BAND_ANALYSIS_STORE_ID';
export const GET_GENERAL_BAND_ANALYSIS_LIST = 'GET_GENERAL_BAND_ANALYSIS_LIST';
export const GET_GENERAL_BAND_ANALYSIS_LIST_FAIL = 'GET_GENERAL_BAND_ANALYSIS_LIST_FAIL';

// Muse measurement object
export const CREATE_MUSE_BAND_ANALYSIS = 'CREATE_MUSE_BAND_ANALYSIS';
export const GET_MUSE_BAND_ANALYSIS_LIST = 'GET_MUSE_BAND_ANALYSIS_LIST';
export const GET_MUSE_BAND_ANALYSIS_LIST_SUCCESS = 'GET_MUSE_BAND_ANALYSIS_LIST_SUCCESS';
export const GET_MUSE_BAND_ANALYSIS_LIST_FAIL = 'GET_MUSE_BAND_ANALYSIS_LIST_FAIL';
export const CREATE_MUSE_BAND_ANALYSIS_SUCCESS = 'CREATE_MUSE_BAND_ANALYSIS_SUCCESS';
export const CREATE_MUSE_BAND_ANALYSIS_FAIL = 'CREATE_MUSE_BAND_ANALYSIS_FAIL';
export const POST_MUSE_BAND_ANALYSIS_PACKAGE = 'POST_MUSE_BAND_ANALYSIS_PACKAGE';
export const POST_MUSE_BAND_ANALYSIS_PACKAGE_SUCCESS = 'POST_MUSE_BAND_ANALYSIS_PACKAGE_SUCCESS';
export const POST_MUSE_BAND_ANALYSIS_PACKAGE_FAIL = 'POST_MUSE_BAND_ANALYSIS_PACKAGE_FAIL';
export const GET_MUSE_BAND_ANALYSIS_PACKAGES = 'GET_MUSE_BAND_ANALYSIS_PACKAGES';
export const GET_MUSE_BAND_ANALYSIS_PACKAGES_SUCCESS = 'GET_MUSE_BAND_ANALYSIS_PACKAGES_SUCCESS';
export const GET_MUSE_BAND_ANALYSIS_PACKAGES_FAIL = 'GET_MUSE_BAND_ANALYSIS_PACKAGES_FAIL';
export const CLEAR_RECORDED_DATA_FOR_CHART = 'CLEAR_RECORDED_DATA_FOR_CHART';
export const CLEAR_STORE_MUSE_BAND_ANALYSIS_LIST = 'CLEAR_STORE_MUSE_BAND_ANALYSIS_LIST';
export const TURN_ON_MUSE_DEBUGG = 'TURN_ON_MUSE_DEBUGG';

export const GET_PRODUCTS_LOADING = 'GET_PRODUCTS_LOADING';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';
export const SET_ALL_PRODUCTS = 'SET_ALL_PRODUCTS';
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
// Payments
export const PURCHASE_PRODUCT = 'PURCHASE_PRODUCT';
export const PURCHASE_PRODUCT_LOADING = 'PURCHASE_PRODUCT_LOADING';
export const PURCHASE_PRODUCT_ERROR = 'PURCHASE_PRODUCT_ERROR';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';
export const SET_ONBOARDED = 'SET_ONBOARDED';
export const SET_ONBOARDED_ERROR = 'SET_ONBOARDED_ERROR';
export const GET_ONBOARDED = 'GET_ONBOARDED';
export const GET_PAYOUT_REQUIREMENTS = 'GET_PAYOUT_REQUIREMENTS';
export const SET_PAYOUT_REQUIREMENTS = 'SET_PAYOUT_REQUIREMENTS';
export const SET_PAYOUT_REQUIREMENTS_ERROR = 'SET_PAYOUT_REQUIREMENTS_ERROR';
export const GET_ONBOARDING_URL = 'GET_ONBOARDING_URL';
export const SET_ONBOARDING_URL = 'SET_ONBOARDING_URL';
export const SET_ONBOARDING_URL_ERROR = 'SET_ONBOARDING_URL_ERROR';
export const PAYOUT_MONEY = 'PAYOUT_MONEY';
export const SET_PAYOUT_MONEY_LOADING = 'SET_PAYOUT_MONEY_LOADING';
export const SET_PAYOUT_MONEY_ERROR = 'SET_PAYOUT_MONEY_ERROR';
export const SET_PAYOUT_MONEY_SUCCESS = 'SET_PAYOUT_MONEY_SUCCESS';
// Ratings
export const CREATE_RATING = 'CREATE_RATING';
export const CREATE_RATING_SUCCESS = 'CREATE_RATING_SUCCESS';
export const CREATE_RATING_FAIL = 'CREATE_RATING_FAIL';
export const GET_RATING_INFO = 'GET_RATING_INFO';
export const GET_RATING_INFO_SUCCESS = 'GET_RATING_INFO_SUCCESS';
export const GET_RATING_INFO_FAIL = 'GET_RATING_INFO_FAIL';
// Courses
export const SET_START_COURSES_LOADING = 'SET_START_COURSES_LOADING';
export const SET_COURSES_SUCCESS = 'SET_COURSES_SUCCESS';
export const SET_COURSES_ERROR = 'SET_COURSES_ERROR';
export const GET_OPENED_COURSES = 'GET_OPENED_COURSES';
export const SET_POSTING_COURSE_LOADING = 'SET_POSTING_COURSE_LOADING';
export const SET_POSTING_COURSE_SUCCESS = 'SET_POSTING_COURSE_SUCCESS';
export const SET_POSTING_COURSE_ERROR = 'SET_POSTING_COURSE_ERROR';
export const POST_COURSE = 'POST_COURSE';
export const GET_SPECIFIC_COURSE = 'GET_SPECIFIC_COURSE';
export const SET_SPECIFIC_COURSE_SUCCESS = 'SET_SPECIFIC_COURSE_SUCCESS';
export const SET_SPECIFIC_COURSE_ERROR = 'SET_SPECIFIC_COURSE_ERROR';
export const BUY_COURSE = 'BUY_COURSE';
export const BUY_COURSE_SUCCESS = 'BUY_COURSE_SUCCESS';
export const BUY_COURSE_ERROR = 'BUY_COURSE_ERROR';
export const SET_MY_COURSES_LOADING = 'SET_MY_COURSES_LOADING';
export const SET_MY_COURSES_ERROR = 'SET_MY_COURSES_ERROR';
export const SET_MY_COURSES_SUCCESS = 'SET_MY_COURSES_SUCCESS';
export const GET_MY_COURSES = 'GET_MY_COURSES';
//Subscriptions
export const SET_CREATE_SUBSCRIPTION_ERROR = 'SET_CREATE_SUBSCRIPTION_ERROR';
export const SET_CREATE_SUBSCRIPTION_LOADING = 'SET_CREATE_SUBSCRIPTION_LOADING';
export const SET_CREATE_SUBSCRIPTION_SUCCESS = 'SET_CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
