import React, { useState } from 'react';
import { IconButton, Grid, Avatar } from '@material-ui/core';
import {
	StyledDrawer,
	DrawerHeader,
	StyledIconButton,
	StyledTitle,
	DrawerWrapper,
	StyledDivider,
	BigNavLink,
	StyledList,
	StyledListItem,
} from './MobileMenu.Styled';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AccountCircle from '@material-ui/icons/AccountCircle';
import classes from '../../../CenterHeaderControls/CenterHeaderControls.module.scss';

const MobileMenu = ({ userData, menuData, nickname, ringImg, userAvatar }) => {
	const [isMenuOpen, setIsOpen] = useState(false);

	const toggleDrawer = (open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setIsOpen(open);
	};

	return (
		<>
			<IconButton
				aria-controls="menu-appbar"
				aria-haspopup="true"
				onClick={toggleDrawer(true)}
				color="inherit"
				style={{ padding: '0px', marginLeft: 'auto' }}
			>
				<Avatar alt={nickname} src={userAvatar} />
			</IconButton>
			<StyledDrawer anchor="right" open={isMenuOpen} onClose={toggleDrawer(false)}>
				<DrawerHeader>
					<StyledIconButton onClick={toggleDrawer(false)}>
						<ChevronRightIcon style={{ fill: 'white' }} />
					</StyledIconButton>
				</DrawerHeader>
				<Grid container direction="row" alignItems="center">
					<AccountCircle style={{ width: '37px', height: '37px', fill: 'white' }} />
					<StyledTitle>{userData ? userData.nickname : null}</StyledTitle>
				</Grid>

				<DrawerWrapper>
					<StyledList>
						<StyledDivider />

						{menuData.map((item) => (
							<StyledListItem button>
								<BigNavLink
									to={item.link}
									className={classes.NavLink}
									onClick={toggleDrawer(false)}
								>
									{item.title}
								</BigNavLink>
							</StyledListItem>
						))}
					</StyledList>
				</DrawerWrapper>
			</StyledDrawer>
		</>
	);
};

export default MobileMenu;
