import React from 'react';

import { Badge, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useMenuData from './useMenuData';
import useBidsNotifications from '../../../../hooks/useBidsNotifications';

const ProfileHeaderMenu = () => {
	const userData = useSelector((state) => state.userData.userData);

	const { amount } = useBidsNotifications();
	const { menuData, loading } = useMenuData(userData, amount);

	return (
		<List>
			{!loading && (
				<>
					{menuData.map((item,i) => {
						return (
							<NavLink to={item.link} key={`${item.link}${i}`} activeClassName="selected">
								<ListItem button>
									<Badge
										color="secondary"
										badgeContent={item.amount}
										invisible={Boolean(!item.amount)}
										anchorOrigin={{
											vertical: 'top',
											horizontal: 'left',
										}}
									>
										<Tooltip title={item.title} arrow placement="right">
											<ListItemIcon>{item.icon}</ListItemIcon>
										</Tooltip>
									</Badge>

									<ListItemText primary={item.title} />
								</ListItem>
							</NavLink>
						);
					})}
				</>
			)}
		</List>
	);
};

export default ProfileHeaderMenu;
