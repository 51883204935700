import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from '../../axios-instance';
import { dataURLtoFile } from '../../shared/utility';

export function* createMarkerTypeSaga(action) {
	try {
		const response = yield axios.post('marker_types', {
			marker_type: { name: action.name, color: action.color },
		});
		yield put(actions.createMarkerTypeSuccess(response.data));
		yield put(actions.setMarkerTypeOnLayerSuccess(action.layerId, response.data));
	} catch (error) {
		console.log(error);
	}
}
export function* setVideoLayerMarkerTypeSaga(action) {
	const { name, color, layerId, videoAnalysisId } = action;
	try {
		const response = yield axios.patch(`game_analysis_layers/${layerId}`, {
			game_analysis_layer: {
				name,
				marker_color: color,
			},
		});

		if (response) {
			yield put(actions.getVideoLayers(videoAnalysisId));
		}
	} catch (error) {
		console.log(error);
	}
}

export function* getVideoLayersSaga(action) {
	try {
		const response = yield axios.get(
			`game_analysis_layers/index_by_game_analysis/${action.gameAnalysisId}`
		);
		if (response) {
			if (action.payload === 'delete_all_layers') {
				yield put(actions.setDeleteAllLayersLoading(false));
				yield put(actions.setDeleteAllLayersSuccess(true));
			}

			yield put(actions.setVideoLayers(response.data));
			yield put(actions.getVideoLayersSuccess());
		}
	} catch (error) {
		yield put(actions.getVideoLayersFail('Wystąpił błąd!'));
	}
}

export function* deleteVideoLayerSaga(action) {
	yield put(actions.videoAnalysisStartLoading());
	try {
		yield axios.delete(`game_analysis_layers/${action.id}`);
		yield put(actions.deleteVideoLayerSuccess(action.id));
	} catch (error) {
		console.log(error);
	}
}

export function* createVideoLabelSaga(action) {
	const { layerId, frameFrom, frameTo, videoAnalysisId } = action;

	try {
		const response = yield axios.post(
			`game_analysis_layers/${layerId}/game_analysis_layer_labels`,
			{
				game_analysis_layer_id: layerId,
				game_analysis_layer_label: {
					frame_from: frameFrom,
					frame_to: frameTo,
				},
			}
		);

		if (response) {
			yield put(actions.getVideoLayers(videoAnalysisId));
		}
	} catch (error) {
		yield put(actions.createVideoLabelFail(error));
	}
}
export function* createVideoLayerSaga(action) {
	const { videoAnalysisId } = action;

	try {
		const response = yield axios.post(`game_analysis_layers`, {
			game_analysis_layer: {
				game_analysis_id: videoAnalysisId,
				marker_type_id: null,
				name: null,
			},
		});

		if (response) {
			yield put(actions.getVideoLayers(videoAnalysisId));
		}
	} catch (error) {
		yield put(actions.createVideoLabelFail(error));
	}
}

export function* setMarkerTypeOnLayerSaga(action) {
	try {
		const response = yield axios.patch(`game_analysis_layers/${action.layerId}`, {
			game_analysis_marker: {
				marker_type_id: action.markerTypeId,
			},
		});
		yield put(actions.setMarkerTypeOnLayerSuccess(action.layerId, response.data.marker_type));
	} catch (error) {
		yield put(actions.setMarkerTypeOnLayerFail(error));
	}
}

export function* setImageOnLayerSaga(action) {
	try {
		const file = dataURLtoFile(action.image, `${new Date()}_layer${action.layerId}.png`);
		const formData = new FormData();
		formData.append('game_analysis_marker[image]', file);
		const response = yield axios.patch(`game_analysis_layers/${action.layerId}`, formData, {
			headers: { 'content-type': 'multipart/form-data' },
		});
		yield put(actions.setImageOnLayerSuccess(action.layerId, response.data.image));
	} catch (error) {
		yield put(actions.setImageOnLayerFail(error));
	}
}

export function* deleteMarkerTypeSaga(action) {
	try {
		yield axios.delete(`marker_types/${action.id}`);
		yield put(actions.deleteMarkerTypeSuccess(action.id));
	} catch (error) {
		console.log(error);
	}
}

export function* getVideoAnalysisSaga(action) {
	yield put(actions.videoAnalysisStartLoading());

	try {
		const response = yield axios.get(`game_analyses/${action.id}`);
		yield put(actions.getVideoAnalysisSuccess(response.data));
	} catch (error) {
		console.log(error.response);
		yield put(actions.getVideoAnalysisFail(error.response.data.error));
	}
}

export function* createVideoAnalysisSaga(action) {
	const { videoId, summary, recommendation, coachId, from_order_id } = action;
	yield put(actions.setCreateVideoAnalysisLoading(true));
	try {
		const response = yield axios.post(`game_analyses`, {
			game_analysis: {
				game_video_id: videoId,
				summary: summary,
				recommendation: recommendation,
				coach_id: coachId,
				from_order_id,
			},
		});
		yield put(actions.setCreateVideoAnalysisLoading(false));

		yield put(actions.createVideoAnalysisSuccess(response.data));
		yield put(actions.getVideoAnalysis(response.data.id));
		yield put(actions.getVideoAnalysisByCoachId(action.coachId));
	} catch (error) {
		console.log(error.response);
		yield put(actions.createVideoAnalysisFail(error.response.data.error));
	}
}

export function* updateVideoAnalysisSaga(action) {
	const { id, summary, finished, orderId } = action;
	yield put(actions.setStartVideosLoading());
	try {
		const response = yield axios.patch(`game_analyses/${id}`, {
			game_analysis: {
				summary: summary,
				finished: finished,
			},
		});
		if (response.data.finished) {
			yield put(actions.getVideoLayersSuccess());
			if (orderId) {
				yield put(actions.updateOrder(orderId, true));
			}
		}
	} catch (error) {
		yield put(actions.updateVideoAnalysisFail('Wystąpił błąd!'));
	}
}

export function* getVideoAnalysisByCoachIdSaga(action) {
	try {
		const response = yield axios.get(`game_analyses/index_by_coach/${action.coachId}`);
		yield put(actions.getVideoAnalysisByCoachIdSuccess(response.data));
		yield put(actions.setDeleteVideoAnalysisLoading(false));
	} catch (error) {
		yield console.log(error.response);
		yield put(actions.getVideoAnalysisByCoachIdFail('Wystąpił błąd!'));
		yield put(actions.setDeleteVideoAnalysisLoading(false));
	}
}
export function* deleteVideoLabelSaga(action) {
	const { labelId, videoAnalysisId } = action;
	try {
		const response = yield axios.delete(`game_analysis_layer_labels/${labelId}`);
		if (response) {
			yield put(actions.getVideoLayers(videoAnalysisId));
		}
	} catch (error) {
		yield put(actions.setVideoAnalysisError('Wystąpił błąd!'));
	}
}
export function* addCommentToLayerSaga(action) {
	const { layerId, frame, content, videoAnalysisId } = action;

	yield put(actions.addCommentToLayerStart());
	try {
		const response = yield axios.post(
			`game_analysis_layers/${layerId}/game_analysis_layer_comments`,
			{
				game_analysis_layer_comment: {
					content,
					frame,
				},
			}
		);
		if (response) {
			yield put(actions.getVideoLayers(videoAnalysisId));
		}
	} catch (error) {
		yield put(actions.setVideoAnalysisError(error.response));
	}
}
export function* deleteCommentSaga(action) {
	const { commentId, videoAnalysisId } = action;

	yield put(actions.addCommentToLayerStart());
	try {
		yield axios.delete(`game_analysis_layer_comments/${commentId}`);
		yield put(actions.getVideoLayers(videoAnalysisId));
	} catch (error) {
		yield put(actions.setVideoAnalysisError(error.response));
	}
}
export function* updateCommentSaga(action) {
	const { commentId, layerId, userId, content, frame, videoAnalysisId } = action;

	yield put(actions.addCommentToLayerStart());
	try {
		const response = yield axios.patch(`game_analysis_layer_comments/${commentId}`, {
			game_analysis_layer_comment: {
				game_analysis_layer_id: layerId,
				author_id: userId,
				content,
				frame,
			},
		});

		if (response) {
			yield put(actions.getVideoLayers(videoAnalysisId));
		}
	} catch (error) {
		yield put(actions.setVideoAnalysisError(error.response));
	}
}

export function* addDrawToLayerSaga(action) {
	const {
		drawingId,
		layerId,
		videoAnalysisId,
		data: { frame_from, frame_to, src, canvas_ref },
	} = action;

	const body = {
		game_analysis_layer_drawing: {
			frame_from,
			frame_to,
			src,
			canvas_ref,
		},
	};

	yield put(actions.videoAnalysisStartLoading());

	try {
		let response;

		if (drawingId) {
			response = yield axios.patch(`game_analysis_layer_drawings/${drawingId}`, body);
		} else {
			response = yield axios.post(
				`game_analysis_layers/${layerId}/game_analysis_layer_drawings`,
				body
			);
		}

		if (response) {
			yield put(actions.getVideoLayers(videoAnalysisId));
		}
	} catch (error) {
		yield put(actions.setVideoAnalysisError(error.response));
	}
}
export function* deleteDrawingFromLayerSaga(action) {
	const { drawingId, videoAnalysisId } = action;

	yield put(actions.videoAnalysisStartLoading());
	try {
		const response = yield axios.delete(`game_analysis_layer_drawings/${drawingId}`);

		if (response) {
			yield put(actions.getVideoLayers(videoAnalysisId));
		}
	} catch (error) {
		yield put(actions.setVideoAnalysisError(error.response));
	}
}

export function* setActiveLayerSaga(action) {
	const { layerId, videoAnalysisId } = action;

	try {
		const response = yield axios.post(`game_analyses/${videoAnalysisId}/activate_layer`, {
			active_layer_id: layerId,
		});

		if (response) {
			yield put(actions.setActiveLayerSuccess(response.data.active_layer_id));
		}
	} catch (error) {
		yield put(actions.setVideoAnalysisError(error.response));
	}
}
export function* setChangeTutorialStateSaga(action) {
	const { videoAnalysisId, data } = action;

	try {
		const response = yield axios.post(`users/custom/${videoAnalysisId}/change_tutorial_state`, {
			content: data,
		});

		if (response) {
			yield put(actions.getTutorialSuccess(response.data.tutorial.content));
		}
	} catch (error) {
		yield put(actions.setVideoAnalysisError(error.response));
	}
}
export function* deleteVideoAnalysisSaga(action) {
	const { videoAnalysisId, userId } = action;

	yield put(actions.setDeleteVideoAnalysisLoading(true));
	try {
		const response = yield axios.delete(`game_analyses/${videoAnalysisId}`);

		if (response) {
			console.log(response);
			yield put(actions.getVideoAnalysisByCoachId(userId));
		}
	} catch (error) {
		yield put(actions.setVideoAnalysisError(error.response));
	}
}

export function* deleteAllLayersSaga(action) {
	const { videoAnalysisId } = action;

	yield put(actions.setDeleteAllLayersLoading(true));
	try {
		const response = yield axios.delete(`game_analyses/${videoAnalysisId}/destroy_all_layers`);

		if (response) {
			yield put(actions.getVideoLayers(videoAnalysisId, 'delete_all_layers'));
		}
	} catch (error) {
		yield put(actions.setDeleteAllLayersLoading(false));
		yield put(actions.setDeleteAllLayersError(error.response));
	}
}
