import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from '../../axios-instance';

export function* sendFeedback(action) {
	const { feedback } = action;
	yield put(actions.sendFeedbackLoading());
	try {
		const response = yield axios.post('feedbacks', { feedback });
		if (response) {
			yield put(actions.sendFeedbackSuccess(response));
		}
	} catch (error) {
		yield put(actions.sendFeedbackError(error.response));
	}
}
