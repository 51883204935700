import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from '../../axios-instance';
import appDebug from '../../helpers/debugger.js';
import { select } from 'redux-saga/effects';

export const getGeneralBandAnalysisId = (state) => state.muse.generalBandAnalysisId;
export const getMuseBandAnalysisId = (state) => state.muse.museBandAnalysisId;
export const getDebugMode = (state) => state.general.debugMode;

// General band analysis id
export function* createGeneralBandAnalysisSaga() {
	let debugMode = yield select(getDebugMode);
	appDebug('info',`Function createBandAnalysisSaga`, debugMode);
	try {
		const response = yield axios.post('band_analyses',{
			"band_analysis": {
			  "band_type": "Muse S"
			}
		});
		appDebug('info',`Action createGeneralBandAnalysisSuccess with parameter ${response.data.id}`, debugMode);

		yield put(actions.createGeneralBandAnalysisSuccess(response.data.id));
	} catch (error) {
		const message = `${error.response.status} ${error.response.statusText}`
		appDebug('error',`Action createGeneralBandAnalysisFail ${message}`, true);
		yield put(actions.createGeneralBandAnalysisFail(message));
	}
}

// General band analysis list
export function* getGeneralBandAnalysisListSaga() {
	let debugMode = yield select(getDebugMode);
	appDebug('info',`Function getBandAnalysisListSaga`, debugMode);
	try {
		const response = yield axios.get('band_analyses');
		const bandList = response.data.data;
		appDebug('info',`Response from band_analyses ${JSON.stringify(response.data.data)} with lenght ${bandList.length}`, debugMode);
		let generalMuseBandId = undefined;
		for(let i=0;i<bandList.length;i++) {
			if(bandList[i].band_type === "Muse S" && !generalMuseBandId) {
				generalMuseBandId = bandList[i].id;
			}
		} 

		if(generalMuseBandId) {
			appDebug('info',`Action setGeneralBandAnalysisStoreId with parameter ${generalMuseBandId}`, debugMode);
			yield put(actions.setGeneralBandAnalysisStoreId(generalMuseBandId));
		} else {
			appDebug('warn',`General band id for muse doesn't exist.`, debugMode);
			appDebug('info',`Action createBandAnalysis`, debugMode);
			yield put(actions.createGeneralBandAnalysis());
		}
	} catch (error) {
		const message = `${error.response.status} ${error.response.statusText}`
		appDebug('error',`Action getGeneralBandAnalysisListFail ${message}`, true);
		yield put(actions.getGeneralBandAnalysisListFail(message));
	}
}

export function* createMuseBandAnalysisSaga() {
	let debugMode = yield select(getDebugMode);
	const generalBandAnalysisId= yield select(getGeneralBandAnalysisId);
	appDebug('info',`Function createMuseBandAnalysisSaga`, debugMode);
	try {
		const response = yield axios.post(`band_analyses/${generalBandAnalysisId}/muse_band_analyses`, {
			"muse_band_analysis": {
			  "title": "Muse band analysis",
			  "description": "Muse band analysis"
			}
		  });
		  appDebug('info',`Action createMuseBandAnalysisSuccess with parameter ${response.data.id}`, debugMode);
		yield put(actions.createMuseBandAnalysisSuccess(response.data.id));
	} catch (error) {
		const message = `${error.response.status} ${error.response.statusText}`
		appDebug('error',`Action createMuseBandAnalysisSagaFail ${message}`, true);
		yield put(actions.createMuseBandAnalysisFail(message));
	}
}

export function* getMuseBandAnalysisListSaga(action) {
	let page = action.payload;
	let debugMode = yield select(getDebugMode);
	const generalBandAnalysisId= yield select(getGeneralBandAnalysisId);
	appDebug('info',`Function getMuseBandAnalysisListSaga`, debugMode);
	try {
		const response = yield axios.get(`band_analyses/${generalBandAnalysisId}/muse_band_analyses?page=${page}`);
		const bandList = response.data.data;
		const totalPages = response.data.meta.total_pages;
		appDebug('info',`Response from band_analyses list ${JSON.stringify(response.data.data)} with lenght ${bandList.length}`, debugMode);
		yield put(actions.getMuseBandAnalysisListSuccess(response.data.data));
		appDebug('info',`Pages ${page} from ${totalPages}`, debugMode);
		if (page<totalPages) {
			page++;
			appDebug('info',`Getting page ${page}`, debugMode);					
			yield put(actions.getMuseBandAnalysisList(page));
		} else {
			appDebug('success',`Pages ${page} from ${totalPages} fetched`, debugMode);
		}		
	} catch (error) {
		const message = `${error.response.status} ${error.response.statusText}`
		appDebug('error',`Action getGeneralBandAnalysisListFail ${message}`, true);
		yield put(actions.getMuseBandAnalysisListFail(message));
	}
}

export function* postMuseBandAnalysisPackageSaga(action) {
	let debugMode = yield select(getDebugMode);
	appDebug('info',`Data to send length ${action.payload.package.length}`, debugMode);
	const generalBandAnalysisId= yield select(getGeneralBandAnalysisId);
	const museBandAnalysisId= yield select(getMuseBandAnalysisId);
	appDebug('info',`Function postMuseBandAnalysisPackageSaga`, debugMode);
	try {
		const response = yield axios.post(`band_analyses/${generalBandAnalysisId}/muse_band_analyses/${museBandAnalysisId}/muse_band_analysis_packages`, {
			"muse_band_analysis_package": {
				"package_number": action.payload.packageNumber,
				"measurements_count": action.payload.package.length,
				"measurements": action.payload.package
			}
		  });
		  appDebug('success',`Action postMuseBandAnalysisPackageSuccess`, debugMode);
		yield put(actions.postMuseBandAnalysisPackageSuccess(response.data.id));
	} catch (error) {
		const message = `${error.response.status} ${error.response.statusText}`
		appDebug('error',`Action postMuseBandAnalysisPackageFail ${message}`, true);
		yield put(actions.postMuseBandAnalysisPackageFail(message));
	}
}

export function* getMuseBandAnalysisPackagesSaga(action) {
	let debugMode = yield select(getDebugMode);
	const generalBandAnalysisId= yield select(getGeneralBandAnalysisId);
	const museBandAnalysisId = action.payload.museBandAnalysisId;
	let page = action.payload.page;
	appDebug('info',`Function getMuseBandAnalysisPackageSaga`, debugMode);
	try {
		const response = yield axios.get(`band_analyses/${generalBandAnalysisId}/muse_band_analyses/${museBandAnalysisId}/muse_band_analysis_packages?page=${page}`);
		const totalPages = response.data.meta.total_pages;

		let processedData = [];
		const dataToProcess = response.data.data;
		for(let i=0;i<dataToProcess.length;i++) {
			for(let ii=0;ii<dataToProcess[i].measurements.length;ii++) {
				processedData.push(dataToProcess[i].measurements[ii]);
			}
		}
		appDebug('success',`Action getMuseBandAnalysisPackagesSuccess in getMuseBandAnalysisPackagesSaga processed data for show send to muse store`, debugMode);
		appDebug('info',`Sended data ${JSON.stringify(processedData)}`, debugMode);
		yield put(actions.getMuseBandAnalysisPackagesSuccess(processedData));
		if (page<totalPages) {
			page++;
			appDebug('info',`Getting page ${page}`, debugMode);					
			yield put(actions.getMuseBandAnalysisPackages({museBandAnalysisId: museBandAnalysisId, page: page}));
		} else {
			appDebug('success',`Pages ${page} from ${totalPages} fetched`, debugMode);
		}		
	} catch (error) {
		const message = `${error.response.status} ${error.response.statusText}`
		appDebug('error',`Action postMuseBandAnalysisPackageFail ${message}`, true);
		yield put(actions.getMuseBandAnalysisPackagesFail(message));
	}
}