import * as actionTypes from './actionTypes';

export const purchaseProductLoading = (bool) => {
	return {
		type: actionTypes.PURCHASE_PRODUCT_LOADING,
		bool,
	};
};
export const purchaseProductError = (error) => {
	return {
		type: actionTypes.PURCHASE_PRODUCT_ERROR,
		error,
	};
};
export const purchaseProduct = (id) => {
	return {
		type: actionTypes.PURCHASE_PRODUCT,
		id,
	};
};
export const setRedirectURL = (url) => {
	return {
		type: actionTypes.SET_REDIRECT_URL,
		url,
	};
};
export const getOnboarded = () => {
	return {
		type: actionTypes.GET_ONBOARDED,
	};
};
export const setOnboarded = (value) => {
	return {
		type: actionTypes.SET_ONBOARDED,
		value,
	};
};
export const setOnboardedError = (error) => {
	return {
		type: actionTypes.SET_ONBOARDED_ERROR,
		error,
	};
};
export const getPayoutRequirements = () => {
	return {
		type: actionTypes.GET_PAYOUT_REQUIREMENTS,
	};
};
export const setPayoutRequirements = (value) => {
	return {
		type: actionTypes.SET_PAYOUT_REQUIREMENTS,
		value,
	};
};
export const setPayoutRequirementsError = (error) => {
	return {
		type: actionTypes.SET_PAYOUT_REQUIREMENTS_ERROR,
		error,
	};
};
export const getOnboardingURL = () => {
	return {
		type: actionTypes.GET_ONBOARDING_URL,
	};
};
export const setOnboardingURL = (url) => {
	return {
		type: actionTypes.SET_ONBOARDING_URL,
		url,
	};
};
export const setOnboardingURLError = (error) => {
	return {
		type: actionTypes.SET_ONBOARDING_URL_ERROR,
		error,
	};
};
export const payoutMoney = (coins_amount) => {
	return {
		type: actionTypes.PAYOUT_MONEY,
		coins_amount,
	};
};
export const setPayoutMoneyLoading = (value) => {
	return {
		type: actionTypes.SET_PAYOUT_MONEY_LOADING,
		value,
	};
};
export const setPayoutMoneyError = (error) => {
	return {
		type: actionTypes.SET_PAYOUT_MONEY_ERROR,
		error,
	};
};
export const setPayoutMoneySuccess = (value) => {
	return {
		type: actionTypes.SET_PAYOUT_MONEY_SUCCESS,
		value,
	};
};
