import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid ${({ theme }) => theme.colors.strokeBlue};
	border-radius: 3px;
	p {
		display: flex;
		align-items: center;
		padding: 10px 25px;
		img {
			margin-left: 5px;
		}
	}
`;
