import {put} from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from '../../axios-instance';

export function* getUserRolesSaga(action) {
  try {
    const response = yield axios.get('user_roles');
    yield put(actions.getUserRolesSuccess(response.data));
  } catch (error) {
    yield put(actions.getUserRolesFail('Wystąpił błąd!'));
  }
}