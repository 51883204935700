import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	videos: null,
	error: null,
	loading: false,
	deleteLoading: false,
	getVideosLoading: false,
	uploadPercentage: null,
};

const getVideosSuccess = (state, action) => {
	if (action.payload === 'from_table') {
		return updateObject(state, {
			videos: [...state.videos, ...action.videos],
			error: null,
			loading: false,
			deleteLoading: false,
			getVideosLoading: false,
		});
	} else if (action.payload === 'override') {
		return updateObject(state, {
			videos: action.videos,
			error: null,
			loading: false,
			deleteLoading: false,
			getVideosLoading: false,
		});
	} else {
		return updateObject(state, {
			videos: [...state.videos, action.videos],
			error: null,
			loading: false,
			deleteLoading: false,
			getVideosLoading: false,
		});
	}
};
const setStartVideosLoading = (state, action) => {
	return updateObject(state, { loading: true });
};
const setStartDeleteVideosLoading = (state, action) => {
	return updateObject(state, { deleteLoading: true });
};
const setStartGetVideosLoading = (state, action) => {
	return updateObject(state, { getVideosLoading: true });
};

const setVideosError = (state, action) => {
	setTimeout(() => {
		return updateObject(state, {
			error: null,
		});
	}, 6000);
	return updateObject(state, {
		error: action.error,
		loading: false,
		getVideosLoading: false,
		deleteLoading: false,
	});
};

const setUploadPercentage = (state, action) => {
	return updateObject(state, { uploadPercentage: action.percentage });
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_VIDEOS_SUCCESS:
			return getVideosSuccess(state, action);
		case actionTypes.SET_START_VIDEOS_LOADING:
			return setStartVideosLoading(state, action);
		case actionTypes.SET_START_DELETE_VIDEOS_LOADING:
			return setStartDeleteVideosLoading(state, action);
		case actionTypes.SET_START_GET_VIDEOS_LOADING:
			return setStartGetVideosLoading(state, action);
		case actionTypes.SET_VIDEOS_ERROR:
			return setVideosError(state, action);
		case actionTypes.SET_UPLOAD_PERCENTAGE:
			return setUploadPercentage(state, action);
		default:
			return state;
	}
};

export default reducer;
