import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function useUserData() {
	const userData = useSelector((state) => state.userData.userData);

	const [loading, setLoading] = useState(true);
	const [userRole, setUserRole] = useState(null);
	const [userId, setUserId] = useState(null);

	useEffect(() => {
		if (userData) {
			setUserRole(userData.user_role.name);
			setUserId(userData.id);
			setLoading(false);
		}
	}, [userData]);

	return {
		userRole,
		userId,
		loading,
	};
}
