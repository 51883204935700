import React from 'react';
import LogoBase64 from './LogoBase64';
import { EULogoComponentStyled } from './EULogo.Styled';
import { NavLink, withRouter } from 'react-router-dom';

const EULogo = () => {
    return (
        <NavLink to="/euinfopage" style={{ textDecoration: 'none' }}>
            <EULogoComponentStyled>
                <img src={LogoBase64} alt='eu logo' />
                <p>Unia Europejska</p>
            </EULogoComponentStyled>
        </NavLink>
    )
}

export default withRouter(EULogo);