import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';
import appDebug from '../../helpers/debugger.js';

const initialState = {
  debugMode: false,
  errorCreateRating: false,
  errorGetRating: false,
  rated: false,
  ratingsListByOrder: [],
}

const createRatingSuccess = (state, action) => {
  appDebug('success',`Function createRatingSuccess in reducer ${action.payload}`, state.debugMode);
  return updateObject(state, state);
}

const createRatingFail = (state, action) => {
  appDebug('fail',`Function createRatingFail in reducer ${action.payload}`, state.debugMode);
  return updateObject(state, {errorCreateRating: true});
}

const getRatingInfoSuccess = (state, action) => {
  appDebug('success',`Function getRatingInfoSuccess in reducer ${action.payload}`, state.debugMode);
  let updatedRatingListByOrder = state.ratingsListByOrder;
  if (action.payload.vote) {
    updatedRatingListByOrder[action.payload.order.id] = {
      test: "test",
      vote: action.payload.vote
    }
  }
  return updateObject(state, {ratingsListByOrder: updatedRatingListByOrder});
}

const getRatingInfoFail = (state, action) => {
  appDebug('fail',`Function getRatingInfoFail in reducer ${action.payload}`, state.debugMode);
  return updateObject(state, {errorGetRating: true});
}
  
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CREATE_RATING_SUCCESS: return createRatingSuccess(state, action);
		case actionTypes.CREATE_RATING_FAIL: return createRatingFail(state, action);
		case actionTypes.GET_RATING_INFO_SUCCESS: return getRatingInfoSuccess(state, action);
		case actionTypes.GET_RATING_INFO_FAIL: return getRatingInfoFail(state, action);
		default: return state;
	}
}

export default reducer;