import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Badge } from '@material-ui/core';
import Logo from '../Logo/Logo';

const LeftHeaderControls = (props) => {
	return (
		<React.Fragment>
			<NavLink to="/">
				<Badge
					badgeContent="BETA"
					color="error"
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
				>
					<Logo />
				</Badge>
			</NavLink>
		</React.Fragment>
	);
};

export default withRouter(LeftHeaderControls);
