import { TextField } from '@material-ui/core';
import styled from 'styled-components';

export const StyledTextField = styled(TextField)`
	.MuiInputBase-input {
		color: white;
		font-family: 'Poppins-Regular';
		padding: 13px;
	}
`;
