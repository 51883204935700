import * as actionTypes from './actionTypes';

export const setMarkersModalInputText = (text) => {
	return {
		type: actionTypes.SET_MARKERS_MODAL_INPUT_TEXT,
		text,
	};
};
export const setIsMarkerColorSubmitted = (value) => {
	return {
		type: actionTypes.SET_IS_MARKER_COLOR_SUBMITTED,
		value,
	};
};
export const setIsFrameRangeSettled = (value) => {
	return {
		type: actionTypes.SET_IS_FRAME_RANGE_SETTLED,
		value,
	};
};
export const setIsCommentModalOpen = (value) => {
	return {
		type: actionTypes.SET_IS_COMMENT_MODAL_OPEN,
		value,
	};
};
export const setIsCommentTooltipClicked = (value) => {
	return {
		type: actionTypes.SET_IS_COMMENT_TOOLTIP_CLICKED,
		value,
	};
};
export const setIsCommentTooltipClosed = (value) => {
	return {
		type: actionTypes.SET_IS_COMMENT_TOOLTIP_CLOSED,
		value,
	};
};
export const setIsPencilButtonClicked = (value) => {
	return {
		type: actionTypes.SET_IS_PENCIL_BUTTON_CLICKED,
		value,
	};
};
export const setIsSingleRangeClicked = (value) => {
	return {
		type: actionTypes.SET_IS_SINGLE_RANGE_CLICKED,
		value,
	};
};
export const setIsRangeClicked = (value) => {
	return {
		type: actionTypes.SET_IS_RANGE_CLICKED,
		value,
	};
};
export const setIsFullRangeClicked = (value) => {
	return {
		type: actionTypes.SET_IS_FULL_RANGE_CLICKED,
		value,
	};
};
export const setRangeValue = (value, payload) => {
	return {
		type: actionTypes.SET_RANGE_VALUE,
		value,
		payload,
	};
};
export const updateTutorialState = (value, payload) => {
	return {
		type: actionTypes.UPDATE_TUTORIAL_STATE,
		value,
		payload,
	};
};
export const resetTutorial = () => {
	return {
		type: actionTypes.RESET_TUTORIAL_STATE,
	};
};
