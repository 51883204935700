import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
	games: [],
  error: null,
  loading: false
}

const getGamesStart = (state, action) => {
  return updateObject(state, {error: null, loading: true});
}

const getGamesSuccess = (state, action) => {
  return updateObject(state, {games: action.games, error: null, loading: false});
}

const getGamesFail = (state, action) => {
  return updateObject(state, {error: action.error, loading: false});
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_GAMES_START: return getGamesStart(state, action);
		case actionTypes.GET_GAMES_SUCCESS: return getGamesSuccess(state, action);
		case actionTypes.GET_GAMES_FAIL: return getGamesFail(state, action);
		default: return state;
	}
}

export default reducer;