import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	view: null,
	isKeyListenerDisabled: false,
	isMarkersModalOpen: false,
};

const setView = (state, action) => {
	return updateObject(state, { view: action.view });
};
const setIsKeyListenerDisabled = (state, action) => {
	return updateObject(state, { isKeyListenerDisabled: action.value });
};
const setIsMarkerModalOpen = (state, action) => {
	return updateObject(state, { isMarkersModalOpen: action.value });
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_VIEW:
			return setView(state, action);
		case actionTypes.SET_IS_KEY_LISTENER_DISABLED:
			return setIsKeyListenerDisabled(state, action);
		case actionTypes.SET_IS_MARKER_MODAL_OPEN:
			return setIsMarkerModalOpen(state, action);
		default:
			return state;
	}
};

export default reducer;
