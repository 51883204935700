import React, { Suspense, useEffect } from 'react';
import { Route, Switch, withRouter, useLocation } from 'react-router-dom';
import Layout from './hoc/Layout/Layout';
import Spinner from './components/UI/Spinner/Spinner';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as actions from './store/actions/index';
import Logout from './containers/Auth/Logout/Logout';
import PropTypes from 'prop-types';
import appDebug from './helpers/debugger.js';
import ReactGA from 'react-ga';
import FontResizer from './components/FontResizer/FontResizer';

const LoginModal = React.lazy(() => {
	return import('./containers/Auth/LoginModal/LoginModal');
});
const RegisterModal = React.lazy(() => {
	return import('./containers/Register/RegisterModal/RegisterModal');
});
const RegisterForm = React.lazy(() => {
	return import('./containers/Register/RegisterForm/RegisterForm');
});
const LoginForm = React.lazy(() => {
	return import('./containers/Auth/LoginForm/LoginForm');
});
const VideoAnalysis = React.lazy(() => {
	return import('./containers/VideoAnalysis/VideoAnalysisContainer');
});
const VideoAnalysisUpload = React.lazy(() => {
	return import('./containers/VideoAnalysisUpload/VideoAnalysisUpload');
});
const Profile = React.lazy(() => {
	return import('./containers/Profiles/Profile');
});
const ProfileEdit = React.lazy(() => {
	return import('./containers/Profiles/ProfileEdit');
});
const Marketplace = React.lazy(() => {
	return import('./containers/Marketplace/Marketplace');
});
const MarketplaceOrderDetails = React.lazy(() => {
	return import('./containers/MarketplaceOrderDetails/MarketplaceOrderDetails');
});
const MarketplaceCourseDetails = React.lazy(() => {
	return import('./containers/MarketplaceCourseDetails/MarketplaceCourseDetails');
});
const Orders = React.lazy(() => {
	return import('./containers/Orders/Orders');
});
const VideoAnalysisList = React.lazy(() => {
	return import('./containers/VideoAnalysisList/VideoAnalysisList');
});
const Dashboard = React.lazy(() => {
	return import('./components/Dashboard/Dashboard');
});
const Muse = React.lazy(() => {
	return import('./components/Muse/Muse');
});
const BugReport = React.lazy(() => {
	return import('./containers/BugReport/BugReport');
});
const VideoPreview = React.lazy(() => {
	return import('./containers/VideoPreview/VideoPreview');
});
const Wallet = React.lazy(() => {
	return import('./containers/Wallet');
});
const SuccessPage = React.lazy(() => {
	return import('./containers/SuccessPage/SuccessPage');
});
const WalletPayout = React.lazy(() => {
	return import('./containers/WalletPayout');
});
const SendDemo = React.lazy(() => {
	return import('./containers/SendDemo');
});
const Order = React.lazy(() => {
	return import('./containers/Order');
});
const EUInfoPage = React.lazy(() => {
	return import('./components/Static/EUInfoPage/EUInfoPage');
});
const ContactPage = React.lazy(() => {
	return import('./components/Static/ContactPage/ContactPage');
});
const PrivacyPolicy = React.lazy(() => {
	return import('./containers/PrivacyPolicy');
});
const RulesAndRegulations = React.lazy(() => {
	return import('./containers/RulesAndRegulations');
});

const App = (props) => {
	const { isAuth, onTryAutoSetLanguage, onGetGames, setView, logoutLoading } = props;

	const dispatch = useDispatch();
	const userData = useSelector((state) => state.userData.userData);
	const debugMode = useSelector((state) => state.general.debugMode);
	const location = useLocation();

	useEffect(() => {
		ReactGA.initialize('UA-168169537-2');
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, [location]);

	useEffect(() => {
		if (window.location.href.includes('appDebug')) {
			dispatch(actions.turnOnDebugModeAction());
		}
		appDebug('init', '', debugMode);
		appDebug('info', 'Debug mode initialized', debugMode);
		appDebug('info', `debugMode variable state is ${debugMode}`, debugMode);
		appDebug('info', `You'll see label legend below`, debugMode);
		appDebug('info', `This is info box`, debugMode);
		appDebug('success', 'This is success box', debugMode);
		appDebug('warn', 'This is warning box', debugMode);
		appDebug('error', 'This is error box', debugMode);
		appDebug('none', '', debugMode);
	}, [debugMode, dispatch]);

	useEffect(() => {
		if (window.innerWidth < 1024) {
			setView('mobile');
		} else {
			setView('desktop');
		}
		window.addEventListener('resize', () => {
			if (window.innerWidth < 1024) {
				setView('mobile');
			} else {
				setView('desktop');
			}
		});
	}, [setView]);

	useEffect(() => {
		onTryAutoSetLanguage();
	}, [onTryAutoSetLanguage]);

	useEffect(() => {
		onGetGames();
	}, [onGetGames]);

	useEffect(() => {
		if (isAuth && userData) {
			if (userData.user_role.name === 'coach') {
				dispatch(actions.getMyBids());
			} else if (userData.user_role.name === 'player') {
				if (!window.location.pathname.includes('profile')) {
					dispatch(actions.getMyOrders());
				}
			}
		}
	}, [isAuth, userData, dispatch]);

	let routes = (
		<Switch>
			<Route path="/login-form" render={(props) => <LoginForm {...props} />} />
			<Route path="/register-form" render={(props) => <RegisterForm {...props} />} />
			<Route path="/register" render={(props) => <RegisterModal {...props} />} />
			<Route path="/login" render={(props) => <LoginModal {...props} />} />
			<Route path="/muse" render={(props) => <Muse {...props} />} />
			<Route path="/euinfopage" render={(props) => <EUInfoPage {...props} />} />
			<Route path="/contactpage" render={(props) => <ContactPage {...props} />} />
			<Route path="/privacy-policy" render={(props) => <PrivacyPolicy {...props} />} />
			<Route
				path="/rules-and-regulations"
				render={(props) => <RulesAndRegulations {...props} />}
			/>
			<Route path="/" exact component={Dashboard} />
		</Switch>
	);

	if (isAuth) {
		routes = (
			<Switch>
				<Route path="/profile/:id" render={(props) => <Profile {...props} />} />
				<Route path="/profile-edit" render={(props) => <ProfileEdit {...props} />} />
				<Route
					path="/video-analysis/:id"
					render={(props) => <VideoAnalysis {...props} />}
				/>
				<Route
					path="/video-upload"
					render={(props) => <VideoAnalysisUpload {...props} />}
				/>
				<Route path="/register-form" render={(props) => <RegisterForm {...props} />} />
				<Route path="/login-form" render={(props) => <LoginForm {...props} />} />
				<Route path="/register" render={(props) => <RegisterModal {...props} />} />
				<Route path="/logout" component={Logout} />
				<Route path="/login" render={(props) => <LoginModal {...props} />} />
				<Route path="/orders" exact render={(props) => <Orders {...props} />} />
				<Route
					path="/video-analysis-list"
					exact
					render={(props) => <VideoAnalysisList {...props} />}
				/>
				<Route path="/marketplace" exact render={(props) => <Marketplace {...props} />} />
				<Route
					path="/marketplace/:id"
					render={(props) => <MarketplaceOrderDetails {...props} />}
				/>
				<Route
					path="/marketplace-course/:id"
					render={(props) => <MarketplaceCourseDetails {...props} />}
				/>
				<Route path="/muse" render={(props) => <Muse {...props} />} />
				<Route path="/bug-report" render={(props) => <BugReport {...props} />} />
				<Route path="/video-preview/:id" render={(props) => <VideoPreview {...props} />} />
				<Route path="/wallet" render={(props) => <Wallet {...props} />} />
				<Route path="/payment/success" render={(props) => <SuccessPage {...props} />} />
				<Route path="/wallet-payout" render={(props) => <WalletPayout {...props} />} />
				<Route path="/send-demo/:id" render={(props) => <SendDemo {...props} />} />
				<Route path="/order/:id" render={(props) => <Order {...props} />} />
				<Route path="/privacy-policy" render={(props) => <PrivacyPolicy {...props} />} />
				<Route
					path="/rules-and-regulations"
					render={(props) => <RulesAndRegulations {...props} />}
				/>
				<Route path="/euinfopage" render={(props) => <EUInfoPage {...props} />} />
				<Route path="/" exact component={Dashboard} />
			</Switch>
		);
	}

	return (
		<div>
			<Layout>
				<Suspense fallback={<Spinner />}>{logoutLoading ? <Spinner /> : routes}</Suspense>
				<FontResizer />
			</Layout>
		</div>
	);
};

App.propTypes = {
	isAuth: PropTypes.bool,
	onTryAutoLogin: PropTypes.func,
	onTryAutoSetLanguage: PropTypes.func,
	onGetGames: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		logoutLoading: state.auth.loading,
		isAuth: state.auth.token !== null,
		state,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onTryAutoSetLanguage: () => dispatch(actions.autoSetLanguageInit()),
		onGetGames: () => dispatch(actions.getGames()),
		setView: (view) => dispatch(actions.setView(view)),
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
