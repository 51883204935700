import { takeEvery, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actions/actionTypes';
import {
	loginSaga,
	logoutSaga,
	logoutSuccessSaga,
	autoLoginSaga,
	getTokenInfoSaga,
	refreshTokenSaga,
} from './auth';
import { autoSetLanguageSaga, changeLanguageSaga } from './language';
import { registerSaga } from './register';
import { getGamesSaga } from './games';
import { getSteamStatsSaga } from './stats';
import { 
	getUserDataSaga,
	getAvailableUserLanguagesSaga,
	setUserLanguageSaga,
	getAvailableUserGamesSaga,
	getUserGamesSaga,
	addUserGameSaga,
	deleteUserGameSaga,
	getGameRanksSaga,
	getAvailableGameRanksSaga,
	setGameRankSaga,
	deleteGameRankSaga,
} from './userData';
import { getUserRolesSaga } from './userRoles';
import {
	createMarkerTypeSaga,
	getVideoLayersSaga,
	deleteVideoLayerSaga,
	createVideoLabelSaga,
	setMarkerTypeOnLayerSaga,
	setImageOnLayerSaga,
	deleteMarkerTypeSaga,
	getVideoAnalysisSaga,
	createVideoAnalysisSaga,
	updateVideoAnalysisSaga,
	getVideoAnalysisByCoachIdSaga,
	createVideoLayerSaga,
	deleteVideoLabelSaga,
	setVideoLayerMarkerTypeSaga,
	addCommentToLayerSaga,
	deleteCommentSaga,
	updateCommentSaga,
	addDrawToLayerSaga,
	setActiveLayerSaga,
	deleteDrawingFromLayerSaga,
	setChangeTutorialStateSaga,
	deleteVideoAnalysisSaga,
	deleteAllLayersSaga,
} from './videoAnalysis';

import {
	addNewVideoSaga,
	cancelUploadSaga,
	deleteVideoSaga,
	getVideosSaga,
	updateVideoSaga,
} from './videos';
import {
	postOrderSaga,
	getOpenedOrders,
	getSpecificOrder,
	applyForOrder,
	getMyOrders,
	acceptCurrentBid,
	rejectCurrentBid,
	getMyBids,
	markBidAsViewed,
	updateOrder,
	deleteMyBid,
} from './orders';
import { sendFeedback } from './feedbacks';

import {
	createGeneralBandAnalysisSaga,
	getGeneralBandAnalysisListSaga,
	createMuseBandAnalysisSaga,
	getMuseBandAnalysisListSaga,
	postMuseBandAnalysisPackageSaga,
	getMuseBandAnalysisPackagesSaga,
} from './muse';
import { getAllProducts } from './products';
import {
	getOnboardedSaga,
	purchaseProduct,
	getPayoutRequirementsSaga,
	getOnboardingURLSaga,
	payoutMoneySaga,
} from './payments';

import { createRatingSaga, getRatingInfoSaga } from './ratings';

import {
	getOpenedCoursesSaga,
	postCourseSaga,
	getSpecificCourseSaga,
	buyCourseSaga,
	getMyCourses,
} from './courses';
import { createSubscription } from './subscriptions';

export function* watchAuth() {
	yield takeEvery(actionTypes.LOGIN, loginSaga);
	yield takeEvery(actionTypes.LOGOUT_START, logoutSaga);
	yield takeEvery(actionTypes.LOGOUT_SUCCESS, logoutSuccessSaga);
	yield takeEvery(actionTypes.AUTO_LOGIN, autoLoginSaga);
	yield takeEvery(actionTypes.GET_TOKEN_INFO, getTokenInfoSaga);
	yield takeEvery(actionTypes.REFRESH_TOKEN, refreshTokenSaga);
}

export function* watchLanguage() {
	yield takeEvery(actionTypes.AUTO_SET_LANGUAGE_INIT, autoSetLanguageSaga);
	yield takeEvery(actionTypes.CHANGE_LANGUAGE, changeLanguageSaga);
}
export function* watchOrders() {
	yield takeEvery(actionTypes.POST_ORDER, postOrderSaga);
	yield takeEvery(actionTypes.GET_OPENED_ORDERS, getOpenedOrders);
	yield takeEvery(actionTypes.GET_SPECIFIC_ORDER, getSpecificOrder);
	yield takeEvery(actionTypes.APPLY_FOR_ORDER, applyForOrder);
	yield takeEvery(actionTypes.GET_MY_ORDERS, getMyOrders);
	yield takeEvery(actionTypes.ACCEPT_BID, acceptCurrentBid);
	yield takeEvery(actionTypes.REJECT_BID, rejectCurrentBid);
	yield takeEvery(actionTypes.GET_MY_BIDS, getMyBids);
	yield takeEvery(actionTypes.MARK_BID_AS_VIEWED, markBidAsViewed);
	yield takeEvery(actionTypes.UPDATE_ORDER, updateOrder);
	yield takeEvery(actionTypes.DELETE_MY_BID, deleteMyBid);
}
export function* watchRegister() {
	yield takeEvery(actionTypes.REGISTER, registerSaga);
}

export function* watchGames() {
	yield takeEvery(actionTypes.GET_GAMES, getGamesSaga);
}

export function* watchStats() {
	yield takeEvery(actionTypes.GET_STEAM_STATS, getSteamStatsSaga);
}
export function* watchUserData() {
	yield takeEvery(actionTypes.GET_USER_DATA, getUserDataSaga);
	yield takeEvery(actionTypes.GET_AVAILABLE_USER_LANGUAGES, getAvailableUserLanguagesSaga);
	yield takeEvery(actionTypes.SET_USER_LANGUAGE, setUserLanguageSaga);
	yield takeEvery(actionTypes.GET_AVAILABLE_USER_GAMES, getAvailableUserGamesSaga);
	yield takeEvery(actionTypes.GET_USER_GAMES, getUserGamesSaga);
	yield takeEvery(actionTypes.ADD_USER_GAME, addUserGameSaga);
	yield takeEvery(actionTypes.DELETE_USER_GAME, deleteUserGameSaga);
	yield takeEvery(actionTypes.GET_GAME_RANKS, getGameRanksSaga);
	yield takeEvery(actionTypes.GET_AVAILABLE_GAME_RANKS, getAvailableGameRanksSaga);
	yield takeEvery(actionTypes.SET_GAME_RANK, setGameRankSaga);
	yield takeEvery(actionTypes.DELETE_GAME_RANK, deleteGameRankSaga);
}
export function* watchUserRoles() {
	yield takeEvery(actionTypes.GET_USER_ROLES, getUserRolesSaga);
}

export function* watchVideoAnalysis() {
	yield takeEvery(actionTypes.CREATE_MARKER_TYPE, createMarkerTypeSaga);
	yield takeEvery(actionTypes.GET_VIDEO_LAYERS, getVideoLayersSaga);
	yield takeEvery(actionTypes.DELETE_VIDEO_LAYER, deleteVideoLayerSaga);
	yield takeEvery(actionTypes.CREATE_VIDEO_LABEL, createVideoLabelSaga);
	yield takeEvery(actionTypes.CREATE_VIDEO_LAYER, createVideoLayerSaga);
	yield takeEvery(actionTypes.SET_MARKER_TYPE_ON_LAYER, setMarkerTypeOnLayerSaga);
	yield takeEvery(actionTypes.SET_IMAGE_ON_LAYER, setImageOnLayerSaga);
	yield takeEvery(actionTypes.DELETE_MARKER_TYPE, deleteMarkerTypeSaga);
	yield takeEvery(actionTypes.GET_VIDEO_ANALYSIS, getVideoAnalysisSaga);
	yield takeEvery(actionTypes.CREATE_VIDEO_ANALYSIS, createVideoAnalysisSaga);
	yield takeEvery(actionTypes.UPDATE_VIDEO_ANALYSIS, updateVideoAnalysisSaga);
	yield takeEvery(actionTypes.GET_VIDEO_ANALYSIS_BY_COACH_ID, getVideoAnalysisByCoachIdSaga);
	yield takeEvery(actionTypes.DELETE_VIDEO_LABEL, deleteVideoLabelSaga);
	yield takeEvery(actionTypes.SET_VIDEO_LAYER_MARKER_TYPE, setVideoLayerMarkerTypeSaga);
	yield takeEvery(actionTypes.ADD_COMMENT_TO_LAYER, addCommentToLayerSaga);
	yield takeEvery(actionTypes.DELETE_COMMENT, deleteCommentSaga);
	yield takeEvery(actionTypes.UPDATE_COMMENT, updateCommentSaga);
	yield takeEvery(actionTypes.ADD_DRAW_TO_LAYER, addDrawToLayerSaga);
	yield takeEvery(actionTypes.SET_ACTIVE_LAYER, setActiveLayerSaga);
	yield takeEvery(actionTypes.DELETE_DRAWING_FROM_LAYER, deleteDrawingFromLayerSaga);
	yield takeEvery(actionTypes.SET_CHANGE_TUTORIAL_STATE, setChangeTutorialStateSaga);
	yield takeEvery(actionTypes.DELETE_VIDEO_ANALYSIS, deleteVideoAnalysisSaga);
	yield takeEvery(actionTypes.DELETE_ALL_LAYERS, deleteAllLayersSaga);
}

export function* watchVideos() {
	yield takeEvery(actionTypes.ADD_NEW_VIDEO, addNewVideoSaga);
	yield takeEvery(actionTypes.GET_VIDEOS, getVideosSaga);
	yield takeEvery(actionTypes.DELETE_VIDEO, deleteVideoSaga);
	yield takeEvery(actionTypes.CANCEL_UPLOAD, cancelUploadSaga);
	yield takeEvery(actionTypes.UPDATE_VIDEO, updateVideoSaga);
}
export function* watchFeedbacks() {
	yield takeEvery(actionTypes.SEND_FEEDBACK, sendFeedback);
}

export function* watchMuse() {
	yield takeLatest(actionTypes.CREATE_GENERAL_BAND_ANALYSIS, createGeneralBandAnalysisSaga);
	yield takeEvery(actionTypes.GET_GENERAL_BAND_ANALYSIS_LIST, getGeneralBandAnalysisListSaga);
	yield takeEvery(actionTypes.CREATE_MUSE_BAND_ANALYSIS, createMuseBandAnalysisSaga);
	yield takeEvery(actionTypes.GET_MUSE_BAND_ANALYSIS_LIST, getMuseBandAnalysisListSaga);
	yield takeEvery(actionTypes.POST_MUSE_BAND_ANALYSIS_PACKAGE, postMuseBandAnalysisPackageSaga);
	yield takeEvery(actionTypes.GET_MUSE_BAND_ANALYSIS_PACKAGES, getMuseBandAnalysisPackagesSaga);
}
export function* watchProducts() {
	yield takeEvery(actionTypes.GET_ALL_PRODUCTS, getAllProducts);
}
export function* watchPayments() {
	yield takeEvery(actionTypes.PURCHASE_PRODUCT, purchaseProduct);
	yield takeEvery(actionTypes.GET_ONBOARDED, getOnboardedSaga);
	yield takeEvery(actionTypes.GET_PAYOUT_REQUIREMENTS, getPayoutRequirementsSaga);
	yield takeEvery(actionTypes.GET_ONBOARDING_URL, getOnboardingURLSaga);
	yield takeEvery(actionTypes.PAYOUT_MONEY, payoutMoneySaga);
}

export function* watchRatings() {
	yield takeEvery(actionTypes.CREATE_RATING, createRatingSaga);
	yield takeLatest(actionTypes.GET_RATING_INFO, getRatingInfoSaga);
}

export function* watchCourses() {
	yield takeEvery(actionTypes.GET_OPENED_COURSES, getOpenedCoursesSaga);
	yield takeEvery(actionTypes.POST_COURSE, postCourseSaga);
	yield takeEvery(actionTypes.GET_SPECIFIC_COURSE, getSpecificCourseSaga);
	yield takeEvery(actionTypes.BUY_COURSE, buyCourseSaga);
	yield takeEvery(actionTypes.GET_MY_COURSES, getMyCourses);
}
export function* watchSubscriptions() {
	yield takeEvery(actionTypes.CREATE_SUBSCRIPTION, createSubscription);
}
