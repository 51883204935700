import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	p {
		margin-left: 5px;
		font-size: 16px;
	}
`;

export const AvatarWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 46px;
	height: 46px;
	border-radius: 50%;
	background-color: #12192e;
	::before {
		content: '';
		width: 42px;
		height: 42px;
		border-radius: 50%;
		background-color: #070a14;
		position: absolute;
	}
	.MuiAvatar-root {
		width: 38px;
		height: 38px;
	}
`;
