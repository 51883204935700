import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	purchaseProductLoading: false,
	purchaseProductError: null,
	redirectURL: null,
	onboarded: null,
	onboardedError: null,
	payoutRequirements: null,
	payoutRequirementsError: null,
	onboardingURL: null,
	onboardingURLError: null,
	payoutMoneyLoading: false,
	payoutMoneyError: null,
	payoutMoneySuccess: null,
};

const purchaseProductLoading = (state, action) => {
	return updateObject(state, { purchaseProductLoading: action.bool });
};
const purchaseProductError = (state, action) => {
	return updateObject(state, { purchaseProductError: action.error });
};
const setRedirectURL = (state, action) => {
	return updateObject(state, { redirectURL: action.url });
};
const setOnboarded = (state, action) => {
	return updateObject(state, { onboarded: action.value });
};
const setOnboardedError = (state, action) => {
	return updateObject(state, { onboardedError: action.error });
};
const setPayoutRequirements = (state, action) => {
	return updateObject(state, { payoutRequirements: action.value });
};
const setPayoutRequirementsError = (state, action) => {
	return updateObject(state, { payoutRequirementsError: action.error });
};
const setOnboardingURL = (state, action) => {
	return updateObject(state, { onboardingURL: action.url });
};
const setOnboardingURLError = (state, action) => {
	return updateObject(state, { onboardingURLError: action.error });
};
const setPayoutMoneyLoading = (state, action) => {
	return updateObject(state, { payoutMoneyLoading: action.value });
};
const setPayoutMoneyError = (state, action) => {
	return updateObject(state, { payoutMoneyError: action.error });
};
const setPayoutMoneySuccess = (state, action) => {
	return updateObject(state, { payoutMoneySuccess: action.value });
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.PURCHASE_PRODUCT_LOADING:
			return purchaseProductLoading(state, action);
		case actionTypes.PURCHASE_PRODUCT_ERROR:
			return purchaseProductError(state, action);
		case actionTypes.SET_REDIRECT_URL:
			return setRedirectURL(state, action);
		case actionTypes.SET_ONBOARDED:
			return setOnboarded(state, action);
		case actionTypes.SET_ONBOARDED_ERROR:
			return setOnboardedError(state, action);
		case actionTypes.SET_PAYOUT_REQUIREMENTS:
			return setPayoutRequirements(state, action);
		case actionTypes.SET_PAYOUT_REQUIREMENTS_ERROR:
			return setPayoutRequirementsError(state, action);
		case actionTypes.SET_ONBOARDING_URL:
			return setOnboardingURL(state, action);
		case actionTypes.SET_ONBOARDING_URL_ERROR:
			return setOnboardingURLError(state, action);
		case actionTypes.SET_PAYOUT_MONEY_LOADING:
			return setPayoutMoneyLoading(state, action);
		case actionTypes.SET_PAYOUT_MONEY_ERROR:
			return setPayoutMoneyError(state, action);
		case actionTypes.SET_PAYOUT_MONEY_SUCCESS:
			return setPayoutMoneySuccess(state, action);
		default:
			return state;
	}
};

export default reducer;
