import { all, put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from '../../axios-instance';
import appDebug from '../../helpers/debugger.js';
import { select } from 'redux-saga/effects';
export const getDebugMode = (state) => state.general.debugMode;
export function* postOrderSaga(action) {
	let debugMode = yield select(getDebugMode);
	appDebug('info',`Function postOrderSaga`, debugMode);
	yield put(actions.setStartOrdersLoading());
	const { orderData } = action;
	try {
		appDebug('info',`Function postOrderSaga post orders with data ${JSON.stringify(orderData)}`, debugMode);
		yield axios.post('orders', { order: orderData });
		yield put(actions.setOrdersSentSuccess());
		yield put(actions.getMyOrders());
	} catch (e) {
		console.log(e.response);
		yield put(actions.setPostOrderError(e.response.data));
	}
}
export function* getOpenedOrders(action) {
	let page = action.filters.page;
	let debugMode = yield select(getDebugMode);
	appDebug('info', `Function getOpenedOrders page ${page}`, debugMode);
	yield put(actions.setStartOrdersLoading());
	try {
		const response = yield axios.post('orders/opened', {
			limit: 12,
			page: page,
			by_game_ids: [],
			by_player_ids: [],
			order: action.filters.order,
			search_by_title: action.filters.search,
			search_by_title_and_description: '',
		});
		appDebug('success', `Function getOpenedOrders ${JSON.stringify(response.data)}`, debugMode);
		yield put(actions.setOrdersSuccess(response.data));
	} catch (e) {
		appDebug('error', `Function getOpenedOrders ${JSON.stringify(e.response.data)}`, debugMode);
		yield put(actions.setOrdersError(e.response.data));
	}
}
export function* updateOrder(action) {
	const { orderId, fromAnalysis, status } = action;
	yield put(actions.setStartOrdersLoading());
	try {
		if (fromAnalysis) {
			const response = yield axios.patch(`orders/${orderId}`, {
				order: {
					status: 'completed',
				},
			});
			if (response) {
				yield put(actions.getMyBids());
			}
		} else {
			const response = yield axios.patch(`orders/${orderId}`, {
				order: {
					status,
				},
			});
			if (response) {
				yield put(actions.getMyOrders());
			}
		}
	} catch (e) {
		console.log(e.response);
		yield put(actions.setOrdersError(e.response.data));
	}
}
export function* getSpecificOrder(data) {
	let debugMode = yield select(getDebugMode);
	const { id } = data;
	yield put(actions.setStartOrdersLoading());
	try {
		appDebug('info',`Function getSpecificOrder from orders/${id}`, debugMode);
		const response = yield axios.get(`orders/${id}`);
		appDebug('info',`Function getSpecificOrder response.data ${JSON.stringify(response.data)}`, debugMode);
		yield put(actions.setSpecificOrderSuccess(response.data));
	} catch (e) {
		console.log(e.response);
		yield put(actions.setSpecificOrderError(e.response.data));
	}
}
export function* applyForOrder(data) {
	const { id } = data;
	yield put(actions.setStartOrdersLoading());
	try {
		yield axios.post(`orders/${id}/make_bid`);
		yield put(actions.setApplyForOrderSuccess(true));
		yield put(actions.getMyBids());
	} catch (e) {
		console.log(e.response);
		yield put(actions.setOrdersError(e.response.data));
	}
}
export function* getMyOrders(action) {
	const { payload, filters } = action;
	if (payload !== 'WITHOUT_LOADING') {
		yield put(actions.setStartOrdersLoading());
	}
	const {
		order,
		limit,
		page,
		by_game_ids,
		by_player_ids,
		search_by_title,
		search_by_title_and_description,
	} = filters || {};

	const urlProcessed = `${encodeURIComponent('limit')}=${limit || 5}&${encodeURIComponent(
		'order'
	)}=${order || 'by_recent'}&${encodeURIComponent('page')}=${page || 1}&${encodeURIComponent(
		'by_game_ids[]'
	)}=${by_game_ids || ''}&${encodeURIComponent('by_player_ids[]')}=${
		by_player_ids || ''
	}&${encodeURIComponent('search_by_title')}=${search_by_title || ''}&${encodeURIComponent(
		'search_by_title_and_description'
	)}=${search_by_title_and_description || ''}`;
	try {
		const response = yield axios.get(`orders?${urlProcessed}`);
		yield put(actions.setMyPublishedOrdersSuccess(response.data.data));
	} catch (e) {
		console.log(e.response);
		yield put(actions.setOrdersError(e.response.data));
	}
}
export function* getMyBids(payload) {
	if (payload.payload !== 'WITHOUT_LOADING') {
		yield put(actions.setStartOrdersLoading());
	}
	try {
		const response = yield axios.get('order_bids');
		yield put(actions.setMyBidsSuccess(response.data.bids));
	} catch (e) {
		console.log(e.response);
		yield put(actions.setOrdersError(e.response.data));
	}
}
export function* acceptCurrentBid(data) {
	const { bidId } = data;
	yield put(actions.setStartOrdersLoading());
	try {
		yield axios.post(`order_bids/${bidId}/accept_bid`);
		yield put(actions.getMyOrders());
	} catch (e) {
		console.log(e.response);
		yield put(actions.setOrdersError(e.response.data));
	}
}
export function* rejectCurrentBid(data) {
	const { bidId } = data;
	yield put(actions.setStartOrdersLoading());
	try {
		yield axios.post(`order_bids/${bidId}/reject_bid`);
		yield put(actions.getMyOrders());
	} catch (e) {
		console.log(e.response);
		yield put(actions.setOrdersError(e.response.data));
	}
}
function* markBidAsViewedSingle(id, user) {
	let url;
	if (user === 'PLAYER') {
		url = `order_bids/${id}/mark_as_viewed_by_player`;
	} else if (user === 'COACH') {
		url = `order_bids/${id}/mark_as_viewed_by_coach`;
	}
	try {
		yield axios.post(url);
	} catch (e) {
		console.log(e.response);
		yield put(actions.setOrdersError(e.response.data));
	}
}
export function* markBidAsViewed(data) {
	const { bidsData, user } = data;
	yield all(bidsData.map((item) => markBidAsViewedSingle(item.id, user)));
	if (user === 'PLAYER') {
		yield put(actions.getMyOrders('WITHOUT_LOADING'));
	} else if (user === 'COACH') {
		yield put(actions.getMyBids('WITHOUT_LOADING'));
	}
}
export function* deleteMyBid(data) {
	const { orderId, bidId } = data;
	try {
		yield axios.delete(`orders/${orderId}/delete_bid/${bidId}`);
		yield put(actions.getMyBids());
	} catch (e) {
		console.log(e.response);
		yield put(actions.setOrdersError(e.response.data));
	}
}
