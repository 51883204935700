import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	getProductsLoading: false,
	getProductsError: null,
	allProducts: null,
};

const getProductsLoading = (state, action) => {
	return updateObject(state, { getProductsLoading: action.bool });
};
const getProductsError = (state, action) => {
	return updateObject(state, { getProductsError: action.error });
};
const setAllProducts = (state, action) => {
	return updateObject(state, { allProducts: action.products });
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_PRODUCTS_LOADING:
			return getProductsLoading(state, action);
		case actionTypes.GET_PRODUCTS_ERROR:
			return getProductsError(state, action);
		case actionTypes.SET_ALL_PRODUCTS:
			return setAllProducts(state, action);
		default:
			return state;
	}
};

export default reducer;
